

















































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglScaleControl,
    MglFullscreenControl,
    //MglVectorLayer,
    MglRasterLayer,
    MglPopup,
    MglMarker
} from 'vue-mapbox'

import MglVectorLayer from './MglVectorLayer.vue' // Use our custom version of the vector layer.

import MglGeocoderControl from 'vue-mapbox-geocoder'

var mapboxgl = require('mapbox-gl');

import InfoPanel from './infopanels/InfoPanel.vue';

import Filters from './filters/Filters.vue';
import ScenarioEditor from './tasks/ScenarioEditor.vue';
import { ScenarioAction } from '@/components/tasks/types';
import TransitFilter from './filters/Filter.vue';

import Events from './events/Events.vue';
import InteractiveLegend from './events/InteractiveLegend.vue';
import ContextReports from './events/ContextReports.vue';
import EnterpriseLegendEvents from './events/EnterpriseLegendEvents.vue';

import BlockedCommodityChart from './events/BlockedCommodityChart.vue';

import NetworkLegend from './legends/NetworkLegend.vue';
import EnterpriseLegend from './legends/EnterpriseLegend.vue';

import Reports from './reports/Reports.vue';

import { isNullOrUndefined } from 'util';
import { DensityType } from '@/store/datasets/types';

import { State, Action, Getter } from 'vuex-class';
import { DatasetState } from '@/store/datasets/types';
import { FiltersState } from '@/store/filters/types';
import { MapState, Layers, Sources } from '@/store/map/types';
import { EventsState } from '@/store/events/types';

import { paintStyles } from '@/components/layers/paint_styles';

import { bus } from '@/pages/transitweb/main'

import { endpoints } from "@/endpoints";
import buildUrl from "build-url";
import * as enterprise_layers from './layers/enterprises';
import * as road_layers from './layers/roads';
import * as rail_layers from './layers/rail';
import * as sea_layers from './layers/sea';

import * as polygon_styles from './layers/polygon_styles';

import { CancelTokenSource, AxiosResponse, AxiosRequestConfig } from 'axios';

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { mixins } from 'vue-class-component';
import PolygonColors from '@/components/tasks/polygon-colors-mixin'



@Component({
    components: {
        MglMap,
        MglGeocoderControl,
        MglNavigationControl,
        MglGeolocateControl,
        MglScaleControl,
        MglFullscreenControl,
        MglPopup,
        MglMarker,
        InfoPanel,
        Filters,
        TransitFilter,
        Events,
        InteractiveLegend,
        ContextReports,
        NetworkLegend,
        EnterpriseLegend,
        EnterpriseLegendEvents,
        MglVectorLayer,
        MglRasterLayer,
        Reports,
        BlockedCommodityChart,
        ScenarioEditor,
        MapboxDraw
    }
})


export default class TransitMap extends mixins(PolygonColors) {
    @State('filters') filters!: FiltersState;
    @Action('filters/setFiltersNotDirty') setFiltersNotDirty: any;
    @Action('filters/setFiltersDirty') setFiltersDirty: any;
    @Action('filters/clearFilters') clearFilters: any;
    @Action('filters/setQueryParams') setFiltersQueryParams: any;
    @Action('filters/setFilterValue') setFilterValue: any;
    @Action('filters/setFilterParams') setFilterParams: any;
    @Getter('filters/isFiltered') isFiltered!: boolean;
    @Getter('filters/isMonthlyFilter') isMonthlyFilter!: boolean;
    @Getter('filters/hasUnconstrainedFilters') hasUnconstrainedFilters!: boolean;
    @Getter('filters/filterHasValue') filterHasValue: any; // Returns an anonymous function

    @Getter('auth/constrainedValues') constrainedValues: any; // Returns an anonymous function
    @Getter('auth/username') username!: string;

    @State('map') mapstate!: MapState;
    @Action('map/setMarker') setMarker: any;
    @Action('map/setFeatureOfInterest') setFeatureOfInterest: any;
    @Action('map/setFlyTo') setFlyTo: any;
    @Getter('map/isInspecting') isInspecting!: boolean;

    @Action('auth/logout') logout: any;
    @Action('auth/refreshToken') refreshToken: any;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isAcsUser') isAcsUser!: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isScenarioUser') isScenarioUser!: boolean;
    @Getter('auth/displayName') displayName!: string;

    @State('datasets') datasets!: DatasetState;
    @Action('datasets/getDatasets') getDatasets: any;

    @State('events') events!: EventsState;
    @Action('events/setBlockedEnterpriseId') setBlockedEnterpriseId: any;
    @Getter('events/isInspectingBlockedEnterprise') isInspectingBlockedEnterprise!: boolean;
    @Getter('events/hasEventDates') hasEventDates!: boolean;

    created() {

        this.addAxiosInterceptors(); // Intercepts axios calls to control the progress bar.

        var vm = this;

        this.setFiltersQueryParams({}) // Reset the previous map state params.

        this.getDatasets().then(() => {
            if (!vm.isSelfRefreshing.includes(this.densityType)) {
                this.show_layers = true;
            }
        });

        // If filters are updated to a reset state (i.e. nothing selected), then refresh the base map.
        bus.$on('filters_updated', (filterId: string, value: any) => {
            //Update only if a user has all constrained filters (their account has constraints and they are looking at the base map).
            if (!this.hasUnconstrainedFilters) {
                // Cancel critical link analysis request. If request doesn't exist, nothing will be done.
                this.cancelFilteredDataRequest();
                this.refreshMap();
            }
        });

        // Initialize Mapbox Draw and polygon drawing controls
        bus.$on('scenario_initialize_mapboxDraw', () => {
            this.initializeMapboxDraw('draw_polygon');
        })

        // Remove all polygons
        bus.$on('scenario_remove_allPolygons', (e: any) => {
            this.removeAllPolygons();
        })

        // Remove individual polygons
        bus.$on('scenario_remove_polygon', (e: any) => {
            this.removePolygonById(e);
        })

        // Open or close the Scenario Editor.
        bus.$on('scenario_editor', (val: boolean) => {
            this.scenario_editor = val;
        });

        // Load polygons for individual tasks.
        bus.$on('scenario_load', (e: any) => {
            this.loadTaskPolygons(e);
        });

        // Remove all polygons after the task is submitted
        bus.$on('task_submitted', (e: any) => {
            this.removeAllPolygons();
        });

        if (this.isFiltered) {
            if (this.hasUnconstrainedFilters) {
                this.setFiltersDirty()
            }
        } else {
            this.setFiltersNotDirty()
        }

        // Refresh map after filter lists were updated on density type change
        bus.$on('filter_lists_updated', (e: any) => {
            this.refreshMap();

        });

    };


    destroyed() {
        bus.$off('filters_updated');
        bus.$off('scenario_initialize_mapboxDraw');
        bus.$off('scenario_remove_polygon');
        bus.$off('scenario_load');

    };

    accessToken = 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw';
    //mapStyleSatellite = 'mapbox://styles/bon132/cl311ia6i000h14nwms75g3rj';
    mapStyle = 'mapbox://styles/bon132/ckspigz2k5vsm18mjec6j8msb';

    center = [132.0, -29.5];
    bounds = [
        //Australia
        [60, -60], // Southwest coordinates
        [207, 20]  // Northeast coordinates
    ];


    isMapLoaded: boolean = false;
    isSatelliteLayer: boolean = false;
    show_layers = false;
    marker: any;
    mouseHoverLocation = { lng: 0, lat: 0 };
    errorSnackbar = false;
    errorMsg = "Errors have occurred retrieving data.";
    loading = { tiles: false };
    isSelfRefreshing = [DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario];  // Density types which don't require refreshing because they have their own refresh handling (i.e Events after retrieving the date list)
    DensityType: any = DensityType;
    popups: any = {};
    scenario_editor: boolean = false;

    datatableid: any = undefined;
    axiosRequestProgressBar: boolean = false;

    // Used to cancel cla requests
    filteredDataCancelTokenSource: CancelTokenSource | null = null;

    // Used to create mapbox-draw instance
    mapboxDraw: any = null;

    @Watch('dataset', { deep: true })
    onDatasetChanged(val: any, oldVal: any) {
        if (this.isMapLoaded) { // Needed in case it's the first time a user has ever logged in. (The dataset will go from 'empty string' to a 'value string')
            this.loadNewMap();
        }
    }

    @Watch('isMapReady')
    onIsMapReady(val: boolean) {
        if (val) {
            this.loadBoundaries();
            if (this.isInspecting) {
                this.renderLocationMarker(this.mapstate.marker)
            }
        }
    }


    @Watch('densityType')
    onDensityTypeChanged(val: DensityType, oldVal: DensityType) {

        // Clear the special filters which may exist (but not the value) on next/previous density types.
        this.setFilterValue({ filter: 'critical_link', value: [] })
        this.setFilterValue({ filter: 'inbound', value: [] })
        this.setFilterValue({ filter: 'outbound', value: [] })

        // Clear filters that cannot be shared between density types.
        this.setFilterValue({ filter: 'roadname', value: [] })
        this.setFilterValue({ filter: 'railname', value: [] })
        this.setFilterValue({ filter: 'seaname', value: [] })
        this.setFilterValue({ filter: 'gauge', value: [] })
        this.setFilterValue({ filter: 'accesstype', value: [] })
        this.setFilterValue({ filter: 'surfacetype', value: [] })

        // Clear filters which shouldn't be used for a certain density types.
        if (val == DensityType.Road_Events_Baseline || val == DensityType.Road_Events_Scenario) {
            this.setFilterValue({ filter: 'month', value: [] })
            this.setFilterValue({ filter: 'boundary1', value: [] })
            this.setFilterValue({ filter: 'boundary2', value: [] })
            this.setFilterValue({ filter: 'boundary3', value: [] })
            this.setFilterValue({ filter: 'boundary4', value: [] })
            this.setFilterValue({ filter: 'boundary5', value: [] })
            this.setFilterValue({ filter: 'boundary6', value: [] })
            this.setFilterValue({ filter: 'boundary7', value: [] })
        }

        // Update filters that are used in all density types but can have different filter values.
        if (val == DensityType.Road || val == DensityType.Rail || val == DensityType.Sea) {

            this.setFilterParams({ filter: 'commodity', params: { } });
            this.setFilterParams({ filter: 'commod_l2', params: { } });
            this.setFilterParams({ filter: 'commod_l3', params: { } });
            this.setFilterParams({ filter: 'origenterprise', params: { } });
            this.setFilterParams({ filter: 'origenterprisecategory', params: { } });
            this.setFilterParams({ filter: 'destenterprise', params: { } });
            this.setFilterParams({ filter: 'destenterprisecategory', params: { } });
        }

        this.show_layers = false; // Turn off the layers until it handles it's own map refresh itself. refreshMap is triggered (via bus) after filter lists were updated or, if Events, by its own refreshMap.

        //if (this.isSelfRefreshing.includes(val)) {
        //    this.show_layers = false;   // Turn off the layers until it handles it's own map refresh itself. Used for Events, Events have their own maprefresh.
        //}
        //else {
        //   this.refreshMap();          // Do a map refresh.
        //}

        this.clearLocationMarker();

        if (val == DensityType.Rail || val == DensityType.Sea) {
            this.disableDraw();
            this.removeAllPolygons();
        }
    }


    @Watch('mapstate.hiddenLayers', { deep: false })
    onVisibilityChanged(val: string[], oldVal: string[]) {

        let turned_off = val.filter((x: string) => !oldVal.includes(x));
        for (var layer in turned_off) {
            this.$data.map.setLayoutProperty(turned_off[layer], 'visibility', 'none')
        }
        let turned_on = oldVal.filter((x: string) => !val.includes(x));
        for (var layer in turned_on) {
            this.$data.map.setLayoutProperty(turned_on[layer], 'visibility', 'visible')
        }
    }


    @Watch('mapstate.flyTo', { deep: false })
    onFlyToChanged(val: number[], oldVal: number[]) {
        if (val.length > 0) {
            this.$data.map.flyTo({
                center: val,
                zoom: 15
            });
            this.setFlyTo([]);
        }
    }


    // A consistent reference to the map object.
    get getMap() {
        return this.$data.map
    }


    get dataset(): any {
        return this.datasets.dataset;
    }


    get isMapReady(): boolean {
        return this.dataset != '' && this.dataset && this.isMapLoaded && !isNullOrUndefined(this.$data.map);
    }


    get densityType(): DensityType {
        return this.datasets.type;
    }


    get statisticType(): string {
        if (this.isMonthlyFilter) {
            return 'Monthly';
        } else if (this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) {
            return 'Weekly'
        } else {
            return 'Annual';
        }
    }


    loadBoundaries() {
        this.$data.map.addSource('boundaries-source', this.boundariesSource);
    }


    removeBoundaries() {

        const source = 'boundaries-source'

        var layersToRemove = this.$data.map.getStyle().layers.filter(function (layer: any) {
            return layer.source === source;
        });

        for (var layer in layersToRemove) {
            this.$data.map.removeLayer(layersToRemove[layer].id);
        }

        this.$data.map.removeSource(source)
    }


    get boundariesSource(): any {
        return {
            type: 'vector',
            tiles: this.getTileUrl(endpoints.boundariesTileUrlTemplate(this.dataset), true, true), // Need to get the dataset param.
            tolerance: 40,
            buffer: 40,
        }
    }


    resetMap() {
        this.removeBoundaries();
        this.show_layers = false;
        this.popups = {};
        this.clearLocationMarker();
        this.removeAllPolygons();
    }

    //refreshMap() {
    //    // Removes all mgl-vector-layer components (and hence the layers from Mapbox)
    //    this.show_layers = false;

    //    var info_panel = (this.$refs.info_panel as InfoPanel)
    //    if (!isNullOrUndefined(info_panel)) { info_panel.fetchLinkStats(); }

    //    // Need to do this on nextTick so the layers can be successfully removed when mgl-vector-layers are destroyed.
    //    Vue.nextTick(() => {
    //        for (let layerConfig in this.vector_layers) {
    //            var layer = (this.vector_layers as any)[layerConfig];

    //            // Remove the existing source.
    //            try {
    //                this.$data.map.removeSource(layer.sourceId)
    //            } catch (error) {
    //                //console.log(error)
    //            }
    //            // Update the source with the new url's.
    //            if (!isNullOrUndefined(layer.source)) {
    //                layer.source.tiles = this.getTileUrl(layer.endpoint(layer.args), layer.ignoreFilters);
    //            }
    //            // Save all params to the store.
    //            this.setFiltersQueryParams(this.getQueryParams(false, true));
    //        }

    //        // Reload all layers and sources.
    //        this.show_layers = true;

    //        // Need to do this on the nextTick so the layers can be successfully created when mgl-vector-layers are created.
    //        Vue.nextTick(() => {
    //            // Hide the layer if it has been disabled.
    //            for (var layer of this.mapstate.hiddenLayers) {
    //                if (this.$data.map.getLayer(layer)) {
    //                    this.$data.map.setLayoutProperty(layer, 'visibility', 'none')
    //                }
    //            }
    //        })
    //    })

    //    this.popups = {};
    //    this.setFiltersNotDirty();
    //};

    refreshMapMain(dataTable?: undefined) {
        // Removes all mgl-vector-layer components (and hence the layers from Mapbox)
        this.show_layers = false;

        if (dataTable) {
            this.datatableid = dataTable;
        }

        var info_panel = (this.$refs.info_panel as InfoPanel)
        if (!isNullOrUndefined(info_panel)) { (info_panel as any).fetchLinkStats(); }

        // Need to do this on nextTick so the layers can be successfully removed when mgl-vector-layers are destroyed.
        Vue.nextTick(() => {
            for (let layerConfig in this.vector_layers) {
                var layer = (this.vector_layers as any)[layerConfig];

                // Remove the existing source.
                try {
                    this.$data.map.removeSource(layer.sourceId)
                } catch (error) {
                    //console.log(error)
                }

                // Update the source with the new url's.
                if (!isNullOrUndefined(layer.source)) {
                    layer.source.tiles = this.getTileUrl(layer.endpoint(layer.args()), layer.ignoreFilters, false, layer.useDatatableid, layer.boundaryFilters);
                }

                // Save all params to the store.
                this.setFiltersQueryParams(this.getQueryParams(false, true));
            }

            // Reload all layers and sources.
            this.show_layers = true;

            // Need to do this on the nextTick so the layers can be successfully created when mgl-vector-layers are created.
            Vue.nextTick(() => {
                // Hide the layer if it has been disabled.
                for (var layer of this.mapstate.hiddenLayers) {
                    if (this.$data.map.getLayer(layer)) {
                        this.$data.map.setLayoutProperty(layer, 'visibility', 'none')
                    }
                }
            })
        })

        this.popups = {};
        this.setFiltersNotDirty();
    };


    refreshMap() {
        if (this.isFiltered && this.densityType !== DensityType.Road_Events_Baseline && this.densityType !== DensityType.Road_Events_Scenario) {

            this.cancelFilteredDataRequest();// Cancell a running data table request.
            this.show_layers = false;
            this.setFiltersNotDirty();

            this.getFilteredData().then((response: any) => {
                if (response.data) {
                    this.refreshMapMain(response.data);
                }
            })
            .catch((error: any) => {
                console.log('Error fetching cached data:', error.message);
            });

        } else {
            this.refreshMapMain();
        }
    };

    // Filters optimisation - generate data before generating tiles for any baseline filter query (events don't use this feature)
    getFilteredData() {

        return new Promise((resolve, reject) => {
            // Generate cancel token source to cancel running data request when needed
            var CancelToken = Vue.axios.CancelToken;
            var CancelTokenSource = CancelToken.source();
            this.filteredDataCancelTokenSource = CancelTokenSource;

            var p: any = {};
            p = this.getQueryParams(false, true)

            var endpoint = buildUrl(endpoints.filterDataUrl(this.dataset, this.densityType), {
                queryParams: p
            });

            Vue.axios({
                url: buildUrl(endpoint), cancelToken: CancelTokenSource.token
            }).then((response: any) => {
                resolve(response);
            }, (error: any) => {

                reject(error);

                if (Vue.axios.Cancel) {
                    console.log('Cached data request canceled.', error.message);
                } else {
                    console.error('Error:', error.message);
                    // If an error occurred at this stage, clear filters and load a new map.
                    this.loadNewMap();
                }

            });

        });

    };

    // Cancel Vue.axios request if critical link filter is removed and no other filters are selected
    cancelFilteredDataRequest() {
        if (this.filteredDataCancelTokenSource) {
            this.filteredDataCancelTokenSource.cancel();
        }
    };


    loadNewMap() {
        this.resetMap();
        this.clearFilters();
        this.isMapLoaded = false;
        Vue.nextTick(() => { this.isMapLoaded = true; }) // Briefly toggle the isMapLoaded variable to cause isMapReady to watcher to trigger.
        this.refreshMap();
    };


    getQueryParams(ignoreFilters: boolean = false, ignoreEvents: boolean = false, useDatatableid: boolean = false, boundaryFilters: boolean = false) {

        // Build the tile url based upon dataset/filters/events values
        var queryParams: any = {}

        // If not ignoring Filter parameters when needed?
        if ((!ignoreFilters) || (ignoreFilters && useDatatableid && this.isFiltered)) {
            for (var filterId in this.filters.filter_types) {
                if (this.filters.filter_types.hasOwnProperty(filterId)) {
                    if (this.filters.filter_types[filterId].enabled && this.filterHasValue(filterId)) {
                        if (filterId.startsWith('boundary')) {
                            // Refactor Notes: This isn't cool... it works, but should think of a way to better handle boundary filters.
                            if (isNullOrUndefined(queryParams['boundary'])) { queryParams['boundary'] = [] }
                            var ids = this.filters.filter_types[filterId].value.map(function (val: any) { return val; });
                            queryParams['boundary'] = queryParams['boundary'].concat(ids);
                        } else {
                            // If boundaryFilter is false, add all filters to a layer, otherwise only boundary filters, if selected, will be added in the code block above.
                            if (!boundaryFilters) {
                                queryParams[filterId] = this.filters.filter_types[filterId].value.map(function (val: any) { return val; });

                            }
                        }
                    }
                }
            }
        }

        // Add datatableid as a parameter for filter queries
        if(ignoreFilters && useDatatableid && this.isFiltered){
            queryParams['datatableid'] = this.datatableid;
        }

        // If not ignoring Events parameters when needed?
        if (!ignoreEvents) {
            if (this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) {
                queryParams['date'] = this.events.event_date;

                //if (this.events.report_on === 'events') {
                //    queryParams['report_type'] = this.events.report_type;
                //}

                if (this.events.report_on === 'delta_trailers') {
                    this.events.delta_trailers != 'all' ? queryParams['commod_l3'] = this.events.delta_trailers : null;
                    /*queryParams['report_type'] = 'Event'; // Need to set as event for the enterprises*/
                }
            }
        }

        return queryParams;
    }

    getTileUrl(url: string, ignoreFilters: boolean = false, ignoreEvents: boolean = false, useDatatableid: boolean = false, boundaryFilters: boolean = false): string[] {
        var queryParams = this.getQueryParams(ignoreFilters, ignoreEvents, useDatatableid, boundaryFilters); // Get only required params for layer.
        var tileurl = buildUrl(url, { queryParams: queryParams });
        return [tileurl]; // Needs to be array of strings https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector-tiles
    }

    //-------------------------------------------
    //---------  Map Layer Definitions ----------
    //-------------------------------------------

    get vector_layers(): any {

        var vm = this;

        var ret = {

            [Layers.Enterprises]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road || vm.densityType === DensityType.Rail || vm.densityType === DensityType.Sea
                },
                layerId: Layers.Enterprises,
                layer: enterprise_layers.enterpriseStyle(Sources.Enterprises),
                sourceId: Sources.Enterprises,
                source: {
                    tiles: this.getTileUrl(endpoints.enterprisesTileUrlTemplate({ dataset: this.dataset, densityType: this.densityType }), true), // Set initial value
                    tolerance: 40,
                    buffer: 40,
                },
                before: 'road-label', // Should be underneath road labels otherwise you can't properly read the labels when zoomed out along way.
                endpoint: endpoints.enterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: false,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.EventsEnterprisesBaseline]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Baseline && (this.events.report_on === 'events' && this.events.report_type === 'Baseline')
                },
                layerId: Layers.EventsEnterprisesBaseline,
                layer: enterprise_layers.EventsEnterprisesBaselineStyle(Sources.EventsEnterprisesBaseline),
                sourceId: Sources.EventsEnterprisesBaseline,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsBaselineEnterprisesTileUrlTemplate({ dataset: this.dataset, densityType: this.densityType, }), true), // Set initial value
                    tolerance: 40,
                    buffer: 40,
                },
                before: 'road-label', // Should be underneath road labels otherwise you can't properly read the labels when zoomed out along way.
                endpoint: endpoints.eventsBaselineEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: false,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.Roads]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road
                },
                layerId: Layers.Roads,
                layer: road_layers.roadStyle(Sources.Roads),
                sourceId: Sources.Roads,
                source: {
                    tiles: this.getTileUrl(endpoints.roadsTileUrlTemplate({ dataset: this.dataset }), true), // Set initial value.
                    tolerance: 40,
                    buffer: 40,
                    maxzoom: 12,
                },
                before: Layers.Enterprises, // Ensure enterprises are over roads.
                endpoint: endpoints.roadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                ignoreFilters: true,
                useDatatableid: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); } // Colours the layer with the selected attribute from the network legend.
            },

            [Layers.RoadsBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road
                },
                layerId: Layers.RoadsBackground,
                layer: road_layers.roadShadowsStyle(Sources.RoadsBackground),
                sourceId: Sources.RoadsBackground,
                source: {
                    tiles: this.getTileUrl(endpoints.roadsTileUrlTemplate({ dataset: this.dataset }), true, true),
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Roads,
                endpoint: endpoints.roadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
            },

            [Layers.Rail]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Rail
                },
                layerId: Layers.Rail,
                layer: rail_layers.railStyle(Sources.Rail),
                sourceId: Sources.Rail,
                source: {
                    tiles: this.getTileUrl(endpoints.railTileUrlTemplate({ dataset: this.dataset }), true), // Set initial value.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Enterprises, // Ensure enterprises are over rail lines.
                endpoint: endpoints.railTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
                useDatatableid: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'rail_speed', 'gauge'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); } // Colours the layer with the selected attribute from the network legend.
            },

            [Layers.RailBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Rail
                },
                layerId: Layers.RailBackground,
                layer: rail_layers.railShadowsStyle(Sources.RailBackground),
                sourceId: Sources.RailBackground,
                source: {
                    tiles: this.getTileUrl(endpoints.railTileUrlTemplate({ dataset: this.dataset }), true, true),
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Rail,
                endpoint: endpoints.railTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
            },


            [Layers.Sea]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Sea
                },
                layerId: Layers.Sea,
                layer: sea_layers.seaStyle(Sources.Sea),
                sourceId: Sources.Sea,
                source: {
                    tiles: this.getTileUrl(endpoints.seaTileUrlTemplate({ dataset: this.dataset }), true), // Set initial value.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Enterprises, // Ensure enterprises are over sea lines.
                endpoint: endpoints.seaTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
                useDatatableid: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'co2_tn'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); } // Colours the layer with the selected attribute from the network legend.
            },

            [Layers.SeaBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Sea
                },
                layerId: Layers.SeaBackground,
                layer: sea_layers.seaShadowsStyle(Sources.SeaBackground),
                sourceId: Sources.SeaBackground,
                source: {
                    tiles: this.getTileUrl(endpoints.seaTileUrlTemplate({ dataset: this.dataset }), true, true),
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Sea, // Ensure sea lines are over the sea line shadows.
                endpoint: endpoints.seaTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
            },

            [Layers.RoadEventsBaseline]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Baseline  && vm.events.event_date != '' && this.events.report_on === 'events'
                },
                layerId: Layers.RoadEventsBaseline,
                layer: road_layers.roadEventsBaselineStyle(Sources.RoadEventsBaseline),
                sourceId: Sources.RoadEventsBaseline,
                source: {
                    tiles: this.getTileUrl(endpoints.roadBaselineTileUrlTemplate({ dataset: this.dataset, date: this.events.event_date }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                before: this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.roadBaselineTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                ignoreFilters: false,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },
            [Layers.RoadEventsScenario]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Scenario && vm.events.event_date != '' && this.events.report_on === 'events'
                },
                layerId: Layers.RoadEventsScenario,
                layer: road_layers.roadEventsScenarioStyle(Sources.RoadEventsScenario),
                sourceId: Sources.RoadEventsScenario,
                source: {
                    tiles: this.getTileUrl(endpoints.roadScenarioTileUrlTemplate({ dataset: this.dataset, date: this.events.event_date }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                before: this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.roadScenarioTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                ignoreFilters: false,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.DeltaTrailers]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && this.events.report_on === 'delta_trailers'
                },
                layerId: Layers.DeltaTrailers,
                layer: road_layers.roadEventsDeltaTrailersStyle(Sources.DeltaTrailers),
                sourceId: Sources.DeltaTrailers,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsDeltaCommodTileUrlTemplate({ dataset: this.dataset, date: this.events.event_date }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                endpoint: endpoints.eventsDeltaCommodTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads_difference'],
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.ClosedRoads1]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosedRoads1,
                layer: road_layers.roadEventsClosedRoadsStyle(Sources.ClosedRoads, Layers.ClosedRoads1, ['<=', ['get', 'days_closed'], 7]),
                sourceId: Sources.ClosedRoads,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsClosedRoadsTileUrlTemplate({ dataset: this.dataset, date: this.events.event_date }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsClosedRoadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.ClosedRoads7]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosedRoads7,
                layer: road_layers.roadEventsClosedRoadsStyle(Sources.ClosedRoads, Layers.ClosedRoads7, ['>=', ['get', 'days_closed'], 8]),
                sourceId: Sources.ClosedRoads,
                endpoint: endpoints.eventsClosedRoadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesOrigin]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.BlockedEnterprisesOrigin,
                layer: enterprise_layers.enterpriseBlockedODStyle(Sources.BlockedEnterprisesOD, Layers.BlockedEnterprisesOrigin, 0),
                sourceId: Sources.BlockedEnterprisesOD,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsBlockedEnterprisesODTileUrlTemplate({ dataset: this.dataset, densityType: this.densityType, }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                before: this.events.report_on === 'events' && this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.eventsBlockedEnterprisesODTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
            },

            [Layers.BlockedEnterprisesDestination]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.BlockedEnterprisesDestination,
                layer: enterprise_layers.enterpriseBlockedODStyle(Sources.BlockedEnterprisesOD, Layers.BlockedEnterprisesDestination, 1),
                sourceId: Sources.BlockedEnterprisesOD,
                before: this.events.report_on === 'events' && this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.eventsBlockedEnterprisesODTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
            },

            [Layers.BlockedEnterprisesUnaffected]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline)  && vm.events.event_date != '' && (vm.events.report_type === 'Event' || vm.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesUnaffected,
                layer: enterprise_layers.enterpriseBlockedUnStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsBlockedEnterprisesTileUrlTemplate({ dataset: this.dataset, densityType: this.densityType, }), true),
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesPartial]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (this.events.report_type === 'Event' || this.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesPartial,
                layer: enterprise_layers.enterpriseBlockedParStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                clearSource: false,  // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesTotally]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (this.events.report_type === 'Event' || this.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesTotally,
                layer: enterprise_layers.enterpriseBlockedTotStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesImpactedBy]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (vm.events.report_type === 'Event' || this.events.report_on === 'delta_trailers') && this.isInspectingBlockedEnterprise
                },
                layerId: Layers.BlockedEnterprisesImpactedBy,
                layer: enterprise_layers.enterpriseBlockedImpactedByStyle(Sources.BlockedEnterprisesImpactedBy),
                sourceId: Sources.BlockedEnterprisesImpactedBy,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsBlockedEnterprisesImpactedByTileUrlTemplate({ dataset: this.dataset, ent_id: this.events.blocked_enterprise_id }), true),
                    promoteId: { 'blockedenterprises_impactedby': 'street_name' }, // Generated feature Id from source layer.
                    tolerance: 40,
                    buffer: 40,
                },
                endpoint: endpoints.eventsBlockedEnterprisesImpactedByTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, ent_id: this.events.blocked_enterprise_id }
                },
                ignoreFilters: false,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setBlockedEnterprisesImpactedByLayersEvents(e); }
            },

            [Layers.ClosureCategories]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosureCategories,
                layer: road_layers.roadEventsClosureCategoriesStyle(Sources.ClosureCategories),
                sourceId: Sources.ClosureCategories,
                source: {
                    tiles: this.getTileUrl(endpoints.eventsClosureCategoriesTileUrlTemplate({ dataset: this.dataset, date: this.events.event_date }), true), // Set initial value.
                    tolerance: 40,
                    buffer: 40,
                    maxzoom: 12,
                },
                //before: Layers.Enterprises, // Ensure enterprises are over roads.
                endpoint: endpoints.eventsClosureCategoriesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                useDatatableid: false,
                boundaryFilters: false, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

        };

        // If the current legend layer has a paint style definition in the dictionary... (Otherwise assumes the paint style is already defined on the actual layer).
        if (this.mapstate.layerStyle in paintStyles) {
            if (this.layerForLegend != '') {
                (ret as any)[this.layerForLegend].layer.paint['line-color'] = (paintStyles as any)[this.mapstate.layerStyle]; // Set the line colors on the layers paint object.
            }
        }
        return ret

    }


    // Gets the layer to be used for the legend.
    get layerForLegend(): string {
        if (this.densityType === DensityType.Road_Events_Baseline && this.events.report_on === 'events') {
            return Layers.RoadEventsBaseline;
        } else if (this.densityType === DensityType.Road_Events_Scenario && this.events.report_on === 'events') {
            return Layers.RoadEventsScenario;
        } else if ((this.densityType === DensityType.Road_Events_Scenario || this.densityType === DensityType.Road_Events_Baseline) && this.events.report_on === 'delta_trailers') {
            return Layers.DeltaTrailers;
        } else if (this.densityType === DensityType.Road) {
            return Layers.Roads;
        } else if (this.densityType === DensityType.Rail) {
            return Layers.Rail;
        } else if (this.densityType === DensityType.Sea) {
            return Layers.Sea;
        } else {
            return ''
        }
    }


    setInfoPanelLayerEvents(layer_e: any) {

        var vm = this;

        vm.$data.map.on('click', layer_e.layerId, function (e: any) {
            // If not already inspecting a feature.
            if (!this.isInspecting) {
                vm.setMarker(e.lngLat)                  // Set the marker location in the store.
                vm.renderLocationMarker(e.lngLat);      // Draw the marker on the map.
            }
        });
    }


    setBlockedEnterprisesImpactedByLayersEvents(layer_e: any) {

        var featureId: any = null;

        var vm = this;

        // When the user moves their mouse over the layer, we'll update the
        // feature state for the feature under the mouse - this will color marker red
        vm.$data.map.on('mousemove', layer_e.layerId, function (e: any) {
            if (e.features.length > 0) {
                if (featureId != null) { layer_e.component.setFeatureState(featureId, { hover: false }); }
                featureId = e.features[0].id;
                layer_e.component.setFeatureState(featureId, { hover: true });
            }
        });

        // When the mouse leaves the layer, update the feature state of the
        // previously hovered feature - this will color marker grey
        vm.$data.map.on('mouseleave', layer_e.layerId, function (e: any) {
            if (featureId != null) { layer_e.component.setFeatureState(featureId, { hover: false }); }
            featureId = null;
        });

        vm.$data.map.on('click', layer_e.layerId, function (e: any) {

            var feature = e.features[0];

            // Create a popup with a closed link street name.
            Vue.nextTick(() => {
                Vue.set(vm.popups, layer_e.layerId, {
                    showed: true,
                    contentHTML: '<div id="impacted-by-popup-content"></div>',
                    coordinates: [e.lngLat.lng, e.lngLat.lat],
                    closeButton: false,
                    closeOnClick: true,
                    offset: 25,
                    open: (opene: any) => {
                        // Inject the BlockedCommodityChart component into the popup.
                        const commodityChart = Vue.extend(BlockedCommodityChart)
                        const popupInstance = new commodityChart({
                            propsData: {
                                properties: feature.properties,
                            }
                        }).$mount("#impacted-by-popup-content"); // Mount this component to the div added above.
                    },
                    close: (closee: any) => {
                        Vue.delete(vm.popups, layer_e.layerId);
                    },
                })
            })
        });
    }


    setRoadPopupLayerEvents(layer_e: any) {

        var vm = this;

        // Note: Extra mouseenter event, to add additional functionality to default.
        vm.$data.map.on('mouseenter', layer_e.layerId, function (e: any) {

            const street_name = e.features[0].properties.street_name;
            const trailer_loads = layer_e.layerId === Layers.DeltaTrailers ? (e.features[0].properties.trailer_loads).toFixed(2) : 0
            const display_name = e.features[0].properties.display_name;

            // Populate the popup and set its coordinates based on the feature found.
            const html_string_deltatrailers = "<h3>Road name: " + street_name + "</h3>" + "<h3>Change in trailers: " + trailer_loads + "</h3>";
            const html_string_closedroad = "<h3>Closed road: " + street_name + "</h3>";
            const html_string_closurecategory = "<h3>" + display_name + "</h3>";

            if (layer_e.layerId === Layers.DeltaTrailers) {
                var html_string = html_string_deltatrailers
            } else if (layer_e.layerId === Layers.ClosureCategories) {
                var html_string = html_string_closurecategory
            } else {
                var html_string = html_string_closedroad
            }

            //var html_string = layer_e.layerId === Layers.DeltaTrailers ? html_string_deltatrailers : html_string_closedroad;

            Vue.set(vm.popups, layer_e.layerId, {
                showed: true,
                contentHTML: html_string,
                closeButton: false,
                closeOnClick: false,
            })
        });

        // Note: Extra mouseleave event to add additional functionality to default.
        vm.$data.map.on('mouseleave', layer_e.layerId, function (e: any) {
            Vue.delete(vm.popups, layer_e.layerId);
        });
    }


    onCloseInfoPanel(type: string) {
        this.setFeatureOfInterest(null);
        this.clearLocationMarker();
        this.setBlockedEnterpriseId(undefined);
        if (!isNullOrUndefined(this.popups[Layers.BlockedEnterprisesImpactedBy])) {
            Vue.delete(this.popups, Layers.BlockedEnterprisesImpactedBy);
        }
    }


    clearLocationMarker() {
        if (this.isInspecting) {
            this.$data.marker.remove();
            this.$data.marker = null;
            this.setFeatureOfInterest(null);
            this.setMarker(null);
        }
    }

    renderLocationMarker(lngLat: any) {

        if (!this.$data.marker) {
            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            this.$data.marker = new mapboxgl.Marker(el, {
                offset: [0, -42 / 2]
            })
                .setLngLat(lngLat)
                .addTo(this.$data.map);
        }
    }


    // Add the Auth token in all Mapbox Tile requests.
    addJWTToken(url: any, resourceType: any) { // Note: does not use Vue.axios that we have configured, so interceptors do not pick up 401 errors.
        if (resourceType == 'Tile' && !url.includes('mapbox.com')) {
            return {
                url: url,
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.access_token,
                    'X-Username': this.$store.state.auth.user.username
                }
            }
        }
    }


    loadMapImage(image_path: string, imageId: string, sdfOption: boolean = true) {

        this.$data.map.loadImage(image_path, (error: any, image: any) => {
            if (error) throw error;
            // Convert to a signed distance field to enable resizing and recoluring of monochrome images.
            // Images with a single colour and transparent background are appropriate.
            this.$data.map.addImage(imageId, image, { 'sdf': sdfOption });
        });
    }


    //-----------------------------------
    //---------  Mapbox Events ----------
    //-----------------------------------

    //Map Loaded
    onMapLoaded(e: any) {

        this.$data.map = e.map; // Do not add mapbox to a reactive property or else things go bad.

        // Load necessary custom markers.
        this.loadMapImage('./location_on_FILL1_wght400_GRAD0_opsz48.png', 'marker-icon', true)
        this.loadMapImage('./stop_FILL1_wght400_GRAD0_opsz48.png', 'stop_icon', true)
        this.loadMapImage('./change_history_FILL1_wght400_GRAD0_opsz48.png', 'triangle_icon', true)
        this.loadMapImage('./flood-icon-small.png', 'water_icon', false)
        this.loadMapImage('./fire-icon-small.png', 'fire_icon', false)
        this.loadMapImage('./hazard-icon-small.png', 'caution_icon', false)

        this.isMapLoaded = true;

    }


    // Mouse is moving on the map.
    onMapMouseMove(e: any) {
        // If not locked into inspecting a feature (i.e. mouse hovering)

        if (!this.isInspecting) {

            // Get the features at the mouse location.
            var features = this.$data.map.queryRenderedFeatures(e.mapboxEvent.point);

            // Ignore any mapbox features.
            features = features.filter(function (f: any) {
                if (f.source === "composite" || f.source.startsWith('mapbox-gl-draw')) {
                    return false; // skip
                }
                return true;
            });

            // If there is a feature at the mouse location, set the feature of interest.
            if (features.length > 0) {
                var feature = features[0]
                this.setFeatureOfInterest(feature);
            } else {
                this.setFeatureOfInterest(null);
            }
        }

        this.mouseHoverLocation = e.mapboxEvent.lngLat; // Capture the current coordinates.
    }


    // Get a name for the background map layer
    get mapBackgroundLayerName() {
        return this.isSatelliteLayer ? 'Map' : 'Satellite';
    }

    // Switch between background layers on the map
    toggleMapBackgroundLayer() {

        this.isSatelliteLayer = !this.isSatelliteLayer;

        if (!this.isSatelliteLayer) {
            // Remove layer explicitly
            this.$data.map.removeLayer('satellite');
        }
    };


    // Map is updating.
    onMapData(e: any) {
        if (!this.axiosRequestProgressBar){
            if (e.mapboxEvent.tile) {
                this.loading.tiles = !this.$data.map.areTilesLoaded()
            }
        }
    }

   // Map becomes idle.
   onMapIdle(e: any) {
       if (!this.axiosRequestProgressBar) {
           this.loading.tiles = !this.$data.map.areTilesLoaded()
       }
   }

    //// Map becomes idle.
    //onMapIdle(e: any) {
    //    this.loading.tiles = !this.$data.map.areTilesLoaded()
    //}

    onMapError(e: any) {
        if (e.mapboxEvent.error.status == 401) {
            this.logout(); // Deletes axios auth headers
            this.$router.push("/signin");
        }
    }

    // Interseptors are used here to control the progress bar on axios requests
    addAxiosInterceptors() {
        var requestInterceptor = Vue.axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                this.axiosRequestProgressBar = true;
                this.loading.tiles = true;
                return config;
            });

        var responseInterceptor = Vue.axios.interceptors.response.use(
            (response: AxiosResponse<any>) => {
                this.axiosRequestProgressBar = false;
                this.loading.tiles = false;
                return response;
            },

            (error: any) => {
                this.axiosRequestProgressBar = false;
                this.loading.tiles = false;
                return Promise.reject(error);
            }

      );
      return { requestInterceptor, responseInterceptor };

    }


    //-------------------------------------------
    //---------  Draw a polygon on map ----------
    //-------------------------------------------

    // Initialize Mapbox Draw and polygon drawing controls
    initializeMapboxDraw(drawMode: any) {

        // If draw exists, enable drawing
        if (this.mapboxDraw) {

            const currentMode = this.mapboxDraw.getMode();
            if (currentMode != 'draw_polygon') {
                this.mapboxDraw.changeMode(drawMode);
            }

        };

        // If Mapbox Draw doesn't exist, create new draw instance
        if (!this.mapboxDraw) {

            this.mapboxDraw = new MapboxDraw({
                userProperties: true, // Needed to be able to set color.
                displayControlsDefault: false,
                controls: {
                    polygon: false,
                    trash: false,
                },

                defaultMode: drawMode,
                styles: polygon_styles.polygonStyles,

            });

            this.$data.map.addControl(this.mapboxDraw, 'top-left');

            // Draws polygon
            this.$data.map.on('draw.create', (e: any) => {
                this.handleDrawCreate(e);
            });

            // If shape or location of the polygon changed
            this.$data.map.on('draw.update', (e: any) => {
                var updatedFeatures = e.features;

                updatedFeatures.forEach((feature: any) => {
                    bus.$emit('scenario_close_area', feature);
                });

            });

            // Listen for the Escape key press event
            window.addEventListener('keydown', this.handleKeyDown);

        }
    };

    // Turns mouse cursor from draw state back to default state by pressing the Escape button
    handleKeyDown(e: any) {
        // Check if the pressed key is Escape (key code 27)
        if (e.keyCode === 27) {
            // Change the draw mode to simple_select (normal cursor)
            this.mapboxDraw.changeMode('simple_select');
        }
    };

    disableDraw() {
        if (this.mapboxDraw) {
            const currentMode = this.mapboxDraw.getMode();
            if (currentMode === 'draw_polygon') {
                this.mapboxDraw.changeMode('simple_select');
            }
        }
    };

    // Send polygon coordinates to the scenario editor
    handleDrawCreate(e: any) {

        var feature = e.features[0];
        var featureId = feature.id;
        var color = this.getPolygonColor();

        // Add color to feature.properties of the polygon
        feature.properties.color = color;

        // Assign color to a new polygon
        // color is a custom property to define a custom color on a polygon. This is referenced in mapbox styles as 'user_color' (see src/components/layers/polygon_styles.ts)
        this.mapboxDraw.setFeatureProperty(featureId, 'color', color);

        bus.$emit('scenario_close_area', feature);

    };

    removeAllPolygons() {
        if (this.mapboxDraw) {
            this.mapboxDraw.deleteAll();
        }
    };

    removePolygonById(featureId: string) {
        if (this.mapboxDraw) {
            // Get all features from Mapbox Draw
            const allFeatures = this.mapboxDraw.getAll().features;

            // Find the feature with the specified ID
            const featureToRemove = allFeatures.find(
                (drawnFeature: any) => drawnFeature.id === featureId
            );

            if (featureToRemove) {
                // Remove the feature from Mapbox Draw
                this.mapboxDraw.delete(featureToRemove.id);
            }

        }
    };

    // Load task polygons when viewing (loading) scenario from the tasks list
    loadTaskPolygons(e: any) {

        var polygon_features = e.inputs.actions.filter((action: any) => action.type === ScenarioAction.AREA);
        this.initializeMapboxDraw('simple_select'); // Initialize draw
        this.removeAllPolygons(); // Remove all polygons if any

        polygon_features.length > 0 && polygon_features.forEach((item: any) => {
            var color = item.value.properties.color;
            var featureId = item.value.id;

            this.mapboxDraw.add(item.value); // Add task polygons
            this.mapboxDraw.setFeatureProperty(featureId, 'color', color); // Assign polygon color
        });

    };

}

