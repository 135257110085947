import { MutationTree } from 'vuex';
import { AuthState, AuthStatus } from './types';
import { isNullOrUndefined } from 'util';

export const mutations: MutationTree<AuthState> = {
    auth_request(state) {
        state.status = AuthStatus.Loading;
    },
    auth_success(state, payload: { access: string, refresh: string, user: any }) {
        state.status = AuthStatus.Success;
        state.access_token = payload.access;
        if (!isNullOrUndefined(payload.refresh)) {
            state.refresh_token = payload.refresh;
        }
        if (!isNullOrUndefined(payload.user)) {
            state.user = payload.user;
            state.mfa_current = null; // Only do this if user is also supplied. Otherwise short error is displayed on MFA screen.
        }
    },
    auth_mfa(state, payload: { mfa: any, username: string }) {
        state.status = AuthStatus.MFA;
        state.mfa_current = payload.mfa;
        state.user.username = payload.username;
    },
    auth_success_mfa(state) {
        state.status = AuthStatus.Success;
        state.mfa_current = null;
    },
    auth_mfa_methods(state, payload: any[]) {
        // Don't set status because the status can be anything.
        state.mfa_methods = payload;
    },
    auth_mfa_deactivate(state) {
        // Don't set status because user is already authenticated to be able to do this.
        state.mfa_current = null;
    },
    auth_error(state) {
        state.status = AuthStatus.Error;
    },
    logout(state) {
        state.status = AuthStatus.SignedOut;
        state.access_token = '';
        state.refresh_token = '';
        state.mfa_methods = [];
        state.mfa_current = null;
        state.user = { username: '', first_name: '', last_name: '', constraints: {}, permission_groups: [] }
    },
};
