import { ActionTree } from 'vuex';
import axios from 'axios';
import { EventsState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'
import buildUrl from 'build-url';


export const actions: ActionTree<EventsState, RootState> = {
    setEnterpriseLegend({ commit }, visible: boolean) {
        commit('setEnterpriseLegend', visible);
    },
    setReportType({ commit }, report_type: string) {
        commit('setReportType', report_type);
    },
    setEventDate({ commit }, event_date: string) {
        commit('setEventDate', event_date);
    },
    setReportOn({ commit }, report_on: string) {
        commit('setReportOn', report_on);
    },
    setDeltaTrailers({ commit }, delta_trailers: string) {
        commit('setDeltaTrailers', delta_trailers);
    },
    setBlockedEnterpriseId({ commit }, blocked_enterprise_id: string) {
        commit('setBlockedEnterpriseId', blocked_enterprise_id);
    },
    getEventDateItems({ commit }, dataset: string, ) {
        axios({
            url: buildUrl(endpoints.eventDatesUrl(dataset))
        }).then((response: any) => {
            commit('eventDateItemsLoaded', response.data);
        }, (error: any) => {
            console.log(error);
        });
    }
};
