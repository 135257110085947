import { ActionTree } from 'vuex';
import { AssistantState, MessageState } from './types';
import { RootState } from '../types';
import { getters } from "@/store/assistant/getters";
import { state } from "@/store/assistant/index";
import { endpoints } from '../../endpoints'
import Vue from 'vue';
import buildUrl from 'build-url';
import { isNullOrUndefined } from 'util';
import { EventSourceExtra } from '@/EventSourceExtra.js';


export const actions: ActionTree<AssistantState, RootState> = {

    askQuestion({ commit, dispatch, state, rootState, rootGetters }, payload: MessageState): any {


        // Note doesn't display dialog like old function does. Probably need an extra property (like modal/browser tab)
        payload.role = 'user'; // Add user role to question.

        commit('setSubject', payload.subject);
        commit('setQuestion', payload.content);
        commit('addMessage', payload);
        commit('setLoading', true);
        commit('setQuestion', ''); // Clear the question after the request has been defined.

        // Restructure how images are sent before sending.
        var request_payload = { ...payload };
        if (request_payload.images) {
            request_payload.images = request_payload.images.map(image => { return image.src });
        }

        var use_sse = true;

        if (use_sse) {

            var full_msg: any = {}

            var headers: any = { "Content-Type": "application/json" };
            if (rootGetters['auth/isAuthenticated']) {
                headers.Authorization = "Bearer " + (rootState as any).auth.access_token;
            }
            // Extract the history (if needed) before adding the message to the store.
            (request_payload as any).history = state.messages.slice(-4).map(message => { return { "role": message.role, "content": message.content } })
            var sseClient = new EventSourceExtra(endpoints.assistantUrl, { headers: headers, method: 'POST', payload: JSON.stringify(request_payload) });

            var onUpdate = function(msg: any) {

                //console.log('Message: ', msg);

                var response = msg.response;

                if (response && response.content) {
                    const deltaContent = response["content"] || "";
                    const combinedObject = {
                        content: full_msg.response.content + deltaContent,
                    };
                    full_msg.response = Object.assign({}, full_msg.response, combinedObject);
                }
                if (response && response.role) {
                    (full_msg.response as any)['role'] = response.role;
                }
                if (response && response.related_questions) {
                    (full_msg.response as any)['related_questions'] = response.related_questions;
                }
                if (response && response.images) {
                    (full_msg.response as any)['images'] = response.images;
                }
                if (msg.question) { // Root elements
                    full_msg = Object.assign({}, full_msg, msg);
                }

                commit('setProgressiveMessage', full_msg.response);
            }


            var onComplete = function(msg: any) {

                //console.log('Complete', full_msg);

                dispatch('clearProgressiveMessage');

                var result = full_msg.response as any;
                commit('addMessage', result);
                commit('setLoading', false);

                sseClient.close();
            }

            var onOpen = function () {
                console.log("SSE connected!");
                full_msg = { "response": { "content": "", "role": "", "subject": request_payload.subject } }
            }

            // Catch any errors (ie. lost connections, etc.)
            sseClient.on('error', (e: any) => {

                if (e !== "stream abort") {

                    console.error('Lost SSE connection or failed to parse!', e);

                    // If this error is due to an unexpected disconnection, EventSource will
                    // automatically attempt to reconnect indefinitely. You will _not_ need to
                    // re-add your handlers.

                    commit('addMessage',
                        {
                            content: 'Sorry, I am currently overheating!, please try again shortly...',
                            role: 'assistant',
                            status: 'error'
                        });

                }

                commit('setLoading', false);

            });

            // Handle messages without a specific event
            sseClient.on('message', onUpdate);

            // Handle messages without a specific event
            sseClient.on('complete', onComplete);

            // Handle messages without a specific event
            sseClient.on('open', onOpen);


            sseClient.stream();


        } else {

            Vue.axios({
                url: endpoints.assistantUrl, method: 'POST', data: request_payload
            }).then((response: any) => {
                var result = response.data.response;
                result.subject = request_payload.subject; // Transfer subject over.
                commit('addMessage', result);
                commit('setLoading', false);
            }, (error: any) => {
                commit('addMessage', { content: 'Sorry, I am currently overheating!, please try again shortly...', role: 'assistant', status: 'error' });
                commit('setLoading', false);
            });

        }
    },

    speek({ commit }, payload: any): any {

        return new Promise((resolve, reject) => {

            // Note: Axios API can't stream, so need to use fetch API.
            fetch(endpoints.assistantSpeekUrl,
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": payload.accept || "audio/mpeg",
                    },
                    body: JSON.stringify(payload.body)
                })
                .then(response => {
                    resolve(response);
                }, (error: any) => {
                    reject(error);
                });
        });
    },

    setQuestion({ commit }, question: string) {
        commit('setQuestion', question);
    },

    setSubject({ commit }, subject: string) {
        commit('setSubject', subject);
    },

    setStandAlone({ commit }, isStandAlone: boolean) {
        commit('setIsStandAlone', isStandAlone);
    },

    setSyncWithMap({ commit }, syncWithMap: boolean) {
        commit('setSyncWithMap', syncWithMap);
    },

    setPlayAudioResponses({ commit }, playAudioResponses: boolean) {
        commit('setPlayAudioResponses', playAudioResponses);
    },

    clearMessages({ commit, getters }) {
        commit('setMessages', []);
        commit('setProgressiveMessage', { content: "", role: "" });
    },

    clearProgressiveMessage({ commit, getters }) {
        commit('setProgressiveMessage', {content: "", role: ""} );
    },

    addAssistantMessage({ commit }, content: MessageState) {
        content.role = 'assistant';
        commit('addMessage', content);
    },
};
