import { GetterTree } from 'vuex';
import { AssistantState, MessageState } from './types';
import { RootState } from '../types';
import { isNullOrUndefined } from 'util';


export const getters: GetterTree<AssistantState, RootState> = {

    assistantName(): string {
        return 'Herbie';
    },

    assistantVerb(state, getters): string {
        return 'Honk ' + getters.assistantName + '!';
    },

    relatedQuestions(state): MessageState[] {

        var result: MessageState[] = [];

        if (state.messages.length > 0) {
            var last = state.messages[state.messages.length - 1]; // Get the last message in the conversation.
            if (!isNullOrUndefined(last.related_questions)) {
                // If it has related questions attached to it.
                last.related_questions.forEach((question: string) => {
                    // Add them as a pre-canned message to send to the assistant.
                    result.push({ content: question, role: 'user', subject: 'general' });
                });
            }
        }

        return result;
    },

};
