





































































































































































































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'

import { MapState } from '@/store/map/types';
import { DatasetState } from '@/store/datasets/types';
import { EventsState } from '@/store/events/types';

import LegendItem from '@/components/legends/LegendItem.vue';
import RoadClosureInfo from './RoadClosureInfo.vue';

import buildUrl from 'build-url';
import { endpoints } from "@/endpoints";
import { isNullOrUndefined } from 'util';

import { DensityType } from '@/store/datasets/types';

const namespace: string = 'datasets';

@Component({
    components: {
        LegendItem,
        RoadClosureInfo,
    }
})
export default class Events extends Vue {

    @Prop() private getMap!: any;

    @State('map') mapstate!: MapState;
    @State('datasets') datasets!: DatasetState;
    @State('events') events!: EventsState;

    @Action('filters/setFiltersDisabled') setFiltersDisabled: any;
    @Action('filters/setFiltersVisible') setFiltersVisible: any;
    @Action('filters/setFilterParams') setFilterParams: any;

    @Action('events/getEventDateItems') getEventDateItems: any;
    @Action('events/setEnterpriseLegend') setEnterpriseLegend: any;
    @Action('events/setReportType') setReportType: any;
    @Action('events/setEventDate') setEventDate: any;
    @Action('events/setReportOn') setReportOn: any;
    @Action('events/setDeltaTrailers') setDeltaTrailers: any;
    @Getter('events/hasEventDates') hasEventDates!: boolean;

    @Getter('auth/isScenarioUser') isScenarioUser!: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;

    @Action('setDatasetType', { namespace }) setDatasetType: any;


    // Report Type Selector
    event_layer_items: any[] =
        [
            { header: 'Forecast' },
            { text: '7-Day Forecast (with re-routing)', value: 'events-Event' },
            { text: '7-Day Baseline (without re-routing)', value: 'events-Baseline' },
            { divider: true },
            { header: 'Change in Trailers' },
            { text: 'All Sectors (change in trailers)', value: 'delta_trailers-all' },
            { text: 'Fuel Sector (change in trailers)', value: 'delta_trailers-fuel' },
            { text: 'Processed Food Sector (change in trailers)', value: 'delta_trailers-processed_food' },

        ]

    get report_type(): string {
        return this.events.report_type;
    }

    get event_layer(): string {
        if (this.events.report_on === 'events') {
            return this.events.report_on + '-' + this.events.report_type;
        }
        else {
            return this.events.report_on + '-' + this.events.delta_trailers;
        }
    }
    set event_layer(val: string) {

        var components = val.split('-') // Split the layer string on the hyphen. Takes the form {category}-{layer}
        this.setReportOn(components[0])
        this.setFiltersDisabled(this.isFiltersDisabled);    // Filters Disabled
        //this.setFiltersVisible(!this.isFiltersDisabled);  // Filters Invisible

        if (this.events.report_on === 'events') {

            this.setReportType(components[1]);

            //Set density type in store when report_type changes
            if (this.report_type == 'Event') {
                this.setDatasetType(DensityType.Road_Events_Scenario)
            } else if (this.report_type == 'Baseline') {
                this.setDatasetType(DensityType.Road_Events_Baseline)
            }
        }
        else if (this.events.report_on == 'delta_trailers') {
            this.setDeltaTrailers(components[1]);
        }
    }

    // Date Selector
    get event_date(): string {
        return this.events.event_date;
    }
    set event_date(val: string) {
        this.setEventDate(val);
    }

    // Does the map need refreshing?
    isDirty: boolean = true;

    // Alerts
    road_closure_sources: boolean = false;
    report_not_ready_alert: boolean = false;


    get dataset(): any {
        return this.datasets.dataset;
    }

    get map() {
        return this.getMap;
    }

    get getFormattedDate(): string {
        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(this.event_date).toLocaleDateString("en-AU", options);
    }

    get isEnterpriseLegendEnabled(): boolean {
        return (this.report_type === 'Event' && this.events.report_on === 'events') || this.events.report_on === 'delta_trailers'
    }

    get isFiltersDisabled(): boolean {
        return this.events.report_on === 'delta_trailers';
    }

    // Note: Does this need to include major events?
    allowedDates(val: string): boolean {
        return this.events.event_date_items.includes(val);
    }


    created() {

        // Gets the dates for which closed road layers are available.
        this.getEventDateItems(this.dataset).then(() => {

            // Need to update some filters here with relevant query params - so the selection list updates.
            this.setFilterLists();

        }, (error: any) => {
            console.log(error);
        });

        // Catch the event from the 'NavBar' menu, to display road closure sources.
        bus.$on('road_closure_sources', (e: boolean) => {
            this.road_closure_sources = e
        });

        // Catch the event from the 'Filter', when it's list has been updated.
        bus.$on('filter_lists_updated', (e: any) => {
            if (this.isDirty) {
                this.refreshMap(e)
            }
        });

        // Display the affected enterprise legend on mounting (if needed).
        this.setFiltersDisabled(this.isFiltersDisabled); // Filters Disabled
        //this.setFiltersVisible(!this.isFiltersDisabled); // Filters Invisible

    }


    destroyed() {
        // Remove things that the Events layers take control of once the Events panel is destroyed.
        this.setFiltersDisabled(false); // Filters Disabled
        //this.setFiltersVisible(true); // Filters Invisible
        this.setEnterpriseLegend(false)
        // If value is set to null, don't reset the filter values, however any other value will reset them.
        // this.setFilterParams({ filter: 'commodity', params: null })
    }


    downloadImpactReport() {

        // Generate signed URL to access impact report from Google Storage Bucket

        if (this.hasEventDates) {
            Vue.axios({
                url: buildUrl(endpoints.impactReportUrl(this.event_date))
            }).then((response: any) => {
                var signed_url: any = response.data;
                (window as any).open(signed_url, '_self');
            }, (error: any) => {
                this.report_not_ready_alert = true
                console.log(error);
            });
            //(window as any).open('./docs/acs_reports/acs_report_sample.pdf');
        }

    }

    createScenario(){
        if (this.hasEventDates) {
            bus.$emit('scenario_closed_roads', {event_date: this.event_date});
        }
    }


    onDateChange(e: any) {
        // Need to update commodity filter here with relevant query params - so the selection list updates.
        if (this.events.report_on === 'events') {
            this.isDirty = true;
            this.setFilterLists();
        } else {
            this.refreshMap(e)
        }
    }


    onLayerChange(e: any) {
        // Need to update commodity filter here with relevant query params - so the selection list updates.
        this.isDirty = true;
        this.setFilterLists();
    }


    onDeltaTrailersCommodityChange(e: any) {
        this.refreshMap(null)
    }


    refreshMap(e: any) {
        this.isDirty = false;
        this.setEnterpriseLegend(this.isEnterpriseLegendEnabled);
        this.$emit('refresh_map', e);

    }

    setFilterLists(){
        this.setFilterParams({ filter: 'commodity', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'commod_l2', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'commod_l3', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'origenterprise', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'origenterprisecategory', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'destenterprise', params: { report_type: this.report_type, date: this.event_date } });
        this.setFilterParams({ filter: 'destenterprisecategory', params: { report_type: this.report_type, date: this.event_date } });
    }

    // Old getters which were used to display a delta symbol in html.
    // Not required anymore, but the technique to display wierd symbols could be useful? Will eventually delete if not.

    //get eventsTitle() {
    //    return "Events (" + this.decodeHtml("&Delta;") + ")"
    //}

    //get eventsLabel() {
    //    return this.decodeHtml("&Delta;").concat(" Events")
    //}

    //get commoditiesLabel() {
    //    return this.decodeHtml("&Delta;").concat(" Commodities")
    //}

    //decodeHtml(html: string) {
    //    var txt = document.createElement("textarea");
    //    txt.innerHTML = html;
    //    return txt.value;
    //}


}


