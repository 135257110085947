export var bordersStyle = (tileLayer: string, tileSource: string, sourceLayer: string) => {

    return {
        "id": tileLayer,
        "type": "line",
        "source": tileSource,
        'source-layer': sourceLayer,
        "layout": {},
        "paint": {
            "line-color": "#627BC1",//
            "line-width": 1
        }
    }
}

// The feature-state dependent fill-opacity expression will render the hover effect
// when a feature's hover state is set to true.
export var selectedBordersStyle = (tileLayer: string, tileSource: string, sourceLayer: string) => {

    return {
        "id": tileLayer,
        "type": "fill",
        "source": tileSource,
        'source-layer': sourceLayer,
        "layout": {},
        "paint": {
            "fill-opacity": ["case",
                ["boolean", ["feature-state", "hover"], false],
                1,
                0
            ]
        }
    }
}


export var highlightedStyle = (tileLayer: string, tileSource: string, sourceLayer: string) => {

    return {
        "id": tileLayer,
        "type": "fill",
        "source": tileSource,
        "source-layer": sourceLayer,
        "paint": {
            "fill-outline-color": "black",//#484896
            "fill-color": "#6e599f",
            "fill-opacity": 0.4
        },
        "filter": ["==", "code", ""]
    }
}
