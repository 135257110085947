import { GetterTree } from 'vuex';
import { TablesState } from './types';
import { RootState } from '../types';



export const getters: GetterTree<TablesState, RootState> = {

    isLoading(state, getters): boolean {
        for (var table in state.reports) {
            //check if the property/key is defined in the object itself, not in parent
            if (state.reports.hasOwnProperty(table)) {
                if (state.reports[table].isLoading) {return true};
            }
        }
        return false;
    },
    queryParams(state): boolean {
        return state.queryParams;
    },

};
