export enum TaskStatus {
    Submitted = "Submitted",
    Complete = "Complete",
    Running = "Running",
    Failed = "Failed",
    Cancelled = "Cancelled"
}

export enum TaskType {
    Scenario = "scenario",
    Scenario_test = "scenario_test"
}

export enum ScenarioAction {
    CLOSED_ROADS = "Closed Roads",
    SEGMENT = "Close Segment",
    ROAD = "Close Road",
    AREA = "Close Area",
}

export enum Baseline {
    SHORT_TERM_FORECAST = "short-term",
    ANNUAL = "annual"
}

export enum PolygonOperator {
    UNION = "union",
    INTERSECTION = "intersection"
}
