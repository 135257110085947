import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PolygonColors extends Vue {

    //Index to cycle through polygon colors
    polygonColorIndex = 0;

    polygonColors = ['#F54EA2', // Deep Pink
        '#0072B2', // Deep Sky Blue
        '#546E7A', // Cadet Blue
        '#56B4E9', // Alice Blue
        '#FFED6F', // Gold
        '#9ACD32', // Yellow Green
        '#009E73', // Spring Green
        '#E69F00', // Dark Orange
        '#CC79A7', // Medium Violet Red
        '#D95B43'  // Coral
    ];

    // Get the next color in the array and cycle through
    getPolygonColor() {
        var color = this.polygonColors[this.polygonColorIndex];
        this.polygonColorIndex = (this.polygonColorIndex + 1) % this.polygonColors.length;
        return color;
    };

}
