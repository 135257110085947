










































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { isNullOrUndefined } from 'util';

@Component
export default class InfoPanelDashboard extends Vue {

    @Prop() private data!: any;
    @Prop() private unitType!: string;
    @Prop() private dataColumn!: string;
    @Prop() private statisticType!: string;
    @Prop() private isLimited!: boolean;
    @Prop() private isLimitedAvg!: boolean;
    @Prop() private isEnterpriseInfo!: boolean;
    @Prop() private isSensitive!: boolean;
    @Prop() private isAllowedSensitiveInfo!: boolean;

    get main_unit_type(): string {
        if (isNullOrUndefined(this.unitType)) {
            return 'Trailers';
        }
        else {
            return this.unitType;
        }
    }

    get main_data_column(): string {
        if ((this as any)[this.dataColumn]) {
            return this.dataColumn;
        }
        else {
            return 'trailer_loads'
        }
    }

    get main_unit_type_singular(): string {
        return this.main_unit_type.substring(0, this.main_unit_type.length - 1);
    }

    get main_stat(): string {
        if (isNaN(this.data[this.main_data_column])) {
            return 'NaN';
        }
        else {
            return this.numberWithCommas((this.data[this.main_data_column]).toFixed(2))
        }

    }

    get trailer_loads(): string {
        if (isNaN(this.data.trailer_loads)) {
            return 'NaN';
        }
        else {
            return this.numberWithCommas((this.data.trailer_loads).toFixed(2))
        }
    }

    get tonnes(): string {
        if (isNaN(this.data.tonnes)) {
            return 'NaN';
        }
        else {
            return this.numberWithCommas((this.data.tonnes).toFixed(2))
        }
    }

    get tonnes_per_trailer(): string {
        return this.numberWithCommas(Math.round(this.data.tonnes_per_trailer))
    }

    get transport_costs(): string {
        return this.numberWithCommas(Math.round(this.data.transport_costs))
    }

    get trip_transport_costs(): string {
        return this.numberWithCommas(Math.round(this.data.trip_transport_costs))
    }

    get trip_freight_value(): string {
        return this.numberWithCommas(Math.round(this.data.trip_freight_value))
    }

    get trip_avg_distance(): string {
        return this.numberWithCommas(Math.round(this.data.trip_avg_distance))
    }

    get trip_avg_duration_hrs(): string {
        return this.numberWithCommas(Math.floor(this.data.trip_avg_duration))
    }

    get co2(): string {
        return this.numberWithCommas(Math.round(this.data.co2_tn))
    }

    get trip_avg_duration_mins(): number {
        var hrs = parseInt(this.data.trip_avg_duration)
        var frac = this.data.trip_avg_duration - hrs;
        return Math.round(frac * 60);
        // return this.numberWithCommas()
    }

    numberWithCommas(x: any): string {
        return Number(x).toLocaleString()
    }

    //Adjust title for the average trip duration according to transport mode
    get avgTripDurationOperator() {
        if (this.unitType === 'Trailers') {
            return "driver"
        } else if (this.unitType === 'Wagons') {
            return "crew"
        }
    }

    //Adjust title for values in the dashboard whether it is a road segment or an enterprise
    get selectedDataItem() {
        return this.isEnterpriseInfo ? "enterprise" : "segment"
    }
}


