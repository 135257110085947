export interface ILayerVisibility { layer: string, value: boolean }

export interface MapState {
    layerStyle: string;
    marker?: lngLat;
    featureOfInterest?: any;
    hiddenLayers: string[];
    flyTo: number[];
}

export interface lngLat {
    lng: number, lat: number;
}

export enum Layers {
    Enterprises = 'transit-enterprises',
    Roads = "transit-roads",
    RoadsBackground = "transit-roads-bg",
    Rail = "transit-rail",
    RailBackground = "transit-rail-bg",
    Sea = "transit-sea",
    SeaBackground = "transit-sea-bg",
    RoadEventsBaseline = "road-events-baseline",
    RoadEventsScenario = "road-events-scenario",
    DeltaTrailers = "events-delta-trailers",
    ClosedRoads1 = "events-closed-roads-1",
    ClosedRoads7 = "events-closed-roads-7",
    ClosureCategories = "closure-categories",
    BlockedEnterprisesOrigin = "events-blocked-enterprises-orig",
    BlockedEnterprisesDestination = "events-blocked-enterprises-dest",
    BlockedEnterprisesUnaffected = "events-blocked-enterprises-unaffected",
    BlockedEnterprisesPartial = "events-blocked-enterprises-partial",
    BlockedEnterprisesTotally = "events-blocked-enterprises-totally",
    BlockedEnterprisesImpactedBy = "events-blocked-enterprises-impactedby",
    EventsEnterprisesBaseline = "events-enterprises-baseline"
}

export enum Sources {
    Enterprises = 'enterprises-source',
    Roads = "roads-source",
    RoadsBackground = "roads-source-bg",
    Rail = "rail-source",
    RailBackground = "rail-source-bg",
    Sea = "sea-source",
    SeaBackground = "sea-source-bg",
    RoadEventsBaseline = "road-events-baseline-source",
    RoadEventsScenario = "road-events-scenario-source",
    DeltaTrailers = "events-delta-trailers-source",
    ClosedRoads = "events-closed-roads-source",
    ClosureCategories = "closure-categories-source",
    BlockedEnterprisesOD = "events-blocked-enterprises-od-source",
    BlockedEnterprises = "events-blocked-enterprises-source",
    BlockedEnterprisesImpactedBy = "events-blocked-enterprises-impactedby-source",
    EventsEnterprisesBaseline = "events-enterprises-baseline-source"
}
