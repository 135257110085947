






















































































    import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
    import { State, Action, Getter } from 'vuex-class';

    import LegendItem from './LegendItem.vue';

    import { MapState } from '@/store/map/types';
    import { DatasetState } from '@/store/datasets/types';
    import { DensityType } from '@/store/datasets/types';
import { isNullOrUndefined } from 'util';

    const namespace: string = 'map';

    Vue.config.productionTip = false

    @Component({
        components: {
            LegendItem
        }
    })
    export default class NetworkLegend extends Vue {

        @Prop() private layer!: any;

        @State('map') mapstate!: MapState;
        @State('datasets') datasets!: DatasetState;
        @Action('setLayerStyle', { namespace }) setLayerStyle: any;

        @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;

        DensityType: any = DensityType;

        get densityType() {
            return this.datasets.type;
        }


        // Selected Layer Style
        get layerStyle() {
            return this.mapstate.layerStyle;
        }
        set layerStyle(layerStyle: string) {
            this.setLayerStyle(layerStyle);
        }

        @Watch('layer.layerId')
        onLayerChanged(val: any, oldVal: any) {
            // Note: using the mandatory prop on the radio group is setting the last style,
            // So use this watcher instead to set the first style if not available.
            if (!isNullOrUndefined(val)) {
                if (!this.layer.legendStyles.includes(this.mapstate.layerStyle)) {
                    this.setLayerStyle(this.layer.legendStyles[0]);
                }
            }
        }


        // Need to adjust the legend title in some circumstances.
        legendTitle(layer: string): string {

            if (this.densityType === DensityType.Rail && layer === 'trailer_loads') {
                return "Annual Wagons";
            }
            else if (this.densityType === DensityType.Rail && layer === 'tonnes_per_trailer') {
                return "Tonnes/Wagon";
            }
            else if (this.densityType === DensityType.Sea && layer === 'trailer_loads') {
                return "Annual Containers";
            }
            else if (this.densityType === DensityType.Sea && layer === 'tonnes_per_trailer') {
                return "Tonnes/Container";
            }
            else if ((this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) && layer === 'trailer_loads') {
                return "Weekly Trailers";
            }
            else if ((this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) && layer === 'tonnes') {
                return "Weekly Tonnes";
            }
            else {
                return (this.legendStyles as any)[layer].title
            }

        }


        // Legend bindings
        private legendStyles = {

            trailer_loads_difference: {
                title: "Change in trailers",
                items: [
                    { color: "#007206", value: "< -501" },
                    { color: "#2A8E0A", value: "-500 \u2014 -351" },
                    { color: "#65AB14", value: "-350 \u2014 -251" },
                    { color: "#95C617", value: "-250 \u2014 -151" },
                    { color: "#C4E31D", value: "-150 \u2014 -51" },
                    { color: "#828282", value: "-50 \u2014 50" },
                    { color: "#F9DF1A", value: "51 \u2014 150" },
                    { color: "#FDBD11", value: "151 \u2014 250" },
                    { color: "#FF9A0B", value: "251 \u2014 350" },
                    { color: "#FE710B", value: "351 \u2014 500" },
                    { color: "#FC3B09", value: "> 501" },
                ],
                isSensitive: false,
            },

            trailer_loads: {
                title: "Annual Trailers",
                items: [
                    { color: "#00F5F5", value: "1 - 250" },
                    { color: "#2DC8F7", value: "251 - 500" },
                    { color: "#369AF7", value: "501 - 1,000" },
                    { color: "#3674F7", value: "1,001 - 2,000" },
                    { color: "#2F4DF7", value: "2,001 - 5,000" },
                    { color: "#1B22F5", value: "5,001 - 10,000" },
                    { color: "#6200F5", value: "10,001 - 20,000" },
                    { color: "#8700F5", value: "20,001 - 50,000" },
                    { color: "#D000F5", value: "50,001 - 100,000" },
                    { color: "#F500F5", value: "> 100,001" },
                ],
                isSensitive: false,
            },

            tonnes: {
                title: 'Annual Tonnes',
                items: [
                    { color: "#65E0F9", value: "1 - 250,000" },
                    { color: "#369AF7", value: "250,001 - 500,000" },
                    { color: "#3100F5", value: "500,001 - 750,000" },
                    { color: "#AB00F5", value: "750,001 - 1,000,000" },
                    { color: "#F500F5", value: "> 1,000,001" },
                ],
                isSensitive: false,
            },

            tonnes_per_trailer: {
                title: 'Tonnes/Trailer',
                items: [
                    { color: "#65E0F9", value: "1 - 25" },
                    { color: "#369AF7", value: "26 - 50" },
                    { color: "#3100F5", value: "51 - 100" },
                    { color: "#AB00F5", value: "101 - 150" },
                    { color: "#F500F5", value: "> 151" },
                ],
                isSensitive: false,
            },

            trip_transport_costs: {
                title: 'Total Transport Costs',
                items: [
                    { color: "#65E0F9", value: "$1 - $25M" },
                    { color: "#369AF7", value: "$25M - $100M" },
                    { color: "#3100F5", value: "$100M - $250M" },
                    { color: "#AB00F5", value: "$250M - $500M" },
                    { color: "#F500F5", value: "> $500M" },
                ],
                isSensitive: false,
            },

            trip_freight_value: {
                title: 'Freight Value',
                items: [
                    { color: "#65E0F9", value: "$1 - $250M" },
                    { color: "#369AF7", value: "$250M - $500M" },
                    { color: "#3100F5", value: "$500M - $1B" },
                    { color: "#AB00F5", value: "$1B - $5B" },
                    { color: "#F500F5", value: "> $5B" },
                ],
                isSensitive: false,
            },

            trip_avg_distance: {
                title: 'Average Trip Distance',
                items: [
                    { color: "#65E0F9", value: "1 - 200 km" },
                    { color: "#369AF7", value: "201 - 400 km" },
                    { color: "#3100F5", value: "401 - 600 km" },
                    { color: "#AB00F5", value: "601 - 800 km" },
                    { color: "#F500F5", value: "> 801 km" },
                ],
                isSensitive: false,
            },

            trip_avg_duration: {
                title: 'Average Trip Duration',
                items: [
                    { color: "#65E0F9", value: "1 - 4 hrs" },
                    { color: "#369AF7", value: "5 - 8 hrs" },
                    { color: "#3100F5", value: "9 - 12 hrs" },
                    { color: "#AB00F5", value: "13 - 16 hrs" },
                    { color: "#F500F5", value: "> 17 hrs" },
                ],
                isSensitive: false,
            },

            co2_tn: {
                title: 'CO\u2082 Emissions',
                items: [
                    { color: "#65E0F9", value: "1 - 50,000 t" },
                    { color: "#369AF7", value: "50,001 - 100,000 t" },
                    { color: "#3100F5", value: "100,001 - 150,000 t" },
                    { color: "#AB00F5", value: "150,001 - 200,000 t" },
                    { color: "#F500F5", value: "> 200,001 t" },
                ],
                isSensitive: false,

            },

            truck: {
                title: 'PBS Access',
                items: [
                    { color: "#F0F", value: "Level 1 (Semi-Trailer)" },
                    { color: "#FF0", value: "Level 2a (B-Double)" },
                    { color: "#0F0", value: "Level 3a (Type 1 Road Train)" },
                    { color: "#F00", value: "Level 4a (Type 2 Road Train)" },
                ],
                isSensitive: false,
            },

            surface: {
                title: 'Surface Type',
                items: [
                    { color: "#31a354", value: "Sealed" },
                    { color: "#fb6a4a", value: "Unsealed" },
                ],
                isSensitive: true,
            },

            road_speed: {
                title: 'Speed Limit',
                items: [
                    { color: "#65E0F9", value: "5 km/hr" },
                    { color: "#339FFF", value: "20 km/hr" },
                    { color: "#2BC46A", value: "40 km/hr" },
                    { color: "#FF0000", value: "60 km/hr" },
                    { color: "#ff1aff", value: "80 km/hr" },
                    { color: "#ff944d", value: "95 km/hr" },
                    { color: "yellow", value: "110 km/hr" },
                ],
                isSensitive: true,
            },

            road_rank: {
                title: 'Road Rank',
                items: [
                    { color: "#2ECC71", value: "Rank 5" },
                    { color: "yellow", value: "Rank 4" },
                    { color: "#ffb380", value: "Rank 3" },
                    { color: "#ff0080", value: "Rank 2" },
                    { color: "#cc3300", value: "Rank 1" },
                ],
                isSensitive: true,
            },

            rail_speed: {
                title: 'Speed Limit',
                items: [
                    { color: "#65E0F9", value: "< 30 km/hr" },
                    { color: "#339FFF", value: "35 km/hr" },
                    { color: "#2BC46A", value: "40 km/hr" },
                    { color: "#FF0000", value: "50 km/hr" },
                    { color: "#ff1aff", value: "55 km/hr" },
                    { color: "#ff944d", value: "60 km/hr" },
                    { color: "#cc3300", value: "65 km/hr" },
                    { color: "#99ceff", value: "70 km/hr" },
                    { color: "#3100F5", value: "75 km/hr" },
                    { color: "#AB00F5", value: "80 km/hr" },
                ],
                isSensitive: false,
            },

            gauge: {
                title: 'Rail Gauge',
                items: [
                    { color: "#339FFF", value: "Tramway: 610 mm" },
                    { color: "#F0F", value: "Narrow: 1067 mm" },
                    { color: "#FF0", value: "Standard: 1435 mm" },
                    { color: "#0F0", value: "Broad: 1600 mm" },
                    { color: "#F00", value: "Dual" },
                    { color: "#000000", value: "Unknown" },


                ],
                isSensitive: false,
            },

        }


    }


