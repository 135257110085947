



















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import parsePhoneNumber from 'libphonenumber-js';
import { isNullOrUndefined } from 'util';

import { AuthState } from '@/store/auth/types';

import { mixins } from 'vue-class-component';
import Validation from './validation-mixin'

@Component
export default class Account extends mixins(Validation) {

    @State('auth') auth!: AuthState;

    @Action('auth/getProfile') getProfile: any;
    @Action('auth/updateProfile') updateProfile: any;
    @Action('auth/changePassword') changePassword: any;
    @Action('auth/MFA_deactivate') deactivate: any;

    @Getter('auth/isAdmin') isAdmin?: boolean;

    user: any = { phone_number: '' }; // Note need to initialise mobile number with something other than undefined so the the phone_number validator parses.
    password: any = {}
    get error() {
        return this.status_message !== ''
    }

    getFormattedDate(date: string): string {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString("en-AU", options);
    }

    get mfaMethod() {
        if (this.auth.mfa_methods.length === 0) { return null };
        var mfa_method = this.auth.mfa_methods[0].name
        if (mfa_method === "sms_twilio") {
            return "SMS";
        } else if (mfa_method === "call_twilio") {
            return "Phone Call";
        } else if (mfa_method === "email") {
            return "E-Mail";
        } else if (mfa_method === "yubi") {
            return "YubiKey";
        } else if (mfa_method === "app") {
            return "Mobile Authenticator";
        } else if (mfa_method === "yubiadmin") {
            return "YubiKey for Administrators";
        }
        else {
            return mfa_method;
        }
    }


    loading: boolean = false;
    status_message = '';
    status_type = 'info';
    showPasswordCurrent = false;
    showPassword = false;
    showPasswordConfirm = false;
    valid_password = false;
    editing_profile = false;
    profile_modified = false;
    changing_password = false;
    security_expansion_panels = []

    get passwordLastChanged() {
        var date = !isNullOrUndefined(this.user.date_password_changed) ? this.user.date_password_changed : this.user.date_joined
        return this.getFormattedDate(date);
    }


    created() {
        var vm = this;

        this.getProfile()
            .then(function (response: any) {
                vm.user = response.data
            })
            .catch(function (error: any) {
                //console.log(error)
                var msg = 'An error has occurred. Try again shortly.';
                if (error.response.status === 429) {
                    msg = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                }
                vm.status_message = msg;
            }).finally(function () { vm.loading = false; });
    }



    onUpdateProfile() {

        var vm = this;
        vm.status_message = '';
        vm.loading = true;

        // Update the phone number with the international parsed number.
        var pn = parsePhoneNumber(this.user.phone_number, 'AU')
        if (!isNullOrUndefined(pn)){
            this.user.phone_number = pn.number;
        }

        this.updateProfile(this.user)
            .then(function (response: any) {
                //console.log(response)
                vm.user = response.data;
                vm.profile_modified = false;
                vm.editing_profile = false;
                vm.status_type = "success";
                vm.status_message = "Profile successfully updated!"
            })
            .catch(function (error: any) {
                //console.log(error)
                var msg = 'An error has occurred. Try again shortly.';
                if (error.response.status === 429) {
                    msg = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                }
                if (error.response.headers['content-type'] == 'application/json') { // Sends html error on say e-mails.
                    for (const field of Object.keys(error.response.data)) {
                        msg = error.response.data[field][0];
                        break;
                    }
                }
                vm.status_message = msg;
            }).finally(function () { vm.loading = false; });
    }

    onChangePassword() {

        this.status_message = "";
        this.status_type = "info";

        if ((this.$refs.form_password as any).validate()) {

                var vm = this;
                vm.loading = true;
                this.changePassword(this.password).then(function (response: any) {
                    //console.log(response);
                    vm.status_type = 'success';
                    vm.status_message = response.data.detail;
                    vm.changing_password = false;
                    vm.security_expansion_panels = [];
                    (vm.$refs.form_password as any).reset()

                }).catch(function (error: any) {
                    //console.log(error);
                    if (!isNullOrUndefined(error.response.data.detail)) {
                        if (error.response.status === 429) {
                            vm.status_type = 'error';
                            vm.status_message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                        } else {
                            vm.status_type = 'error';
                            vm.status_message = error.response.data.detail;
                        }
                    } else {
                        vm.status_type = 'error';
                        if (error.response.headers['content-type'] == 'application/json') { // Sends html error on say e-mails.
                            for (const field of Object.keys(error.response.data)) {
                                vm.status_message = error.response.data[field][0];
                                break;
                            }
                        }
                    }

                }).finally(function () { vm.loading = false; });

        }
    }

    change_mfa() {

        var vm = this;

        this.deactivate({})
            .then(function (resp: any) {
                console.log(resp);
                vm.$router.push("/MFA")

            })
            .catch(function (err: any) {
                console.log(err)
            });
    }
}



