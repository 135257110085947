import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
//import { profile } from './profile/index';
import { datasets } from './datasets/index';
import { filters } from './filters/index';
import { map } from './map/index';
import { tables } from './tables/index';
import { auth } from './auth/index';
import { assistant } from './assistant/index';
import { events } from './events/index';
import localForage from 'localforage'

Vue.use(Vuex);

import VuexPersistence from 'vuex-persist'
import createMutationSharer from "vuex-shared-mutations";

const vuexLocal = new VuexPersistence<RootState>({
    //storage: window.localStorage
    storage: localForage as any,
    asyncStorage: true,
});

const mutationLookup: any = {
    setFilterValue(store: any, mutation: any) {
        if (mutation.payload.value.toString() !== "") {
//            return { label: mutation.payload.filter, value: mutation.payload.value.toString() }
            return { label: mutation.payload.filter, value: null }
        }
        return null;
    },
    datasetsLoaded(store: any, mutation: any) {
       // return { label: mutation.payload[0], value: mutation.payload[0] }
        return { label: mutation.payload[0], value: null }
    },
    setFilterEnabled(store: any, mutation: any) {
        return { label: mutation.payload.filter, value: mutation.payload.value | 0}
    },
    setMarker(store: any, mutation: any) {
        let vals = mutation.payload ? mutation.payload.toString() : 'Clear Marker';
        //return { label: vals, value: vals }
        return { label: vals, value: null }
    },
    setFiltersNotDirty(store: any, mutation: any) {
        //return { label: store.auth.user.username, value: JSON.stringify(store.filters) }
        return { label: store.auth.user.username, value: null }
    },
    setDatasetType(store: any, mutation: any) {
        //return { label: store.auth.user.username, value: mutation.payload }
        return { label: mutation.payload, value: null }
    },
    auth_mfa_methods(store: any, mutation: any) {
        let val = mutation.payload[0] ? mutation.payload[0].name : "deactivated";
        //return { label: store.auth.user.username, value: val }
        return { label: val, value: null }
    },
    auth_mfa_deactivate(store: any, mutation: any) {
        //return { label: store.auth.user.username, value: store.auth.mfa_methods[0].name }
        return { label: store.auth.mfa_methods[0].name, value: null }
    },
    auth_success_mfa(store: any, mutation: any) {
        let val = store.auth.mfa_methods[0] ? store.auth.mfa_methods[0].name : "deactivated";
        //return { label: store.auth.user.username, value: val }
        return { label: store.auth.user.username, value: null }
    },
    auth_success(store: any, mutation: any) {
        let val = store.auth.mfa_methods[0] ? store.auth.mfa_methods[0].name : "deactivated";
        //return { label: store.auth.user.username, value: val }
        return { label: store.auth.user.username, value: null }
    },
    auth_error(store: any, mutation: any) {
        let val = store.auth.mfa_current ? store.auth.mfa_current.method : "deactivated";
        //return { label: store.auth.user.username, value: val }
        return { label: store.auth.user.username, value: null }
    },
    auth_mfa(store: any, mutation: any) {
        //return { label: store.auth.user.username, value: mutation.payload.mfa.method }
        return { label: mutation.payload.mfa.method, value: null }
    },
    Logout(store: any, mutation: any) {
        //return { label: store.auth.user.username, value: store.auth.user.username }
        return { label: store.auth.user.username, value: null }
    },

}

const myPlugin = (store: { subscribe: (arg0: (m: any, s: any) => void) => void; }) => {
    // called when the store is initialized
    store.subscribe((m: { type: { split: (arg0: string) => void; }; }, s: any) => {
        //console.log(m);
        //console.log(s)

        try {
            let msplit: string[] = String(m.type).split('/');
            let mutation: string = msplit[0];
            let action: string = msplit[1];
            let payload = mutationLookup[action] && mutationLookup[action](s, m);
            if (payload) {
                (Vue as any).gtm.trackEvent({
                    event: 'mutation',
                    category: mutation,
                    action: action,
                    label: payload.label,
                    value: payload.value,
                    noninteraction: true,
                    //noninteraction: mutation,
                    //...payload
                });
            }
        } catch (e) {
            throw new Error(`Error with gtm.trackEvent: ${e}`);
        }

        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    });
}

const mutationSharerPlugin = createMutationSharer({
    predicate: [
        'datasets/setDatasetType',
        'map/setFlyTo',
        'assistant/setMessages',
        'assistant/setProgressiveMessage',
        'assistant/setIsStandAlone',
        'assistant/setSyncWithMap',
        'assistant/addMessage',
        'assistant/setQuestion',
        'assistant/setSubject',
        'assistant/setLoading',
        'tables/setIsStandAlone',
        'tables/setSyncWithMap',
        'tables/setIsDownloadDisabled',
        'tables/setTableData',
        'tables/setTableDownloading',
        'tables/setTableLoading',
        'tables/setQueryParams'
        ]
});

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0' // a simple property
    },
    modules: {
        //profile,
        datasets,
        filters,
        map,
        tables,
        auth,
        assistant,
        events
    },
    plugins: [vuexLocal.plugin, myPlugin, mutationSharerPlugin]
};

export default new Vuex.Store<RootState>(store);
