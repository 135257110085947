import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AssistantState } from './types';
import { RootState } from '../types';


export const state: AssistantState = {
    messages: [],
    progressiveMessage: {content: "", role: ""},
    question: '',
    subject: 'transit_web',
    loading: false,
    isStandAlone: false,
    syncWithMap: false,
    playAudioResponses: false
};

const namespaced: boolean = true;

export const assistant: Module<AssistantState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
