import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { FiltersState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'
import { DensityType } from '@/store/datasets/types';

export const state: FiltersState = {

    filter_types: {
        roadname: { title: "Road Name", value: [], enabled: true, isSelectable: true, item_list_url: endpoints.roadnamesUrl('{dataset}'), worksWith: [DensityType.Road, DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario], isSensitive: false },
        railname: { title: "Rail Line", value: [], enabled: true, isSelectable: true, item_list_url: endpoints.railnamesUrl('{dataset}'), worksWith: [DensityType.Rail], isSensitive: false },
        seaname: { title: "Sea Channel", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.seanamesUrl('{dataset}'), worksWith: [DensityType.Sea], isSensitive: false },
        gauge: { title: "Rail Gauge", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.gaugesUrl('{dataset}'), worksWith: [DensityType.Rail], isSensitive: false },
        accesstype: { title: "PBS Access", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.accessTypesUrl('{dataset}'), worksWith: [DensityType.Road, DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario], isSensitive: false },
        surfacetype: { title: "Surface Type", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.surfaceTypesUrl('{dataset}'), worksWith: [DensityType.Road, DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario], isSensitive: true },
        commodity: { title: "Commodity", value: [], enabled: true, isSelectable: true, item_list_url: endpoints.commoditiesUrl('{dataset}', '{density_type}'), isSensitive: false, item_list_params: {} },
        commod_l2: { title: "Industry", value: [], enabled: true, isSelectable: true, item_list_url: endpoints.commod_l2Url('{dataset}', '{density_type}'), isSensitive: false },
        commod_l3: { title: "Sector", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.commod_l3Url('{dataset}', '{density_type}'), isSensitive: false },
        origenterprise: { title: "Origin Enterprise Type", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.enterprisesUrl('{dataset}', '{density_type}'), isSensitive: false },
        origenterprisecategory: { title: "Origin Enterprise Category", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.enterpriseCategoriesUrl('{dataset}', '{density_type}'), isSensitive: false },
        destenterprise: { title: "Destination Enterprise Type", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.enterprisesUrl('{dataset}', '{density_type}'), isSensitive: false },
        destenterprisecategory: { title: "Destination Enterprise Category", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.enterpriseCategoriesUrl('{dataset}', '{density_type}'), isSensitive: false },
        month: { title: "Month", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.monthsUrl('{dataset}'), worksWith: [DensityType.Road, DensityType.Rail, DensityType.Sea], isSensitive: false },
        boundary1: { title: "Local Government Area", value: [], enabled: true, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "LGA", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary2: { title: "Statistical Area Level 2 (SA2)", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "SA2", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary3: { title: "Statistical Area Level 3 (SA3)", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "SA3", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary4: { title: "Statistical Area Level 4 (SA4)", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "SA4", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary5: { title: "States and Territories", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "STE11a", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary6: { title: "Natural Resource Management Regions", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "NRM", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        boundary7: { title: "Regional Development Areas", value: [], enabled: false, isSelectable: true, item_list_url: endpoints.boundariesUrl('{dataset}'), tileSource: "boundaries-source", tileLayer: "RDA", worksWith: [DensityType.Road, DensityType.Rail], isSensitive: false },
        critical_link: { title: "Critical Link", value: [], enabled: true, isSelectable: false, isSensitive: false },
        inbound: { title: "Destination Enterprise", value: [], enabled: true, isSelectable: false, isSensitive: false },
        outbound: { title: "Origin Enterprise", value: [], enabled: true, isSelectable: false, isSensitive: false },
    },
    isDirty: false,
    disabled: false,
    visible: true,
    queryParams: {}
};

const namespaced: boolean = true;

export const filters: Module<FiltersState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
