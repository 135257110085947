import { MutationTree } from 'vuex';
import { EventsState } from './types';

export const mutations: MutationTree<EventsState> = {
    setEnterpriseLegend(state, visible: boolean) {
        state.enterprise_legend = visible;
    },
    setReportType(state, report_type: string) {
        state.report_type = report_type;
    },
    setEventDate(state, event_date: string) {
        state.event_date = event_date;
    },
    setReportOn(state, report_on: string) {
        state.report_on = report_on;
    },
    setDeltaTrailers(state, delta_trailers: string) {
        state.delta_trailers = delta_trailers;
    },
    setBlockedEnterpriseId(state, blocked_enterprise_id: string) {
        state.blocked_enterprise_id = blocked_enterprise_id;
    },
    eventDateItemsLoaded(state, event_date_items: string[]) {
        state.event_date_items = event_date_items;
        if (state.event_date_items.length > 0) {
            if (!state.event_date_items.includes(state.event_date)) {
                state.event_date = state.event_date_items[0];
            }
        } else {
            state.event_date = '';
        }
    },
};
