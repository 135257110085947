
import { Layers, Sources } from '@/store/map/types';


export var enterprise_category_stops = [
    ['retail', 'red'],
    ['storage', '#ff9999'],
    ['transport', 'yellow'],
    ['production', '#979A9A'],
    ['mill', '#FF69B4'],
    ['manufacturer', '#cc3300'],
    ['processor', '#99ceff'],
    ['distribution', '#33FF8D'],
    ['mine', '#ffb31a'],
    ['waste', '#8976cf'],
    ['construction', '#C2B280'],
    ['health', '#fcc0a5']
];


export var enterpriseStyle = (source: Sources) => {

    return {
        id: Layers.Enterprises,
        type: 'circle',
        //filter: this.propertyFilter,
        source: source,
        'source-layer': 'enterprises',
        'layout': {
            'visibility': 'visible',
        },
        paint: {
            'circle-radius': {
                stops: [
                    [6, 3],
                    [12, 6],
                    [16, 16]
                ],
            },
            'circle-stroke-width': 0.25,
            'circle-stroke-color': 'black',
            'circle-color': {
                property: 'enterprise_category',
                type: 'categorical',
                default: 'black',
                stops: enterprise_category_stops,
            },
        }
    }
}

export var EventsEnterprisesBaselineStyle = (source: Sources) => {

    return {
        id: Layers.EventsEnterprisesBaseline,
        type: 'circle',
        //filter: this.propertyFilter,
        source: source,
        'source-layer': 'eventsenterprisesbaseline',
        'layout': {
            'visibility': 'visible',
        },
        paint: {
            'circle-radius': {
                stops: [
                    [6, 3],
                    [12, 6],
                    [16, 16]
                ],
            },
            'circle-stroke-width': 0.25,
            'circle-stroke-color': 'black',
            'circle-color': {
                property: 'enterprise_category',
                type: 'categorical',
                default: 'black',
                stops: enterprise_category_stops,
            },
        }
    }
}


export var enterpriseBlockedTotStyle = (source: Sources) => {

    return {
        id: Layers.BlockedEnterprisesTotally,
        type: 'symbol',
        filter:
            ['all',
                ['==', 'block', 0]
            ],
        source: source,
        'source-layer': 'blockedenterprises',
        'layout': {
            'visibility': 'visible',
            'icon-image': 'stop_icon',
            'icon-allow-overlap': true,
            'icon-size': {
                stops: [[6, 0.3],
                [12, 0.5],
                [16, 1]]
            }
        },
        paint: {
            'icon-halo-width': 0.5,
            'icon-halo-color': '#000000',
            'icon-opacity': 1,
            'icon-color': {
                property: 'enterprise_category',
                type: 'categorical',
                default: 'black',
                stops: enterprise_category_stops,
            },
        },
    }
}


export var enterpriseBlockedParStyle = (source: Sources) => {

    return {
        id: Layers.BlockedEnterprisesPartial,
        type: 'symbol',
        filter: ['all',
            ['>=', 'block', 1],
            ['<', 'block_trips_diff', 0],
        ],
        source: source,
        'source-layer': 'blockedenterprises',
        'layout': {
            'visibility': 'visible',
            'icon-image': 'triangle_icon',
            'icon-allow-overlap': true,
            'icon-size': {
                stops: [[6, 0.3],
                [12, 0.5],
                [16, 0.7]]
            }
        },
        paint: {
            'icon-halo-width': 0.5,
            'icon-halo-color': '#000000',
            'icon-opacity': 1,
            'icon-color': {
                property: 'enterprise_category',
                type: 'categorical',
                default: 'black',
                stops: enterprise_category_stops,
            },
        },
    }
}


export var enterpriseBlockedUnStyle = (source: Sources) => {

    return {
        id: Layers.BlockedEnterprisesUnaffected,
        type: 'circle',
        filter: [
            'all',
            ['==', ['get', 'block_trips_diff'], 0],
            [">=", ["zoom"],
                ["match", ["get", "enterprise"],
                    'property',  // enterprise name
                    8, // minimum zoom level
                    0 // fallback for all other enterprises
                ]
            ],
        ],
        source: source,
        'source-layer': 'blockedenterprises',
        'layout': {
            'visibility': 'visible',
        },
        //minzoom: 6,
        paint: {
            'circle-radius': {
                stops: [
                    [6, 3],
                    [12, 6],
                    [16, 16]
                ],
            },
            'circle-stroke-width': 0.25,
            'circle-stroke-color': 'black',
            'circle-color': {
                property: 'enterprise_category',
                type: 'categorical',
                default: 'black',
                stops: enterprise_category_stops,
            },
        }
    }
}


export var enterpriseBlockedODStyle = (source: Sources, layerId: Layers, base: number) => {

    return {
        id: layerId,
        type: 'circle',
        filter: ['all',
            ['==', 'base', base],
            ['!=', 'block_trips_diff', 0]
        ],
        source: source,
        'source-layer': 'blockedenterprisesod',
        paint: {
            // Make circles larger as the user zooms from z12 to z22.
            'circle-radius': {
                property: 'base',
                type: 'categorical',
                stops: [
                    [{ 'zoom': 6, 'value': 0 }, 6],
                    [{ 'zoom': 6, 'value': 1 }, 9],
                    [{ 'zoom': 12, 'value': 0 }, 13],
                    [{ 'zoom': 12, 'value': 1 }, 16],
                    [{ 'zoom': 16, 'value': 0 }, 23],
                    [{ 'zoom': 16, 'value': 1 }, 28],
                ]
            },
            'circle-opacity': 0,
            'circle-stroke-width': 2.5,
            'circle-stroke-color': {
                property: 'base',
                type: 'categorical',
                default: 'black',
                stops: [
                    // Enterprise base: 0-origin, 1-destination
                    [0, 'purple'],
                    [1, 'orange']
                ]
            },
            'circle-stroke-opacity': {
                property: 'base',
                type: 'categorical',
                stops: [
                    // Enterprise base: 0-origin, 1-destination
                    [0, 1],
                    [1, 1]
                ]
            },
        },
    }
}


export var enterpriseBlockedImpactedByStyle = (source: Sources) => {

    return {
        id: Layers.BlockedEnterprisesImpactedBy,
        type: 'symbol',
        source: source,
        'source-layer': 'blockedenterprises_impactedby',
        'layout': {
            'visibility': 'visible',
            'icon-image': 'marker-icon',
            'icon-allow-overlap': true,
            'icon-offset': [0, -20],
            'icon-size': 0.7,
        },

        'paint': {
            'icon-halo-width': 0.5,
            'icon-halo-color': '#000000',
            'icon-opacity': 1,
            'icon-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#FC0541',
                '#a7a7a8'
            ],
        },
    }
}
