





























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'

import { DensityType, DatasetState } from '@/store/datasets/types';
import { FiltersState } from '@/store/filters/types';
import { TablesState, TableType } from '@/store/tables/types';

import LinksReport from './LinksReport.vue';
import CommoditiesReport from './CommoditiesReport.vue';
import MovementsReport from './MovementsReport.vue';

import { endpoints } from "@/endpoints";
import buildUrl from 'build-url';


@Component({
    components: {
        LinksReport,
        CommoditiesReport,
        MovementsReport
    }
})
export default class Reports extends Vue {

    @State('filters') filters!: FiltersState;
    @Getter('filters/isDirty') isFiltersDirty!: boolean;
    @Getter('filters/hasUnconstrainedFilters') hasUnconstrainedFilters!: boolean;

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isAdmin') isAdmin!: boolean;

    @State('datasets') datasets!: DatasetState;

    @State('tables') tables!: TablesState;
    @Action('tables/loadTable') loadTable: any;
    @Action('tables/downloadTable') downloadTable: any;
    @Action('tables/clearTableData') clearTableData: any;
    @Action('tables/clearTablesLoading') clearTablesLoading: any;
    @Action('tables/setStandAlone') setStandAlone: any;
    @Action('tables/setQueryParams') setQueryParams: any;
    @Action('tables/setDownloadDisabled') setDownloadDisabled: any;
    @Action('tables/setTableDownloading') setTableDownloading: any;
    @Action('tables/setSyncWithMap') setSyncWithMap: any;
    @Getter('tables/isLoading') isLoading!: boolean;

    @Prop() private statisticType!: string;
    @Prop() private queryParamsCallback!: any;
    @Prop() private isStandAlone!: boolean;


    activeTable: number = 0;
    public dirtyTables: boolean = true;


    created() {

        // If it is TranSIT Web window tab, clear some variables.
        if (!this.isStandAlone) {
            this.clearTablesLoading();
            this.setDownloadDisabled(true);
            this.setTableDownloading(false);
        }

        // If filters are updated, reset the tables.
        // Notes: the event bus doesn't work across different processes, so this is for the TraNSIT Web browser window only.
        bus.$on('filters_updated', (filterId: string, value: any) => {

            // When the map is reset to having no filters
            if (!this.hasUnconstrainedFilters) {
                this.clearTables() // Clear the tables loading as well (you must have filters to download tables!)
                this.setDownloadDisabled(true);
            }
        });
    }

    get dataset(): any {
        return this.datasets.dataset;
    }

    get densityType(): DensityType {
        return this.datasets.type;
    }

    get showTables(): boolean {
        return (this.mapRefreshed && !this.dirtyTables && !this.inStandAlone) || this.isStandAlone;
    }

    get isHideReportsVisible(): boolean {
        return !this.isTablesDirty && !(!this.mapRefreshed || this.isLoading) && !this.inStandAlone;
    }

    get isTablesDirty(): boolean {
        return (this.mapRefreshed && this.dirtyTables)
    }

    get mapRefreshed(): boolean {
        return this.hasUnconstrainedFilters && !this.isFiltersDirty
    }

    get inStandAlone(): boolean {
        return this.tables.isStandAlone || this.isStandAlone;
    }

    get syncWithMap(): boolean {
        return this.tables.syncWithMap
    }
    set syncWithMap(value: boolean) {
        this.setSyncWithMap(value);
    }


    @Watch('isTablesDirty', { deep: false })
    onIsTablesDirtyChanged(val: boolean, oldVal: boolean) {
        if (val) {
            this.setDownloadDisabled(true);
        }
    }

    @Watch('mapRefreshed', { deep: false })
    onMapRefreshedChanged(val: boolean, oldVal: boolean) {
        if (val) {
            // i.e. the refresh map button was clicked on the filters.
            this.clearTables();
            if (this.tables.syncWithMap) {
                this.applyFiltersToTables();
            }
        }
    }


   //Returns human readabale density type
    get densityTypeReadable() {
        if (this.densityType === 'Road' || this.densityType === 'Road_Events_Baseline' || this.densityType === 'Road_Events_Scenario') {
            return 'Road'
        } else if (this.densityType === 'Rail') {
            return 'Rail'
        } else if (this.densityType === 'Sea') {
            return 'Shipping'
        }
    }


    // Transfer the reports into a new browser window (for multi-monitor use)
    transfer_new_window() {
        if (!this.isStandAlone) {// Should always be this anyway
            this.applyFiltersToTables();
        }
        this.setStandAlone(true);
        const routeData = this.$router.resolve({ name: 'Reports' });
        window.open(routeData.href, '_blank')
    }


    // Transfer the display of tables on an external browser window, back to the main TraNSIT Web window.
    transfer_back() {
        // If the transfer back button was clicked on transit web, whilst there was an update to be applied.
        if (!this.isStandAlone && this.isTablesDirty) {
            this.applyFiltersToTables()
        }
        this.setStandAlone(false);
        this.setSyncWithMap(false);
        if (this.isStandAlone) {
            window.close()
        }
    }


    onClose() {
        this.setSyncWithMap(false);
        if (this.isStandAlone) {
            this.setStandAlone(false);
            window.close();
        }
    }


    // Generate the table data using the selected filters and put the result on the store (for multi-monitor uses)
    applyFiltersToTables() {

        var queryParams = this.queryParamsCallback(this.filters.disabled)
        this.setQueryParams(queryParams); // Save the params to the store so the download button uses the correct params in another window.

        this.loadTable({
            table: TableType.Links,
            url: buildUrl(endpoints.linkReportUrl(this.dataset, this.densityType), { queryParams: queryParams })
        })
        this.loadTable({
            table: TableType.Commodities,
            url: buildUrl(endpoints.commodityReportUrl(this.dataset, this.densityType), { queryParams: queryParams })
        })
        if (this.isAllowedSensitiveInfo) {
            this.loadTable({
                table: TableType.Movements,
                url: buildUrl(endpoints.movementsReportUrl(this.dataset, this.densityType), { queryParams: queryParams })
            })
        }
        this.setDownloadDisabled(false);
        this.setTableDownloading(false);
        this.dirtyTables = false;
    }


    clearTables() {
        this.clearTablesLoading()
        this.dirtyTables = true;
    }


    // Download the data as a CSV file.
    download(e: string) {

        var url = ''
        if (e === TableType.Links) {
            url = endpoints.linkReportUrl(this.dataset, this.densityType)
        }
        if (e === TableType.Commodities) {
            url = endpoints.commodityReportUrl(this.dataset, this.densityType)
        }
        if (e === TableType.Movements) {
            url = endpoints.movementsReportUrl(this.dataset, this.densityType)
        }

        var params = this.queryParamsCallback(this.filters.disabled)
        params['formattype'] = 'csv'

        this.downloadTable({ table: e, url: buildUrl(url, { queryParams: params }) })
            .then((response: any) => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                var re = new RegExp('\"(.*?)\"');
                var filename: any = re.exec(response.headers["content-disposition"])
                filename = filename[1];
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }


    table_configs = {


        //------------------------------------------------------
        //---------  Rail/Road/Sea Summary Definition ----------
        //------------------------------------------------------

        Links: {
            currentPage: 1,
            pagination: {
                sortBy: 'trip_transport_costs',
                descending: true
            },
            search: '',
            headers: {

                roads: {
                    'Weekly': [
                        { text: 'Road Name', align: 'left', sortable: true, value: 'street_name', description: 'The name of this road provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Avg Weekly Trailers', value: 'trailer_loads', align: 'right', description: 'The average weekly trailers travelled along the full distance of this road for the current selection.\n\nNote: If 10 trailers only travelled half of this road weekly, the value reported is 5 average weekly trailers.' },
                        { text: 'Avg Weekly Tonnes', value: 'tonnes', align: 'right', description: 'The average weekly tonnes transported along the full distance of this road for the current selection.\n\nNote: If 20 tonnes is transported on half of this road weekly, the value reported is 10 average weekly tonnes.' },
                        { text: 'Avg Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', description: 'The average weekly tonnes/trailer transported along the full distance of this road for the current selection.\n\nNote: If 30 tonnes/trailer is transported on half of this road weekly, the value reported is 15 average weekly tonnes/trailer.' },
                        { text: 'Road Length (km)', align: 'right', sortable: 'true', value: 'road_km', description: 'The total length of the road provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'road_speed', description: 'The average speed limit along the total length of this road using speed limits provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this road for the current selection.' },
                    ],

                    'Monthly': [
                        { text: 'Road Name', align: 'left', sortable: true, value: 'street_name', description: 'The name of this road provided by the HERE road network.\n\nwww.navigate.com.au'},
                        { text: 'Avg Monthly Trailers', value: 'trailer_loads', align: 'right', description: 'The average monthly trailers travelled along the full distance of this road for the current selection.\n\nNote: If 10 trailers only travelled half of this road monthly, the value reported is 5 average monthly trailers.' },
                        { text: 'Avg Monthly Tonnes', value: 'tonnes', align: 'right', description: 'The average monthly tonnes transported along the full distance of this road for the current selection.\n\nNote: If 20 tonnes is transported on half of this road monthly, the value reported is 10 average monthly tonnes.' },
                        { text: 'Avg Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', description: 'The average monthly tonnes/trailer transported along the full distance of this road for the current selection.\n\nNote: If 30 tonnes/trailer is transported on half of this road monthly, the value reported is 15 average monthly tonnes/trailer.' },
                        { text: 'Road Length (km)', align: 'right', sortable: 'true', value: 'road_km', description: 'The total length of the road provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'road_speed', description: 'The average speed limit along the total length of this road using speed limits provided by the HERE road network.\n\nwww.navigate.com.au' },

                    ],

                    'Annual': [
                        { text: 'Road Name', align: 'left', sortable: true,  value: 'street_name', description: 'The name of this road provided by the HERE road network.\n\nwww.navigate.com.au'},
                        { text: 'Avg Annual Trailers', value: 'trailer_loads', align: 'right', description: 'The average annual trailers travelled along the full distance of this road for the current selection.\n\nNote: If 10 trailers only travelled half of this road annually, the value reported is 5 average annual trailers.' },
                        { text: 'Avg Annual Tonnes', value: 'tonnes', align: 'right', description: 'The average annual tonnes transported along the full distance of this road for the current selection.\n\nNote: If 20 tonnes is transported on half of this road annually, the value reported is 10 average annual tonnes.' },
                        { text: 'Avg Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', description: 'The average annual tonnes/trailer transported along the full distance of this road for the current selection.\n\nNote: If 30 tonnes/trailer is transported on half of this road annually, the value reported is 15 average annual tonnes/trailer.' },
                        { text: 'Road Length (km)', align: 'right', sortable: 'true', value: 'road_km', description: 'The total length of the road provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'road_speed', description: 'The average speed limit along the total length of this road using speed limits provided by the HERE road network.\n\nwww.navigate.com.au' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this road for the current selection.' },
                ]},

                rail: {
                    'Weekly': [
                        { text: 'Rail Line', align: 'left', sortable: true, value: 'rail_name', description: 'The name of this rail line.'},
                        { text: 'Avg Weekly Wagons', value: 'trailer_loads', align: 'right', description: 'The average weekly wagons travelled along the full distance of this rail line for the current selection.\n\nNote: If 10 wagons only travelled half of this rail line weekly, the value reported is 5 average weekly wagons.' },
                        { text: 'Avg Weekly Tonnes', value: 'tonnes', align: 'right', description: 'The average weekly tonnes transported along the full distance of this rail line for the current selection.\n\nNote: If 20 tonnes is transported on half of this rail line weekly, the value reported is 10 average weekly tonnes.' },
                        { text: 'Avg Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', description: 'The average weekly tonnes/wagon transported along the full distance of this rail line for the current selection.\n\nNote: If 30 tonnes/wagon is transported on half of this rail line weekly, the value reported is 15 average weekly tonnes/wagon.' },
                        { text: 'Rail Length (km)', align: 'right', sortable: 'true', value: 'rail_km', description: 'The total length of the rail line from the TraNSIT rail network.' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'rail_speed', description: 'The estimated average speed limit along the total length of this rail line from the TraNSIT rail network.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this rail line for the current selection.' },
                    ],
                    'Monthly': [
                        { text: 'Rail Line', align: 'left', sortable: true, value: 'rail_name', description: 'The name of this rail line.'},
                        { text: 'Avg Monthly Wagons', value: 'trailer_loads', align: 'right', description: 'The average monthly wagons travelled along the full distance of this rail line for the current selection.\n\nNote: If 10 wagons only travelled half of this rail line monthly, the value reported is 5 average monthly wagons.' },
                        { text: 'Avg Monthly Tonnes', value: 'tonnes', align: 'right', description: 'The average monthly tonnes transported along the full distance of this rail line for the current selection.\n\nNote: If 20 tonnes is transported on half of this rail line monthly, the value reported is 10 average monthly tonnes.' },
                        { text: 'Avg Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', description: 'The average monthly tonnes/wagon transported along the full distance of this rail line for the current selection.\n\nNote: If 30 tonnes/wagon is transported on half of this rail line monthly, the value reported is 15 average monthly tonnes/wagon.' },
                        { text: 'Rail Length (km)', align: 'right', sortable: 'true', value: 'rail_km', description: 'The total length of the rail line from the TraNSIT rail network.' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'rail_speed', description: 'The estimated average speed limit along the total length of this rail line from the TraNSIT rail network.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this rail line for the current selection.' },
                    ],
                    'Annual': [
                        { text: 'Rail Line', align: 'left', sortable: true, value: 'rail_name', description: 'The name of this rail line.'},
                        { text: 'Avg Annual Wagons', value: 'trailer_loads', align: 'right', description: 'The average annual wagons travelled along the full distance of this rail line for the current selection.\n\nNote: If 10 wagons only travelled half of this rail line annually, the value reported is 5 average annual wagons.' },
                        { text: 'Avg Annual Tonnes', value: 'tonnes', align: 'right', description: 'The average annual tonnes transported along the full distance of this rail line for the current selection.\n\nNote: If 20 tonnes is transported on half of this rail line annually, the value reported is 10 average annual tonnes.' },
                        { text: 'Avg Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', description: 'The average annual tonnes/wagon transported along the full distance of this rail line for the current selection.\n\nNote: If 30 tonnes/wagon is transported on half of this rail line annually, the value reported is 15 average annual tonnes/wagon.' },
                        { text: 'Rail Length (km)', align: 'right', sortable: 'true', value: 'rail_km', description: 'The total length of the rail line from the TraNSIT rail network.' },
                        { text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'rail_speed', description: 'The estimated average speed limit along the total length of this rail line from the TraNSIT rail network.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this rail line for the current selection.' },
                    ]},

                sea: {
                    'Monthly': [
                        { text: 'Shipping Channel', align: 'left', sortable: true, value: 'sea_name', description: 'The name of this shipping channel.' },
                        { text: 'Avg Monthly Containers', value: 'trailer_loads', align: 'right', description: 'The average monthly containers travelled along the full distance of this shipping channel for the current selection.\n\nNote: If 10 containers only travelled half of this shipping channel monthly, the value reported is 5 average monthly containers.' },
                        { text: 'Avg Monthly Tonnes', value: 'tonnes', align: 'right', description: 'The average monthly tonnes transported along the full distance of this shipping channel for the current selection.\n\nNote: If 20 tonnes is transported on half of this shipping channel monthly, the value reported is 10 average monthly tonnes.' },
                        { text: 'Avg Tonnes/Container', value: 'tonnes_per_trailer', align: 'right', description: 'The average monthly tonnes/container transported along the full distance of this shipping channel for the current selection.\n\nNote: If 30 tonnes/container is transported on half of this shipping channel monthly, the value reported is 15 average monthly tonnes/container.' },
                        { text: 'Shipping Channel Length (km)', align: 'right', sortable: 'true', value: 'sea_km', description: 'The total length of the shipping channel from the TraNSIT shipping network.' },
                        //{ text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'sea_speed', description: 'The estimated average speed limit along the total length of this sea line from the TraNSIT sea network.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this rail line for the current selection.' },
                    ],
                    'Annual': [
                        { text: 'Shipping Channel', align: 'left', sortable: true, value: 'sea_name', description: 'The name of this shipping channel.' },
                        { text: 'Avg Annual Containers', value: 'trailer_loads', align: 'right', description: 'The average annual containers travelled along the full distance of this shipping channel for the current selection.\n\nNote: If 10 containers only travelled half of this shipping channel annually, the value reported is 5 average annual containers.' },
                        { text: 'Avg Annual Tonnes', value: 'tonnes', align: 'right', description: 'The average annual tonnes transported along the full distance of this shipping channel for the current selection.\n\nNote: If 20 tonnes is transported on half of this shipping channel annually, the value reported is 10 average annual tonnes.' },
                        { text: 'Avg Tonnes/Container', value: 'tonnes_per_trailer', align: 'right', description: 'The average annual tonnes/container transported along the full distance of this shipping channel for the current selection.\n\nNote: If 30 tonnes/container is transported on half of this shipping channel annually, the value reported is 15 average annual tonnes/container.' },
                        { text: 'Shipping Channel Length (km)', align: 'right', sortable: 'true', value: 'sea_km', description: 'The total length of the shipping channel from the TraNSIT shipping network.' },
                        //{ text: 'Avg Speed Limit (km/hr)', align: 'right', sortable: 'true', value: 'sea_speed', description: 'The estimated average speed limit along the total length of this sea line from the TraNSIT sea network.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with transporting commodities along the length of this shipping channel for the current selection.' },
                    ]
                },

            }
        },


        //--------------------------------------------------
        //---------  Commodity Summary Definition ----------
        //--------------------------------------------------

        Commodities: {
            currentPage: 1,
            pagination: {
                sortBy: 'trip_transport_costs',
                descending: true
            },
            search: '',
            headers: {

                roads:{
                    'Weekly': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"'},
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Weekly Trailers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total weekly trailers which were used in transporting this commodity for the current selection.' },
                        { text: 'Weekly Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total weekly tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total weekly tonnes per trailer which were transported for this commodity for the current selection.' },
                        //{ text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        { text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ],

                    'Monthly': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"'},
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Monthly Trailers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total monthly trailers which were used in transporting this commodity for the current selection.' },
                        { text: 'Monthly Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total monthly tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total monthly tonnes per trailer which were transported for this commodity for the current selection.' },
                        //{ text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        //{ text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },
                    ],

                    'Annual': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"'},
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Annual Trailers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total annual trailers which were used in transporting this commodity for the current selection.' },
                        { text: 'Annual Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total annual tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total annual tonnes per trailer which were transported for this commodity for the current selection.' },
                        { text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        { text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },
                    ]},

                rail: {
                    'Weekly': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"' },
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Weekly Wagons', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total weekly wagons which were used in transporting this commodity for the current selection.' },
                        { text: 'Weekly Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total weekly tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total weekly tonnes per wagon which were transported for this commodity for the current selection.' },
                        { text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        { text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ],

                    'Monthly': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"' },
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Monthly Wagons', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total monthly wagons which were used in transporting this commodity for the current selection.' },
                        { text: 'Monthly Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total monthly tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total monthly tonnes per wagon which were transported for this commodity for the current selection.' },
                        //{ text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        //{ text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ],

                    'Annual': [
                         { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"' },
                         { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                         { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                         { text: 'Annual Wagons', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total annual wagons which were used in transporting this commodity for the current selection.' },
                         { text: 'Annual Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total annual tonnes which were transported for this commodity for the current selection.' },
                         { text: 'Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total annual tonnes per wagon which were transported for this commodity for the current selection.' },
                         { text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                         { text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                         { text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                         { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                         { text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                         { text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                         { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ]},

                sea: {

                    'Monthly': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.' },
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Monthly Containers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total monthly containers which were used in transporting this commodity for the current selection.' },
                        { text: 'Monthly Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total monthly tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Container', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total monthly tonnes per container which were transported for this commodity for the current selection.' },
                        //{ text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        //{ text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        //{ text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ],

                    'Annual': [
                        { text: 'Commodity', align: 'left', sortable: true, value: 'commodity', groupable: false, description: 'The commodity being transported.' },
                        { text: 'Industry', align: 'left', sortable: true, value: 'industry', description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Sector', align: 'left', sortable: true, value: 'sector', description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.' },
                        { text: 'Annual Containers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total annual containers which were used in transporting this commodity for the current selection.' },
                        { text: 'Annual Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total annual tonnes which were transported for this commodity for the current selection.' },
                        { text: 'Tonnes/Container', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total annual tonnes per container which were transported for this commodity for the current selection.' },
                        //{ text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                        //{ text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per tonne associated with transporting this commodity for the current selection.' },
                        { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                        { text: 'Tonne kms (tkms)', value: 'tonne_kms_links', align: 'right', groupable: false, description: 'The total tonnes for this commodity transported over the total length of a transport network (or corridor) for the current selection.' },
                        //{ text: 'Avg Trip Distance Links (km)', value: 'avg_links_kms', align: 'right', groupable: false, description: 'The average distance of all trips which involve transporting this commodity over the total length of a transport network (or corridor) for the current selection.' },
                        { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },

                    ]},

                },

            },


        //--------------------------------------------------
        //------------  Trip Summary Definition ------------
        //--------------------------------------------------

        Movements: {
            currentPage: 1,
            pagination: {
                sortBy: 'trip_transport_costs',
                descending: true
            },
            search: '',
            headers: {
                roads: [
                    {
                        text: 'Trip Id',
                        align: 'left',
                        sortable: false,
                        value: 'trip_id',
                        description: 'The random trip identifier generated from the TraNSIT model to represent this trip.'
                    },
                    //{ text: 'Commodity Id', align: 'left', sortable: 'true', value: 'commod_id' },
                    { text: 'Commodity', value: 'commodity', align: 'left', description: 'The commodity which was transported on this trip.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"' },
                    { text: 'Annual Trailers', value: 'trailer_loads', align: 'right', description: 'The total annual trailers which were used in transport for this trip.' },
                    { text: 'Annual Tonnes', value: 'tonnes', align: 'right', description: 'The total annual tonnes which were transported on this trip.' },
                    { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', description: 'The total annual tonnes per trailer which were transported on this trip.' },
                    { text: 'Origin Id', value: 'orig_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip began.' },
                    /*{ text: 'Origin PIC', value: 'orig_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip began.' },*/
                    { text: 'Origin Type', value: 'orig_type', align: 'right', description: 'The type of enterprise at which this trip began.' },
                    /*{ text: 'Origin State', value: 'orig_state', align: 'right', description: 'The state of the enterprise at which this trip began.' },*/
                    { text: 'Dest Id', value: 'dest_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip ended.' },
                    /*{ text: 'Dest PIC', value: 'dest_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip ended.' },*/
                    { text: 'Dest Type', value: 'dest_type', align: 'right', description: 'The type of the enterprise at which this trip ended.' },
                    /*{ text: 'Dest State', value: 'dest_state', align: 'right', description: 'The state of the enterprise at which this trip ended.' },*/
                    { text: 'Mode', value: 'mode', align: 'right', description: 'The mode of transportation. Either Roal, Rail or Sea' },
                    { text: 'Distance (km)', value: 'total_km', align: 'right', description: 'The total distance travelled for this trip.' },
                    { text: 'Duration (hrs)', value: 'total_hour', align: 'right', description: 'The total travel time in which the trip took to complete.' },
                    { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with this trip.' },
                ],
                rail: [
                    {
                        text: 'Trip Id',
                        align: 'left',
                        sortable: false,
                        value: 'trip_id',
                        description: 'The random trip identifier generated from the TraNSIT model to represent this trip.'
                    },
                    //{ text: 'Commodity Id', align: 'left', sortable: 'true', value: 'commod_id' },
                    { text: 'Commodity', value: 'commodity', align: 'left', description: 'The commodity which was transported on this trip.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"' },
                    { text: 'Annual Wagons', value: 'trailer_loads', align: 'right', description: 'The total annual wagons which were used in transport for this trip.' },
                    { text: 'Annual Tonnes', value: 'tonnes', align: 'right', description: 'The total annual tonnes which were transported on this trip.' },
                    { text: 'Tonnes/Wagon', value: 'tonnes_per_trailer', align: 'right', description: 'The total annual tonnes per wagon which were transported on this trip.' },
                    { text: 'Origin Id', value: 'orig_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip began.' },
                    /*{ text: 'Origin PIC', value: 'orig_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip began.' },*/
                    { text: 'Origin Type', value: 'orig_type', align: 'right', description: 'The type of enterprise at which this trip began.' },
                    /*{ text: 'Origin State', value: 'orig_state', align: 'right', description: 'The state of the enterprise at which this trip began.' },*/
                    { text: 'Dest Id', value: 'dest_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip ended.' },
                    /*{ text: 'Dest PIC', value: 'dest_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip ended.' },*/
                    { text: 'Dest Type', value: 'dest_type', align: 'right', description: 'The type of the enterprise at which this trip ended.' },
                    /*{ text: 'Dest State', value: 'dest_state', align: 'right', description: 'The state of the enterprise at which this trip ended.' },*/
                    { text: 'Mode', value: 'mode', align: 'right', description: 'The mode of transportation. Either Roal, Rail or Sea' },
                    { text: 'Distance (km)', value: 'total_km', align: 'right', description: 'The total distance travelled for this trip.' },
                    { text: 'Duration (hrs)', value: 'total_hour', align: 'right', description: 'The total travel time in which the trip took to complete.' },
                    { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with this trip.' },
                ],
                sea: [
                    {
                        text: 'Trip Id',
                        align: 'left',
                        sortable: false,
                        value: 'trip_id',
                        description: 'The random trip identifier generated from the TraNSIT model to represent this trip.'
                    },
                    //{ text: 'Commodity Id', align: 'left', sortable: 'true', value: 'commod_id' },
                    { text: 'Commodity', value: 'commodity', align: 'left', description: 'The commodity which was transported on this trip.' },
                    { text: 'Annual Boats', value: 'trailer_loads', align: 'right', description: 'The total annual boats which were used in transport for this trip.' },
                    { text: 'Annual Tonnes', value: 'tonnes', align: 'right', description: 'The total annual tonnes which were transported on this trip.' },
                    { text: 'Tonnes/Boat', value: 'tonnes_per_trailer', align: 'right', description: 'The total annual tonnes per boat which were transported on this trip.' },
                    { text: 'Origin Id', value: 'orig_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip began.' },
                    /*{ text: 'Origin PIC', value: 'orig_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip began.' },*/
                    { text: 'Origin Type', value: 'orig_type', align: 'right', description: 'The type of enterprise at which this trip began.' },
                    /*{ text: 'Origin State', value: 'orig_state', align: 'right', description: 'The state of the enterprise at which this trip began.' },*/
                    { text: 'Dest Id', value: 'dest_id', align: 'right', description: 'The TraNSIT identifier for the enterprise at which this trip ended.' },
                    /*{ text: 'Dest PIC', value: 'dest_pic', align: 'right', description: 'The Property Identification Code for the enterprise at which this trip ended.' },*/
                    { text: 'Dest Type', value: 'dest_type', align: 'right', description: 'The type of the enterprise at which this trip ended.' },
                    /*{ text: 'Dest State', value: 'dest_state', align: 'right', description: 'The state of the enterprise at which this trip ended.' },*/
                    { text: 'Mode', value: 'mode', align: 'right', description: 'The mode of transportation. Either Roal, Rail or Sea' },
                    { text: 'Distance (km)', value: 'total_km', align: 'right', description: 'The total distance travelled for this trip.' },
                    { text: 'Duration (hrs)', value: 'total_hour', align: 'right', description: 'The total travel time in which the trip took to complete.' },
                    { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', description: 'The total costs associated with this trip.' },
                ],
            }
        },
    }

    @Watch('densityType')
    onDensityTypeChanged(val: DensityType, oldVal: DensityType) {
        this.clearTableData() // Clear the table data so NaN's don't appear in columns when switching between say road/rail
        this.dirtyTables = true;
    }
}

