





























import { isNullOrUndefined } from 'util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { bus } from '@/pages/transitweb/main'

enum MimeType {
    mpeg = "audio/mpeg",
    aac = "audio/aac",
    flac = "audio/flac",
    opus = "audio/opus",
    wav = "audio/wav",
    pcm = "audio/pcm",
}

@Component({
    components: {
    }
})
export default class Speech extends Vue {

    @Action('assistant/speek') speek: any;

    @Prop() private content!: string;
    @Prop() private show_controls!: false;
    @Prop() private disabled!: false;


    audio_url: string = '';
    loading = false;
    playing = false;
    blocked = false;
    played = false;
    mediaSource = new MediaSource();
    mime = MimeType.mpeg;


    created() {
        bus.$on('stop_audio', (e: any) => {
            // Don't stop playing if it's this instance trying to be played.
            if (e != this.$refs.audio) {
                this.stopAudio();
            }
        })

        // Check for a compatible mime type.
        if (MediaSource.isTypeSupported(MimeType.mpeg)) {
            this.mime = MimeType.mpeg;
        } else if (MediaSource.isTypeSupported(MimeType.aac)) {
            this.mime = MimeType.aac;
        } else if (MediaSource.isTypeSupported(MimeType.flac)) {
            this.mime = MimeType.flac;
        } else if (MediaSource.isTypeSupported(MimeType.opus)) {
            this.mime = MimeType.opus;
        } else if (MediaSource.isTypeSupported(MimeType.wav)) {
            this.mime = MimeType.wav;
        } else if (MediaSource.isTypeSupported(MimeType.pcm)) {
            this.mime = MimeType.pcm;
        }
    }


    openSource() {

        console.log("Streaming audio...")
        var sourceBuffer = this.mediaSource.addSourceBuffer(this.mime);
        var vm = this;

        // Make request to endpoint to run TTS.
        this.speek({ body: { content: this.content }, accept: this.mime }).then((response: any) => {

            //console.log("Audio Response", response);

            var queue: any[] = [];
            var buffer_has_data = false; // Flag to indicate if it is the first chunk.
            var complete = false;

            // Override the queue push function to see if the data can be perhaps added to the buffer?
            queue.push = function (buffer) {
                if (!sourceBuffer.updating && queue.length == 0) {
                    sourceBuffer.appendBuffer(buffer)
                    return queue.length;
                } else {
                    return Array.prototype.push.call(this, buffer)
                }
            }

            // Checks weather the last chunk has been processed and the stream length can be finalised.
            function checkEndOfStream() {
                if (!queue.length && !sourceBuffer.updating && complete) {
                    console.log("End of stream!");
                    vm.mediaSource.endOfStream();
                }
            }

            // Fired after SourceBuffer.appendBuffer() or SourceBuffer.remove() ends. This event is fired after update.
            sourceBuffer.addEventListener('updateend', function () {
                //console.log("Update End...")
                if (queue.length > 0 && !sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(queue.shift());
                }
                checkEndOfStream();
            }, false);

            // For each chuck in the stream.
            var reader = response.body.getReader()
            reader.read().then(function pump({ value, done }: any) {

                // If all chunks have been received.
                if (done) {
                    complete = true;
                    checkEndOfStream();
                    return; // Just exit
                }

                if (!buffer_has_data) {
                    // Append chunk to the buffer.
                    console.log("Added first chunk");
                    queue.push(value)
                    console.log("First chunk received, starting audio playback...");
                    vm.playAudio();
                    buffer_has_data = true;

                } else {
                    //console.log("Added to buffer queue");
                    queue.push(value);
                }

                // Continue reading chunks.
                return reader.read().then(pump);
            })
        }, (error: any) => {
            console.log(error);
        }).finally(function () { vm.loading = false; });
    }


    playAudio() {
        Vue.nextTick(() => {
            console.log("Playing");
            this.played = true;
            let playPromise = (this.$refs.audio as any).play(); // This seems to throw this error in iPad: Unhandled Promise Rejection: NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.

            if (!isNullOrUndefined(playPromise)) {
                playPromise.then(() => {
                    bus.$emit('stop_audio', this.$refs.audio); // Stop playing other audio messages.
                    this.playing = true;
                    this.blocked = false;
                }).catch((error: any) => {
                    this.blocked = true;
                    console.log('Unable to autoplay media.', error);
                })
            }
        })
    }


    onEnded() {
        this.stopAudio();
    }


    stopAudio() {
        let audio = (this.$refs.audio as any);
        if (audio) {
            console.log("Stopping audio!")
            audio.pause();
            audio.currentTime = 0;
            this.playing = false;
        }
    }


    onStopSpeech() {
        this.stopAudio();
    }


    onPlaySpeech() {

        if (this.audio_url != '') {
            console.log("Speech is already initialised...")
            this.playAudio();
        } else {
            this.audio_url = URL.createObjectURL(this.mediaSource); // This will trigger the event to open the audio source.
            this.mediaSource.addEventListener('sourceopen', this.openSource);
            this.loading = true;
        }
    }
}

