
































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'

import { DatasetState, DensityType} from '@/store/datasets/types';

import { endpoints } from '../../endpoints'
import buildUrl from 'build-url';


@Component({
    components: {
    }
})
export default class Main extends Vue {

    @State('datasets') datasets!: DatasetState;


    get dataset(): any {
        return this.datasets.dataset;
    }

    get densityType(): DensityType {
        return this.datasets.type;
    }

    show_splash: boolean = true;
    toolbar_toggle: number = 0;
    sector: string = 'All Sectors';
    hover: boolean = false;

    toolbar_btns: any = [
        {
            key: "diversity_of_suppliers",
            text: "Diversity of Suppliers",
            description: "Reducing dependency on a single supplier or geography is vital. Sourcing materials and products from a diverse range of suppliers across different regions can buffer the impact of regional disruptions.",
            click: function(e: any){{ console.log("Clicked on Diversity of Suppliers")}},
            reporting_elements: [
                "Overseas import & export volumes",
                "Ratio of overseas import to domestic supply",
                "Availability to end-user (e.g. volume, kcal, protein, etc.)",
                "Percentage diversification change",
            ]
        },
        {
            key: "inventory_management",
            text: "Inventory Management",
            description: "Efficient inventory practices, such as safety stock levels or buffer inventories, contribute to a resilient supply chain.",
            click: function(e: any){{ console.log("Clicked on Inventory Management")}},
            reporting_elements: [
                "Inventory Levels",
                "Shelf life",
                "Storage capacity, % businesses reaching critical thresholds",
            ]
        },
        {
            key: "geographic_strength",
            text: "Geographic Strength",
            description: "The capacity of a supply chain to withstand and quickly recover from geographic and environmental disruptions. These disruptions can include natural disasters, political unrest, pandemics or even local economic fluctuations that might affect parts of the supply chain network.",
            click: function(e: any){{ console.log("Clicked on Geographic Resilience")}},
            reporting_elements: [
                "LGA Reporting... or perhaps some of the following items(?)",
                "Travel duration per mode (total, per payload)",
                "Travel distance per mode (total, per payload)",
                "Change in fatigue hours (rest time)",
                "Transport cost metrics (total, per km, payload tonne, cost, value ratio)",
            ]
        },
        {
            key: "time_to_recovery",
            text: "Time to Recovery",
            description: "This measures the time it takes for a supply chain to return to its normal function after a disruption. Shorter recovery times indicate more resilience.",
            click: function(e: any){{ console.log("Clicked on Time to Recovery")}},
            reporting_elements: [
                "Import & export volumes from other domestic regions",
                "Freight volume per mode",
                "Production reduction due to missing input",
                "Knock-on effect on product quality",
            ]
        },
        {
            key: "flexibility_and_adaptability",
            text: "Flexibility & Adaptability",
            description: "The ability of a supply chain to change its operations in response to external pressures or shocks. This could involve alternative logistic options, modular product designs, or adjustable sourcing strategies.",
            click: function(e: any){{ console.log("Clicked on Flexibility and Adaptibility")}},
            reporting_elements: [
                "Product substitution volumes (substituted by, used for substitution)",
                "Ability to shift transport mode (Percentage of commodity shifted to different mode vs. blocked)",
                "Ability to transport commodity to alternative market (volume redirected)",
            ]
        },
        {
            key: "suistainability_performance",
            text: "Suistainability Performance",
            description: "Measures the environmental impact of supply chain operations, aiming to minimize carbon footprint, waste, and resource consumption. These can be delivered by aiming for Net Zero emissions and developing circular economies that are robust to resource scarcities.",
            click: function(e: any){{ console.log("Clicked on Suistainability Performance")}},
            reporting_elements: [
                "CO2 emissions from transportation (per payload)",
                "Product expired before reaching end-user",
            ]
        }




    ]

    attrs: any = {
        class: 'ma-6',
        boilerplate: true,
        elevation: 2,
        //height:"95%"
    }

    items: any = [];

    created(){{
        this.getSectorItems(this.dataset, this.densityType);
    }}

    getSectorItems(dataset: string, densityType: string) {
        Vue.axios({
            url: buildUrl(endpoints.commod_l3Url(dataset, densityType))
        }).then((response: any) => {
            this.items = response.data
        }, (error: any) => {
            console.log(error);
        });
    }

    setSector(e: any){
        this.sector = e.currentTarget.textContent;
    }

    loading = {
        landing: false
    }

    mounted() {
        console.log("Resilience")
    }


  }

