import { MutationTree } from 'vuex';
import { MapState, lngLat, ILayerVisibility } from './types';
import Vue from 'vue';

export const mutations: MutationTree<MapState> = {
    setLayerStyle(state, layerStyle: string) {
        state.layerStyle = layerStyle;
    },
    setMarker(state, marker: lngLat) {
        Vue.set(state, 'marker', marker);
    },
    setFlyTo(state, location: number[]) {
        Vue.set(state, 'flyTo', location);
    },
    setFeatureOfInterest(state, feature: any) {
        state.featureOfInterest = feature;
    },
    setLayerVisibility(state, payload: ILayerVisibility) {
        if (payload.value) {
            state.hiddenLayers = state.hiddenLayers.filter((layerId: string) => layerId !== payload.layer);
        } else {
            var copy = [...state.hiddenLayers];
            copy.push(payload.layer);
            state.hiddenLayers = copy;
        }
    },
};
