import { GetterTree } from 'vuex';
import { FiltersState } from './types';
import { RootState } from '../types';
import { isNullOrUndefined } from 'util';
import { DensityType } from "@/store/datasets/types";

export const getters: GetterTree<FiltersState, RootState> = {

    filterCount(state): number {
        var count = 0;
        for (var filter in state.filter_types) {
            var filterObj = state.filter_types[filter].value;
            if ((filterObj != null) && (filterObj.length > 0)) {
                count++;
            }
        }
        return count;
    },

    filtersWithValues(state, getters): string[] {
        var filters: string[] = [];
        for (var filter in state.filter_types) {
            var filterHasValue = (!isNullOrUndefined(state.filter_types[filter].value)) && (state.filter_types[filter].value.length > 0);
            if (filterHasValue) {
                filters.push(filter);
            }
        }
        return filters;
    },

    isFiltered(state, getters): boolean {
        return (getters.filterCount > 0);
    },
    isMonthlyFilter(state, getters): boolean {
        return !isNullOrUndefined(state.queryParams.month);
    },
    isDirty(state): boolean {
        return state.isDirty;
    },
    disabled(state): boolean {
        return state.disabled;
    },
    visible(state): boolean {
        return state.visible;
    },
    isSpatialFilter(state): any {
        return (filter: string) => { return !isNullOrUndefined(state.filter_types[filter].tileSource); }
    },

    filterWorksWith(state, getters): any {
        return (filterId: string, densityType: DensityType) => {
            return isNullOrUndefined(state.filter_types[filterId].worksWith) ||
                (state.filter_types[filterId] as any).worksWith.includes(densityType);
        }
    },
    filterHasValue(state): any { // Actually returns an anonymous function which can then accept an argument (filterId).
        return (filterId: string) => {
            var filter = state.filter_types[filterId].value;
            return !isNullOrUndefined(filter) && filter.length > 0;
        };
    },
    filterUrl(state, getters, rootstate): any { // Actually returns an anonymous function which can then accept an argument (filterId).
        return (filterId: string) => {
            var dataset = (rootstate as any)['datasets'].dataset;
            var densityType = (rootstate as any)['datasets'].type;
            var urlTemplate = state.filter_types[filterId].item_list_url;
            return urlTemplate.replace('{dataset}', dataset).replace('{density_type}', densityType.toLowerCase());
        };
    },
    // Determines if the values for all filters are not put there by a constraint we have enforced (i.e genuine filter values the user placed themselves)
    hasUnconstrainedFilters(state, getters, rootstate, rootgetters): boolean {
        for (var filterId of getters.filtersWithValues) {
            for (var val of state.filter_types[filterId].value) {
                var conVal = rootgetters['auth/constrainedValues'](filterId);
                if (isNullOrUndefined(conVal) || !(conVal.includes(val) && (conVal.length === 1 && !getters.isSpatialFilter(filterId)))) {
                    if (getters.filterWorksWith(filterId, (rootstate as any)['datasets'].type)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

};
