import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { DatasetState, DensityType } from './types';
import { RootState } from '../types';

export const state: DatasetState = {
    datasetList: [],
    dataset: undefined,
    type: DensityType.Road,
    nav: DensityType.Road
};

const namespaced: boolean = true;

export const datasets: Module<DatasetState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
