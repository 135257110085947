

import { Component, Prop, Vue } from 'vue-property-decorator';
import parsePhoneNumber from 'libphonenumber-js';

@Component
export default class Validation extends Vue {

    valid: boolean = false;

    password: any = {};

    confirmPasswordsEqual() {
        return ((this.password.password === this.password.password_confirm) || (!this.password.password_confirm || this.password.password_confirm.length === 0)) || "Both passwords must match";
    }

    rules: any = {
        login_username: [
            function (v: any) { return !!v || "Username or e-mail is required" },
        ],
        login_password: [
            function (v: any) { return !!v || "Password is required" },
        ],
        first_name: [
            function (v: any) { return !!v || "First Name is required" },
        ],
        last_name: [
            function (v: any) { return !!v || "Last Name is required" },
        ],
        organisation: [
            function (v: any) { return !!v || "Organisation is required" },
        ],
        job_title: [
            function (v: any) { return !!v || "Job title is required" },
        ],
        phone_number: [
            function (v: any) { return !!v || "Mobile is required" },
            function (v: any) { return parsePhoneNumber(v, 'AU')?.isValid() || "Invalid mobile number" },
        ],
        email: [
            function (v: any) { return !!v || "E-Mail is required" },
            function (v: any) { return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Invalid E-Mail Address" },
        ],
        password: [
            function (v: any) { return !!v || "Password is required" },
            function (v: any) { return /[a-z]+/.test(v) || "The password must contain at least 1 lowercase character" },
            function (v: any) { return /[A-Z]+/.test(v) || "The password must contain at least 1 uppercase character" },
            function (v: any) { return /\d/.test(v) || "The password must contain at least 1 numeric character" },
            function (v: any) { return /[ !\\"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~]+/.test(v) || "The password must contain at least 1 special character" }, // Taken from django-advanced-password-validator.
            function (v: any) { return (v && v.length >= 12) || "The password must be at least 12 characters long" },
        ],
        licence: [
            function (v: any) { return !!v || "You need a software licence to complete registration" }
        ]
    }

}
