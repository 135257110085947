











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// import { Component, Prop, Vue } from 'vue-property-decorator';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';

import buildUrl from 'build-url';

import VuePlotly from '@statnett/vue-plotly'
//import { Plotly } from 'vue-plotly'

import Links from './Links.vue';
import { isNullOrUndefined } from 'util';


@Component({
    components: {
        VuePlotly,
        //Plotly,
    },
    methods: {

    }
})
export default class BenchmarkPanel extends Links {

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isAdmin') isAdmin?: boolean;

    @Prop() private showTitle!: boolean;

    loaded = false
    show = false

    links = [
        { title: 'User Guide', click: (this as any).user_guide },
        { title: 'FAQ', click: (this as any).faq },
        { title: 'Commodity Metadata', click: (this as any).metadata },
        { title: 'About TraNSIT', click: (this as any).about },
        { title: 'Contact Us', click: (this as any).provideFeedback },
    ]


    //// this is temporary fix to prevent footer tool box style from loading when not admin
    //get ScrollableDiv() {
    //    if (!this.isAdmin) {
    //        return true
    //    } else {
    //        return false
    //    }
    //}

    //// this is temporary fix to prevent footer tool box style from loading when not admin
    //get ScrollableDiv2() {
    //    if (this.isAdmin || this.isAllowedSensitiveInfo) {
    //        return true
    //    } else {
    //        return false
    //    }
    //}

    //temporalComparison_alertCounter: number = 0;
    //counter() {
    //    this.temporalComparison_alertCounter += 1;
    //};

    mounted() {
        this.loadTable(this.tables.commodities_all, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadCommodityTables()
        //this.delay_show()
    }

    loadCommodityTables() {
        this.loadTable(this.tables.supplychain, this.supplychainReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadSupplyChainTables();
        //this.temporalTripsDefined
    }

    loadSupplyChainTables() {
        this.loadTable(this.tables.pathcost, this.pathcostReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.triptype, this.triptypeReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.tripdistribution, this.tripDistributionReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.transportlogistics, this.transportlogisticsReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        this.loadTable(this.tables.commodities, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.commodities_average, this.commodityReportUrl, { 'groupBy': false });
        this.loadTable(this.tables.backload, this.backloadReportUrl, { 'bar_chart_type': 'backload', 'groupBy_l2': this.sensitive_spatial_data_check });

        //temporal comparison tables
        //this.loadTable(this.tables.commoditiestemporal, this.commoditytemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.pathcosttemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.triptypetemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.tripdistributiontemporal, this.tripDistributiontemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.transportlogisticstemporal, this.transportlogisticstemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });

        ////international comparison tables
        //if (this.isAdmin || this.isAllowedSensitiveInfo) {
        //    this.loadTable(this.tables.pathcostindonesia, this.pathcostindonesiaReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
        //    this.loadTable(this.tables.triptypeindonesia, this.pathcostindonesiaReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
        //    this.loadTable(this.tables.tripdistributionindonesia, this.tripdistributionindonesiaReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //    this.loadTable(this.tables.transportlogisticsindonesia, this.transportlogisticsindonesiaReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //}

    }

    get loadingTables() {
        return (this.tables.commodities.loading)
    }


    //-----------------------------
    //-------    Filters     ------
    //-----------------------------

    filterGroupBy = 'root'
    filterGroup = 'All'
    //filterSecondaryGroupBy = ''
    //filterSecondaryGroup = ''
    filterSunburstId = ''
    filterCurrentPath = 'All/'

    commod_sensitive: boolean = false; // alert for sankey diagram
    commod_sensitive_b: boolean = false; //alert for supply chain bar chart, needs to be separate as it needs to be reset when sankeyOnClick
    commod_sensitive_inbound: boolean = false; //alert for density map - inbound/outbound
    commod_sensitive_outbound: boolean = false
    commod_sensitive_tp: boolean = false; //alert for trip type chart

    mode: string = ''
    value_type: string = 'total'
    spatial_type: string = 'state'
    bar_chart_type: string = ''
    temporalTripsDefined: any = null
    temporalComparison_alert: boolean = false
    data_alert: boolean = true
    map_departures: boolean = false
    map_arrivals: boolean = false
    groupBy_l2: boolean = false
    sensitive_spatial_data: boolean = false
    show_legend_detail: boolean = false //when clicked on ! on bubble chart, legend details are shown
    internationalTripsDefined: boolean = false

    baseline_items_all = [
        //{ text: 'December 2020', value: 'dec2020', current_baseline: false },
        //{ text: 'August 2022', value: 'aug2022', current_baseline: false },
        //{ text: 'August 2023', value: 'aug2023', current_baseline: true },
        { text: 'April 2024', value: 'apr2024', current_baseline: true },
    ]//perhaps need to bring this from db, though this will result in an additional url request fired up every time SCBD tab is selected

    country_items: any = ['Indonesia']
    baseline_item_selected: any = null
    country_item_selected: any = null

    //survey: boolean = false

    //delay_show() {
    //    setTimeout(() => this.survey = true, 4000);
    //}

    //complete_survey() {
    //    (window as any).open('https://www.surveymonkey.com/r/freightandsupplychaininsights');
    //}

    showScroll: boolean = false
    onScroll(e: any) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.showScroll = top > 200
    }


    @Prop() private modeFilter!: any;
    //@Prop() private temporalTrips!: boolean;

    trackMetric(metricItem: string, label: string) {
        (Vue as any).gtm.trackEvent({
            event: 'gui',
            category: 'benchmark',
            action: 'Metric Changed (' + metricItem + ')',
            label: label,
            //value: payload.value,
            noninteraction: true,
        });
        console.log('Metric Changed (' + metricItem + ')', label)
    }
    @Watch('tables.supplychain.metric_value', { deep: false })
    supplychainMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Supply Chains', val)
    }
    @Watch('tables.transportlogistics.metric_value', { deep: false })
    transportlogisticsMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Transport Logistics', val)
    }
    @Watch('tables.pathcost.metric_value', { deep: false })
    pathcostMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Path Cost', val)
    }
    @Watch('tables.tripdistribution.metric_value', { deep: false })
    tripdistributionMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Trip Distribution', val)
    }
    @Watch('tables.densitymap.metric_value', { deep: false })
    densitymapMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Density Map', val)
    }
    @Watch('tables.commodities_all.metric_value', { deep: false })
    commodities_allMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Commodities', val)
    }
    @Watch('tables.triptype.metric_value', { deep: false })
    triptypeMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Trip Type', val)
    }
    @Watch('tables.backload.metric_value', { deep: false })
    backloadMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Backload', val)
    }

    @Watch('baseline_item_selected', { deep: false })
    baselineMetricOnChange(val: any, oldVal: any) {
        this.trackMetric('Baseline', val)
    }

    @Watch('modeFilter', { deep: false })
    modeOnChange(val: any, oldVal: any) {
        this.mode = val;
        this.resetCommodityFilter()
        this.resetSupplyChainFilter()
        this.map_arrivals = false;
        this.map_departures = false;

        this.loadTable(this.tables.commodities_all, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadCommodityTables();
    }

    //@Watch('temporalTrips', { deep: false })
    //temporalTripsOnChange(val: any, oldVal: any) {
    //    //console.log(val)
    //    this.internationalTripsDefined = false
    //    this.country_item_selected = null

    //    this.temporalTripsDefined = val
    //    if (isNullOrUndefined(val)) {
    //        this.temporalComparison_alert = false
    //    } else if (this.temporalComparison_alertCounter === 0) {
    //        this.temporalComparison_alert = true
    //        this.counter()
    //    }

    //}

    //@Watch('inrternationalTrips', { deep: false })
    //internationalTripsOnChange(val: any, oldVal: any) {
    //    //console.log(val)
    //    this.temporalTripsDefined = null
    //    this.baseline_item_selected = null

    //    if (val == 'Indonesia' && (this.isAdmin || this.isAllowedSensitiveInfo)) {
    //        this.internationalTripsDefined = true
    //    } else { this.internationalTripsDefined = false }

    //}

    //Set title for the average trip duration score card according to transport mode
    get avgTripDurationTitle() {
        if (this.mode[0] === 'road') {
            return "Average driver time excluding logistics and fatigue management"
        } else if (this.mode[0] === 'rail') {
            return "Average crew time excluding logistics and fatigue management"
        } else if (this.mode === '') {
            return "Average driver/crew time excluding logistics and fatigue management"
        }
    }

    ////Set title for the annual trailes/wagons Power Figures Subplot chart according to transport mode
    //get mainUnitType() {
    //    if (this.mode[0] === 'road') {
    //        return "Annual Trailers"
    //    } else if (this.mode[0] === 'rail') {
    //        return "Annual Wagons"
    //    } else if (this.mode === '') {
    //        return "Annual Trailers/Wagons"
    //    }
    //}

    valueTypeOnChange(val: any, oldVal: any) {
        this.value_type = val;
        this.loadTable(this.tables.transportlogistics, this.transportlogisticsReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.transportlogisticstemporal, this.transportlogisticstemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });

        //if (this.isAdmin || this.isAllowedSensitiveInfo) {
        //    this.loadTable(this.tables.transportlogisticsindonesia, this.transportlogisticsindonesiaReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //}

    }

    spatialFilterUpdated() {
        this.loadTable(this.tables.commodities_all, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.supplychain, this.supplychainReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.pathcost, this.pathcostReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.triptype, this.triptypeReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.tripdistribution, this.tripDistributionReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.transportlogistics, this.transportlogisticsReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        //this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        this.loadTable(this.tables.commodities, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        this.loadTable(this.tables.commodities_average, this.commodityReportUrl, { 'groupBy': false });
        this.loadTable(this.tables.backload, this.backloadReportUrl, { 'bar_chart_type': 'backload', 'groupBy_l2': this.sensitive_spatial_data_check });

        ////temporal comparison tables
        //this.loadTable(this.tables.commoditiestemporal, this.commoditytemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.pathcosttemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.triptypetemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.tripdistributiontemporal, this.tripDistributiontemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.transportlogisticstemporal, this.transportlogisticstemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });

    }

    spatialTypeOnChange(val: any, oldVal: any) {
        this.spatial_type = val;
        var doUpdate = this.map_selected_orig.text != undefined || this.map_selected_dest.text != undefined
        this.map_selected_orig = {};
        this.map_selected_dest = {};
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        this.$emit('spatial_filter_to', this.map_selected_dest);
        this.$emit('spatial_filter_from', this.map_selected_orig);
        if (doUpdate) {
            this.map_arrivals = false;
            this.map_departures = false;
            this.spatialFilterUpdated();
        }
    }

    localTripsOnChange(val: any, oldVal: any) {
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        this.spatialFilterUpdated();

    }

    get isRoot() {
        return this.filterGroupBy == 'root' || this.filterGroupBy == undefined
    }

    applyFilters(data: any) {

        //if (this.supplyChainFilter) {
        //    data = this.filter(data, 'orig_type', this.orig_enterprise_type)
        //    data = this.filter(data, 'dest_type', this.dest_enterprise_type)
        //}
        if (this.commodityFilter) {
            data = this.filter(data, this.filterGroupBy, this.filterGroup)
        }
        return data
    }

    get commodityFilter() {
        if (!this.isRoot) {
            return this.filterGroupBy[0].toUpperCase() + this.filterGroupBy.substring(1) + ': ' + this.filterGroup
        }
        else {
            return undefined
        }
    }

    currentBaseline(value: any) {
        var objectArray = this.baseline_items_all.filter(obj => {
            return obj.current_baseline === value
        });
        var baseline_text = objectArray.map(a => a.text);
        return baseline_text;
    }

    get selectedBaselineValue() {
        var objectArray = this.baseline_items_all.filter(obj => {
            return obj.text === this.baseline_item_selected
        });
        var baseline_value = objectArray.map(a => a.value).toString();

        return baseline_value;
    }

    get baselineItemsToCompare() {
        var objectArray = this.baseline_items_all.filter(obj => {
            return obj.current_baseline === false
        });
        var baseline_value = objectArray.map(a => a.value);

        return baseline_value;
    }


    //alert function
    sensitive_commod_alert_check(data: any, type: any) {
        if (data.length > 0) {
            if (data[0]['alert'] > 0) {
                if (type === 'triptype') {
                    this.commod_sensitive_tp = true
                } else if (type === 'supplychain') {
                    this.commod_sensitive = true
                } else if (type === 'pathcost') {
                    this.commod_sensitive_b = true
                } else if (type === 'dansitymap') {
                    this.commod_sensitive_outbound = true
                } else if (type === 'dansitymaparrivals') {
                    this.commod_sensitive_inbound = true
                }
            } else {
                if (type === 'triptype') {
                    this.commod_sensitive_tp = false
                } else if (type === 'supplychain') {
                    this.commod_sensitive = false
                } else if (type === 'pathcost') {
                    this.commod_sensitive_b = false
                } else if (type === 'dansitymap') {
                    this.commod_sensitive_outbound = false
                } else if (type === 'dansitymaparrivals') {
                    this.commod_sensitive_inbound = false
                }
            }
        }
    }

    //this will evaluate if sensitive_spatial_data true or false based on selected map filter or user privileges
    get sensitive_spatial_data_check() {
        if (!this.map_arrivals && !this.map_departures) {
            return this.sensitive_spatial_data = false
        } else if (this.isAllowedSensitiveInfo || this.isAdmin) {
            return this.sensitive_spatial_data = false
        } else {
            return this.sensitive_spatial_data = true
        }
    }

    //this will return restricted metric items for sankey diagram when sensitive_spatial_data true,
    //this is related to data sensitivity to hide statistics when standard users access data by geographical comparison
    get metric_items_restricted_check() {
        if (this.sensitive_spatial_data === true) {
            return this.metric_items_sankey_restricted
        } else {
            return this.metric_items
        }
    }

    //// this will disable international comparison drop down menu when mode is rail or if not admin/sensitiveInfo
    //get internationalTripsDisabled() {
    //    if (this.isAdmin || this.isAllowedSensitiveInfo) {
    //        if (this.mode == 'rail') {
    //            return true
    //        } else {
    //            return false
    //        }
    //    } else {
    //        return true
    //    }
    //}

    // Not in use yet... need to figure out how to set level 0 on sunburst
    resetCommodityFilter() {
        this.filterGroupBy = 'root'
        this.filterGroup = 'All'
        //this.filterSecondaryGroupBy = ''
        //this.filterSecondaryGroup = ''
        this.$emit('commodity_filter', this.commodityFilter);
        this.map_selected_orig = {}
        this.map_selected_dest = {}
        this.$emit('spatial_filter_from', this.map_selected_orig);
        this.$emit('spatial_filter_to', this.map_selected_dest);
    }

    //reset NavBar filters when clicked on map first time
    resetNavBarFiltersMapOnClick() {
        this.filterGroupBy = 'root'
        this.filterGroup = 'All'

        this.$emit('commodity_filter', this.commodityFilter);
        this.resetSupplyChainFilter()
    }

    clearCommodityFilter() {
        //this.map_departures = false;
        //this.map_arrivals = false;
        this.resetCommodityFilter();
        this.loadCommodityTables();
        this.loadTable(this.tables.commodities_all, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
    }

    orig_enterprise_type: string = ''
    dest_enterprise_type: string = ''
    enterprise_type_operation: string = ''


    get supplyChainFilter() {
        if ((this.orig_enterprise_type === '') && (this.dest_enterprise_type === '')) {
            return undefined
        } else {
            //return this.orig_enterprise_type + ' - ' + this.dest_enterprise_type
            return {
                orig_enterprise_type: this.orig_enterprise_type,
                dest_enterprise_type: this.dest_enterprise_type,
                enterprise_type_operation: this.enterprise_type_operation
            }
        }
    }

    resetSupplyChainFilter() {
        this.orig_enterprise_type = ''
        this.dest_enterprise_type = ''
        this.enterprise_type_operation = ''
        this.$emit('supplychain_filter', this.supplyChainFilter);
    }

    resetDestEnterpriseFilter() {
        this.dest_enterprise_type = ''
        this.enterprise_type_operation = '';
        this.$emit('supplychain_filter', this.supplyChainFilter);
    }

    resetOrigEnterpriseFilter() {
        this.orig_enterprise_type = ''
        this.enterprise_type_operation = '';
        this.$emit('supplychain_filter', this.supplyChainFilter);
    }

    clearSupplyChainFilter() {
        this.resetSupplyChainFilter()
        this.loadSupplyChainTables()
    }

    clearDestEnterpriseFilter() {
        this.resetDestEnterpriseFilter()
        this.loadSupplyChainTables()
    }

    clearOrigEnterpriseFilter() {
        this.resetOrigEnterpriseFilter()
        this.loadSupplyChainTables()
    }


    //-----------------------------
    //-----  Metric Selection  ----
    //-----------------------------

    metric_items =
        [
            {
                text: 'Freight task costs',
                value: 'trip_transport_costs',
                valueformat: "$,.3s",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'trip_transport_costs',
                customDisabled: false
            },
            {
                text: 'Freight task value',
                value: 'total_freight_value',
                valueformat: "$,s",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'trip_freight_value',
                customDisabled: false
            },
            {
                text: 'Annual tonnes',
                value: 'tonnes',
                valueformat: ",.4s",
                valuesuffix: " t",
                units: '(tonne)',
                tickprefixformat: "",
                metric: 'tonnes',
                customDisabled: false
            },
            //{
            //    text: 'Annual trailers',
            //    value: 'trailer_loads',
            //    valueformat: ",.3s",
            //    valuesuffix: "",
            //    units: '',
            //    metric: 'trailer_loads'
            //},
            {
                text: 'Cost per payload tonne',
                value: 'cst_per_tonne',
                valueformat: "$,f",
                valuesuffix: "",
                units: '($/tonne)',
                tickprefixformat: "$",
                metric: 'cst_per_tonne',
                customDisabled: false
            },
            {
                text: 'Cost per tonne km',
                value: 'cst_per_tonne_km',
                valueformat: "$,.4f",
                valuesuffix: "",
                units: '($/tonne-km)',
                tickprefixformat: "$",
                metric: 'cst_per_tonne_km',
                customDisabled: false
            },
            {
                text: 'Total travel distance',
                value: 'total_trip_distance',
                valueformat: ",.3s",
                valuesuffix: " km",
                units: '(km)',
                tickprefixformat: "",
                metric: 'total_km',
                customDisabled: false
            },
            {
                text: 'Total travel duration',
                value: 'total_trip_duration',
                valueformat: ",.3s",
                valuesuffix: " hrs",
                units: '(hrs)',
                tickprefixformat: "",
                metric: 'total_hour',
                customDisabled: false
            },
            {
                text: 'Avg trip distance',
                value: 'avg_trip_distance',
                valueformat: ",.3s",
                valuesuffix: " km",
                units: '(km)',
                tickprefixformat: "",
                metric: 'avg_trip_distance',
                customDisabled: true

            },
            {
                text: 'Avg trip duration',
                value: 'avg_trip_duration',
                valueformat: ",.2r",
                valuesuffix: " hrs",
                units: '(hrs)',
                tickprefixformat: "",
                metric: 'avg_trip_duration',
                customDisabled: true

            },
            {
                text: 'CO2 Emissions',
                value: 'co2_tn',
                valueformat: ",.4s",
                valuesuffix: " t",
                units: '(tonnes)',
                tickprefixformat: "",
                metric: 'tonnes',
                customDisabled: false
            },
        ]

    metric_items_sankey_restricted =
        [

            {
                text: 'Cost per payload tonne',
                value: 'cst_per_tonne',
                valueformat: "$,f",
                valuesuffix: "",
                units: '($/tonne)',
                metric: 'cst_per_tonne',
                customDisabled: false
            },
            {
                text: 'Cost per tonne km',
                value: 'cst_per_tonne_km',
                valueformat: "$,.4f",
                valuesuffix: "",
                units: '($/tonne-km)',
                metric: 'cst_per_tonne_km',
                customDisabled: false
            },

        ]

    metric_items_box_plot =
        [
            {
                text: 'Cost per payload tonne',
                value: 'cst_per_tonne',
                valueformat: "$,f",
                valuesuffix: "",
                units: '($/tonne)',
                tickprefixformat: "$",
                metric: 'cst_per_tonne',
                customDisabled: false
            },
            {
                text: 'Cost per tonne km',
                value: 'cst_per_tonne_km',
                valueformat: "$,.4f",
                valuesuffix: "",
                units: '($/tonne-km)',
                tickprefixformat: "$",
                metric: 'cst_per_tonne_km',
                customDisabled: false
            },

        ]


    metric_items_logistics_chart =
        [

            {
                text: 'Transport costs',
                value: 'transport_costs',
                valueformat: "$,.3s",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'transport_costs',
                customDisabled: false
            },
            {
                text: 'Logistics costs',
                value: 'logistics_costs',
                valueformat: "$,.3s",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'logistics_costs',
                customDisabled: false
            },
            {
                text: 'Logistics time',
                value: 'logistics_time',
                valueformat: ",.2r",
                valuesuffix: " hrs",
                units: '(hrs)',
                tickprefixformat: "",
                metric: 'logistics_time',
                customDisabled: false
            },


        ]

    metric_items_logistics_chart_tonne =
        [

            {
                text: 'Transport costs per tonne',
                value: 'transport_costs',
                valueformat: "$,.3s",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'transport_costs',
                customDisabled: false
            },
            {
                text: 'Logistics costs per tonne',
                value: 'logistics_costs',
                valueformat: "$,.2r",
                valuesuffix: "",
                units: '($)',
                tickprefixformat: "$",
                metric: 'logistics_costs',
                customDisabled: false
            },
            {
                text: 'Logistics time',
                value: 'logistics_time',
                valueformat: ",.2r",
                valuesuffix: " hrs",
                units: '(hrs)',
                tickprefixformat: "",
                metric: 'logistics_time',
                customDisabled: false
            },


        ]

    spatialTypeItems =
        {
            'lga': {
                text: 'Local Government Areas',
                value: 'lga',
                geojson: './docs/lga.json'
            },
            //'sa2': {
            //    text: 'Statistical Area Level 2',
            //    value: 'sa2',
            //    geojson: './docs/sa2.json'
            //},
            'sa3': {
                text: 'Statistical Area Level 3',
                value: 'sa3',
                geojson: './docs/sa3.json'
            },
            'sa4': {
                text: 'Statistical Area Level 4',
                value: 'sa4',
                geojson: './docs/sa4.json'
            },
            //'nrm': {
            //    text: 'Natural Resource Management Regions',
            //    value: 'nrm',
            //    geojson: './docs/nrm.json'
            //},
            'state': {
                text: 'States and Territories',
                value: 'state',
                geojson: './docs/states.json'
            },
            'rda': {
                text: 'Regional Development Areas',
                value: 'rda',
                geojson: './docs/rda.json'
            }
        }

    spatialTypeItemsState = {
        'state': {
                text: 'States and Territories',
                value: 'state',
                geojson: './docs/states.json'
            }
    }

    getMetricSettings(metric_value: string) {
        var result = this.metric_items.filter(obj => {
            return obj.value === metric_value
        })

        return result[0];
    }

    getMetricSettingsLogisticsChart(metric_value: string) {

        if (this.value_type === 'average') {
            var result = this.metric_items_logistics_chart_tonne.filter(obj => { return obj.value === metric_value })
        } else {
            var result = this.metric_items_logistics_chart.filter(obj => { return obj.value === metric_value })
        }
        return result[0];
    }

    getMetricSettingsBoxPlot(metric_value: string) {
        var result = this.metric_items_box_plot.filter(obj => {
            return obj.value === metric_value
        })

        return result[0];
    }

    ////this resets the whole dashboard when a dropdown metric is changed on sunburst
    //@Watch('tables.commodities_all.metric_value', { deep: true })
    //onMetricSunburstChanged(val: any, oldVal: any) {
    //    if (!this.isRoot) {
    //        this.clearCommodityFilter()
    //    }
    //}

    @Watch('tables.tripdistribution.metric_value', { deep: true })
    onMetricBoxChanged(val: any, oldVal: any) {
        this.loadTable(this.tables.tripdistribution, this.tripDistributionReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //this.loadTable(this.tables.tripdistributiontemporal, this.tripDistributiontemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });

        //if (this.isAdmin || this.isAllowedSensitiveInfo) {
        //    this.loadTable(this.tables.tripdistributionindonesia, this.tripdistributionindonesiaReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        //}

    }

    @Watch('tables.densitymap.map_direction', { deep: true })
    onDirectionChanged(val: any, oldVal: any) {
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'direction': 'departures' });
        //this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'inverse_region': this.map_selected_item, 'direction': 'arrivals' });
    }

    @Watch('tables.commodities.init', { deep: true })
    onCommoditiesLoaded(val: any, oldVal: any) {
        var vm = this;
        setTimeout(function () { vm.closeAll(); }, 10); // collapse the commidity table groups 10 ms after commidities loaded
    }


    //-----------------------------
    //-------  Power Numbers ------
    //-----------------------------

    time_out: any;
    animated_numbers = [1242345674, 342435357, 64.82, 11534629342, 385723894, 239578, 35674574, 412, 5, 2947563];

    startNumberAnimation() {
        this.time_out = setInterval(this.getNumbers, 100)
    }

    getNumbers() {
        this.animated_numbers = [
            Math.random() * 100000000,
            Math.random() * 100000000,
            Math.random() * 100 + 13,
            Math.random() * 100000000000,
            Math.random() * 100000000000,
            Math.random() * 100000000000,
            Math.random() * 100000000000,
            Math.random() * 1000 + 17,
            Math.random() * 2 + 2,
            Math.random() * 100000000000,
        ];
    }

    stopNumberAnimation() { clearInterval(this.time_out); }

    numberWithCommas(x: any) {
        return Number(x).toLocaleString()
    }

    totals(data: any, attribute: string, precision: number) {
        data = this.applyFilters(data)
        var ret = this.total(data, attribute).toFixed(precision)
        return this.numberWithCommas(ret)
    }

    averages(data: any, attribute: string, precision: number) {
        data = this.applyFilters(data)
        var ret = this.average(data, attribute).toFixed(precision)
        return this.numberWithCommas(ret)
    }

    get annual_trailers() {
        return this.totals(this.tables.commodities.data, 'trailer_loads', 0)
    }
    get total_freight_value() {
        return this.totals(this.tables.commodities.data, 'total_freight_value', 0)
    }
    get annual_tonnes() {
        return this.totals(this.tables.commodities.data, 'tonnes', 0)
    }
    get annual_tonne_kms() {
        return this.totals(this.tables.commodities.data, 'tonne_kms', 0)
    }
    get total_trip_distance() {
        return this.totals(this.tables.commodities.data, 'total_trip_distance', 0)
    }
    get total_trip_duration() {
        return this.totals(this.tables.commodities.data, 'total_trip_duration', 0)
    }
    //get avg_trip_distance() {
    //    return this.averages(this.tables.commodities.data, 'avg_trip_distance', 1)
    //}
    //get avg_trip_duration() {
    //    return this.averages(this.tables.commodities.data, 'avg_trip_duration', 1)
    //}

    //This will return an 'absolute weighted average' value across all movements for road/rail or both. For this, the group by commodity, industry or sector should not be applied.
    //This should only be used when no filtering is aplied by commodity, industry or sector.
    avgValues(statisticType: string) {
        if (this.filterGroup != 'All') {
            return this.averages(this.tables.commodities.data, statisticType, 1)
        } else {
            return this.totals(this.tables.commodities_average.data, statisticType, 1)
        }
    }

    cost_per_tonneFunction(table: any) {
        var data = this.applyFilters(table,)
        var cost = this.total(data, 'trip_transport_costs')
        var tonnes = this.total(data, 'tonnes')
        return this.numberWithCommas((cost / tonnes).toFixed(2))
    }
    get trip_transport_costs() {
        return this.totals(this.tables.commodities.data, 'trip_transport_costs', 0)
    }
    get co2_tn() {
        return this.totals(this.tables.commodities.data, 'co2_tn', 0)
    }


    //-----------------------------
    //-------    Sunburst    ------
    //-----------------------------

    splitString(joinedString: string, replace: string) {
        return joinedString.replace(/([a-zA-Z])(?=[A-Z])/g, '$1' + replace)
            .replace('Grain_', '')
            .replace('_', '')
    }


    sunburstColor(sector: string) {

        var sunburst_colors: any = {
            //"waste": "#17becf",
            //"vehicles": "#d62728 ",
            //"fuel": "#7f7f7f",
            //"mining": "#e377c2",
            //"wood_product": "#8c564b",
            //"livestock": "#ff7f0e",
            //"horticulture": "#2ca02c",
            //"processed_food": "#1f77b4",
            //"cropping": "#bcbd22",
            //"general": "#9467bd",
            //"construction": "#c2b280",
            //"other_food": "#91921a",

            //"Waste": "#17becf",
            //"Vehicles": "#d62728 ",
            //"Fuel": "#7f7f7f",
            //"Mining": "#e377c2",
            //"Wood_Product": "#8c564b",
            //"Livestock": "#ff7f0e",
            //"Horticulture": "#2ca02c",
            //"Processed_Food": "#1f77b4",
            //"Cropping": "#bcbd22",
            //"General": "#9467bd",
            //"Construction": "#c2b280",
            //"Other_Food": "#91921a"

            'beverage': ' #000066',
            'chemicals': '#4f205b',
            'cold_food': ' #2918EC',
            'construction': ' #c2b280',
            'cropping': ' #bcbd22',
            'food': ' #1f77b4',
            'fuel': ' #7f7f7f',
            'general': ' #9467bd',
            'health': '#fcc0a5',
            'horticulture': ' #2ca02c',
            'livestock': ' #ff7f0e',
            'minerals': ' #e377c2',
            'ores_coal': ' #bb45a2',
            'vehicles': ' #d62728',
            'waste': ' #17becf',
            'wood_product': '#8c564b'
        }

        if (sector in sunburst_colors) {
            return sunburst_colors[sector]
        } else {
            return "#000000" //Black
        }
    }

    get sunburstData() {

        var data = [{
            type: "sunburst",
            //maxdepth: 3,
            //hovertemplate: '%{label}<br>$%{value}',
            hoverinfo: 'label+text',
            textinfo: 'label',
            //insidetextorientation: 'radial',
            insidetextfont: {
                color: 'white'
            },
            labels: ["Eve", "Cain", "Seth", "Enos", "Noam", "Abel", "Awan", "Enoch", "Azura"],
            labels_actual: ["Eve", "Cain", "Seth", "Enos", "Noam", "Abel", "Awan", "Enoch", "Azura"],
            parents: ["", "Eve", "Eve", "Seth", "Seth", "Eve", "Eve", "Awan", "Eve"],
            values: [10, 14, 12, 10, 2, 6, 6, 4, 4],
            text: [''],
            ids: [''],
            levels: [''],
            outsidetextfont: { size: 18, color: "#00698f" },
            leaf: { opacity: 0.6 },
            marker: {
                line: { width: 0.3, },
                colors: ['']
            },

            branchvalues: 'total',
            hoverlabel: {
                font: {
                    size: 14,
                    color: 'white'
                },
                align: 'left'
            }
        }];

        var metric_value = this.tables.commodities_all.metric_value;
        var data_arr = this.tables.commodities_all.data;
        var total_costs = this.total(data, metric_value);
        var labels: string[] = ['All'];
        var labels_actual: string[] = ['All'];
        var parents: string[] = [''];
        var values: number[] = [total_costs];
        var ids: string[] = ['All'];
        var levels: string[] = ['root'];
        var text: string[] = ['$' + this.numberWithCommas(total_costs)];
        var colors: string[] = [''];

        var suffix = this.getMetricSettings(metric_value).valuesuffix
        var prefix = this.getMetricSettings(metric_value).valueformat.startsWith('$') ? '$' : ''
        for (var i = 0; i < data_arr.length; i++) {

            var sector = data_arr[i]['sector']
            var industry = data_arr[i]['industry']
            var commodity = data_arr[i]['commodity']
            var color = this.sunburstColor(data_arr[i]['sector'])
            var sector_id = data_arr[i]['sector'] + '_3_'
            var industry_id = data_arr[i]['industry'] + '_2_' + sector
            var commodity_id = data_arr[i]['commodity'] + '_1_' + industry

            if (!ids.includes(sector_id)) {
                ids.push(sector_id)
                labels.push(this.splitString(sector, '<br>'))
                labels_actual.push(sector)
                parents.push('All')
                levels.push('sector')
                colors.push(color)
                var val = this.total_group(data_arr, 'sector', sector, metric_value)
                values.push(val)
                text.push(prefix + this.numberWithCommas(val) + suffix)
            }
            if (!ids.includes(industry_id)) {
                ids.push(industry_id)
                labels.push(this.splitString(industry, '<br>'))
                labels_actual.push(industry)
                parents.push(sector_id)
                levels.push('industry')
                colors.push(color)
                var val = this.total_group(data_arr, 'industry', industry, metric_value, 'sector', sector)
                values.push(val)
                text.push(prefix + this.numberWithCommas(val) + suffix)
            }
            if (!ids.includes(commodity_id) && !this.sensitive_spatial_data_check) {
                ids.push(commodity_id)
                labels.push(this.splitString(commodity, ' '))
                labels_actual.push(commodity)
                parents.push(industry_id)
                levels.push('commodity')
                colors.push(color)
                var val = (data_arr[i][metric_value] as any)
                values.push(val)
                text.push(prefix + this.numberWithCommas(val) + suffix)
            }
        }

        data[0].labels = labels
        data[0].labels_actual = labels_actual
        data[0].parents = parents
        data[0].values = values
        data[0].ids = ids
        data[0].levels = levels
        data[0].text = text
        data[0].marker.colors = colors

        return data;

    }


    sunburstLayout = {
        //width: 700,
        margin: { l: 15, r: 15, b: 15, t: 15 },
        height: window.innerWidth > 700 ? 680 : undefined,
        autosize: true,
        //sunburstcolorway: ["#EF553B", "#00cc96", "#ab63fa", "#19d3f3", "#e763fa", "#FECB52", "#FFA15A", "#FF6692", "#B6E880", "#636efa"],
        //colorway : ['#f3cec9', '#e7a4b6', '#cd7eaf', '#a262a9', '#6f4d96', '#3d3b72', '#182844'],

        font: {
            size: 11.5
        }
    }

    sunburstOnClick(e: any) {

        var clicked = e.points[0]

        var data = clicked.data
        var idx_clicked = clicked.pointNumber
        var id_parent = data.parents[idx_clicked]

        // Indicator if travelling back up the wheel (a higher level commodity)
        var levels = ['commodity', 'industry', 'sector']
        var isHigherLevel = (levels.indexOf(data.levels[idx_clicked]) >= levels.indexOf(this.filterGroupBy))

        // Indicator if the label of the inner circle is the same as the label of the clicked item
        var isPossiblyCenter = (clicked.entry == clicked.label) || (clicked.entry == undefined)

        // If clicked in the center, filter back to the parent
        if (isPossiblyCenter && isHigherLevel) {
            // Use the parent
            var idx_clicked = data.ids.indexOf(id_parent)
        }

        this.filterGroupBy = data.levels[idx_clicked]
        this.filterGroup = data.labels_actual[idx_clicked]

        this.resetSupplyChainFilter()
        this.loadCommodityTables()

        this.$emit('commodity_filter', this.commodityFilter);
        this.$emit('supplychain_filter', this.supplyChainFilter);

    }

    //-----------------------------
    //------- Sankey Diagram ------
    //-----------------------------

    get sankeyDataGroups() {

        var node: any = {
            pad: 30,
            thickness: 30,
            label: [
                "production",
                "storage",
                "processor",
                "mine",
                "transport",
                //           "Manufacturer",
                "distribution",
                "market",
            //    //          "Waste",

                //"Production",
                //"Storage",
                //"Processor",
                //"Mine",
                //"Transport",
                ////           "Manufacturer",
                //"Distribution",
                //"Market",
                //          "Waste",
            ],
            //x: [0.05, 0.2, 0.3, 0.4, 0.55, 0.85, 0.7, 0.95, 0.55],enterprise_operation_type
            //y: [0.2, 0.7, 0.5, 0.6, 0.7, 0.8, 0.3, 0.5, 0.1],
            x: [0.1, 0.2, 0.3, 0.45, 0.7, 0.8, 1],
            y: [0.2, 0.7, 0.5, 0.4, 0.4, 0.25, 0.5],

            hovertemplate: '%{label}<br>Incoming Enterprise Types: %{targetLinks.length}<br>Outgoing Enterprise Types: %{sourceLinks.length}',
            hoverlabel: {
                font: {
                    size: 14
                },
                align: 'left'
            },
        }
        var link: any = {
            //hovertemplate: "$%{value} per tonne",
            source: [],
            target: [],
            value: [],
            hovertemplate: 'Origin: %{source.label}<br>Destination: %{target.label}',
            hoverlabel: {
                font: {
                    size: 14
                },
                align: 'left'
            }
        }
        var data = this.tables.supplychain.data

        //// Build the nodes and populate node groups
        //for (var i = 0; i < data.length; i++) {
        //    var orig = data[i]['orig_category']
        //    var dest = data[i]['dest_category']
        //    if (!node.label.includes(orig)) {
        //        node.label.push(orig)
        //    }
        //    if (!node.label.includes(dest)) {
        //        node.label.push(dest)
        //    }
        //}
        var orig_dest: string[] = []
        // Build the links
        for (var i = 0; i < data.length; i++) {
            var orig = data[i]['orig_category']
            var dest = data[i]['dest_category']
            var key = orig + '_' + dest
            if (!orig_dest.includes(key)) {
                orig_dest.push(key)
                link.source.push(node.label.indexOf(orig))
                link.target.push(node.label.indexOf(dest))
                link.value.push(this.total_group(data, 'orig_category', orig, this.tables.supplychain.metric_value, 'dest_category', dest))
                //link.value.push(1)
            }
        }

        var metric_settings = this.getMetricSettings(this.tables.supplychain.metric_value)
        return [{
            type: "sankey",
            arrangement: 'fixed',
            orientation: "h",
            node: node,
            link: link,
            valueformat: metric_settings.valueformat,
            valuesuffix: metric_settings.valuesuffix,
        }]
    }

    get sankeyData() {

        var node: any = {
            pad: 30,
            thickness: 30,
            label: [],
            //customdata: [],
            hovertemplate: '%{label}<br>Incoming Enterprise Types: %{targetLinks.length}<br>Outgoing Enterprise Types: %{sourceLinks.length}',
            hoverlabel: {
                font: {
                    size: 14
                },
                align: 'left'
            },
        }
        var link: any = {
            //hovertemplate: "$%{value} per tonne",
            source: [],
            target: [],
            value: [],
            customdata: [],
            hovertemplate: 'Origin: %{source.label}<br>Destination: %{target.label}',
            hoverlabel: {
                font: {
                    size: 14
                },
                align: 'left'
            }
        }
        var data = this.tables.supplychain.data

        // Build the node groups
        var nodeGroup: any = {}
        for (var i = 0; i < data.length; i++) {
            var orig_group = data[i]['orig_class']
            var dest_group = data[i]['dest_class']
            if (!(orig_group in nodeGroup)) {
                nodeGroup[orig_group] = [];
            }
            if (!(dest_group in nodeGroup)) {
                nodeGroup[dest_group] = [];
            }
        }

        // Build the nodes and populate node groups
        for (var i = 0; i < data.length; i++) {
            //var orig = this.splitString(data[i]['orig_type'], ' ')
            //var dest = this.splitString(data[i]['dest_type'], ' ')
            var orig = data[i]['orig_type']
            var dest = data[i]['dest_type']
            if (!node.label.includes(orig)) {
                node.label.push(orig)
                //node.customdata.push(data[i]['orig_type'])
                nodeGroup[data[i]['orig_class']].push(node.label.indexOf(orig))
            }
            if (!node.label.includes(dest)) {
                node.label.push(dest)
                //node.customdata.push(data[i]['dest_type'])
                nodeGroup[data[i]['dest_class']].push(node.label.indexOf(dest))
            }
        }

        var groups = []
        for (var key in nodeGroup) {
            if (nodeGroup.hasOwnProperty(key)) {
                groups.push(nodeGroup[key]);
            }
        }

        // Build the links
        for (var i = 0; i < data.length; i++) {
            //var orig = this.splitString(data[i]['orig_type'], ' ')
            //var dest = this.splitString(data[i]['dest_type'], ' ')
            var orig = data[i]['orig_type']
            var dest = data[i]['dest_type']
            link.source.push(node.label.indexOf(orig))
            link.target.push(node.label.indexOf(dest))
            link.value.push(data[i][this.tables.supplychain.metric_value])
            link.customdata.push(data[i]['tonnes'])
        }

        // Sankey fails when only has 1 node looping onto itself, so display it as 2 seperate nodes
        if (node.label.length === 1) {
            node.label.push(node.label[0])
            link.target = [1]
        }

        var metric_settings = this.getMetricSettings(this.tables.supplychain.metric_value)
        var ret = [{
            type: "sankey",
            arrangement: 'fixed',
            orientation: "h",
            node: node,
            link: link,
            valueformat: metric_settings.valueformat,
            valuesuffix: metric_settings.valuesuffix,
        }]

        return ret
    }

    get sankeyLayout() {

        var sankeyLayout = {
            //title: "Basic Sankey",
            height: this.commod_sensitive && !this.isAdmin ? 700 - 70 : 680,
            //height: 680,
            autosize: true,
            //width: 730,
            //            margin: { l: 40, r: 40, b: 40, t: 20 },
            margin: { l: 60, r: 60, b: 40, t: this.commod_sensitive && !this.isAdmin ? 25 : 40 },
            font: {
                size: 14
            }
        }
        return sankeyLayout
    }


    sankeyOnClick(e: any) {

        if (!this.sensitive_spatial_data) {
            if (e.points[0].source && e.points[0].target) {
                this.orig_enterprise_type = e.points[0].source.label;
                this.dest_enterprise_type = e.points[0].target.label;
                this.enterprise_type_operation = 'Intersection'
            } else {
                this.orig_enterprise_type = e.points[0].label;
                this.dest_enterprise_type = e.points[0].label;
                this.enterprise_type_operation = 'Union'
            }
            this.$emit('supplychain_filter', this.supplyChainFilter);

            this.loadTable(this.tables.commodities, this.commodityReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
            this.loadTable(this.tables.commodities_average, this.commodityReportUrl, { 'groupBy': false });
            this.loadTable(this.tables.pathcost, this.pathcostReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
            this.loadTable(this.tables.triptype, this.triptypeReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
            this.loadTable(this.tables.transportlogistics, this.transportlogisticsReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
            this.loadTable(this.tables.tripdistribution, this.tripDistributionReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
            this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
            this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
            this.loadTable(this.tables.backload, this.backloadReportUrl, { 'bar_chart_type': 'backload', 'groupBy_l2': this.sensitive_spatial_data_check });
            this.commod_sensitive_b = false;

            ////temporal comparison tables
            //this.loadTable(this.tables.commoditiestemporal, this.commoditytemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
            //this.loadTable(this.tables.pathcosttemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'pathcost', 'groupBy_l2': this.sensitive_spatial_data_check });
            //this.loadTable(this.tables.triptypetemporal, this.pathcosttemporalReportUrl, { 'bar_chart_type': 'triptype', 'groupBy_l2': this.sensitive_spatial_data_check });
            //this.loadTable(this.tables.tripdistributiontemporal, this.tripDistributiontemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
            //this.loadTable(this.tables.transportlogisticstemporal, this.transportlogisticstemporalReportUrl, { 'groupBy_l2': this.sensitive_spatial_data_check });
        }

    }


    //-----------------------------
    //-------    d3 Colors   ------
    //-----------------------------
    d3colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

    //color for Box Plot
    d3colorsFunction(n: any) {
        var colors: any = []
        for (var i = 0; i < n; i++) {
            var color = this.d3colors[i % this.d3colors.length]
            colors.push(color)
        }
        return colors
    };

    // color for Bar Chart
    supplyLegColorsFunction(supplyLeg: string) {

        var supplyLeg_colors: any = {
            "production": '#1f77b4',
            "import/export": '#ff7f0e',
            "distribution": '#2ca02c',
            "primarydistribution": '#d62728',
            "processing": '#9467bd',
            "input": '#8c564b',

            ////colors for trip type/length chart
            //'long': '#1f77b4',
            //'medium': '#ff7f0e',
            //'short': '#2ca02c'

            //"Production": '#1f77b4',
            //"Import/Export": '#ff7f0e',
            //"Distribution": '#2ca02c',
            //"PrimaryDistribution": '#d62728',
            //"Processing": '#9467bd',
            //"Input": '#8c564b',

            ////colors for trip type/length chart
            'Long': '#1f77b4',
            'Medium': '#ff7f0e',
            'Short': '#2ca02c'
        }
        if (supplyLeg in supplyLeg_colors) {
            return supplyLeg_colors[supplyLeg]
        } else {
            return "#000000" //Black
        }
    }

    // color for Transport Logistics Chart
    transportLogisticsColorsFunction(costItem: string) {
        var costitem_colors: any = {
            'Driver cost': '#f3cec9',
            'Capital cost': '#e7a4b6',
            'Driver/crew cost': '#cd7eaf',
            'Fuel cost': '#a262a9',
            'Fixed costs': '#6f4d96',
            'Maintenance cost': '#3d3b72',
            'Operating cost - rail': '#182844',

            'Load cost': '#cd7eaf',
            'Unload cost': '#a262a9',
            'Decouple cost': '#6f4d96',
            'Fatigue cost': '#3d3b72',
            'Logistics costs - rail': '#182844',

            'Fatigue hours': '#a262a9',
            'Load hours': '#6f4d96',
            'Unload hours': '#3d3b72',
            'Decoupling hours': '#182844',

            'Crew cost': '#cd7eaf',
            'Operating cost': '#182844',
            'Logistics costs': '#182844',
        }
        if (costItem in costitem_colors) {
            return costitem_colors[costItem]
        } else {
            return "#000000" //Black
        }
    }

    ////dark to light green scale
    //transportLogisticsColorsInternationalFunction(costItem: string) {
    //    var costitem_colors: any = {
    //        'Driver cost': '#56C05E',
    //        'Capital cost': '#39B359',
    //        'Driver/crew cost': '#1DA55A',
    //        'Fuel cost': '#179369',
    //        'Fixed costs': '#127F72',
    //        'Maintenance cost': '#0E646C',
    //        'Operating cost - rail': '#0A4057',

    //        'Load cost': '#1DA55A',
    //        'Unload cost': '#179369',
    //        'Decouple cost': '#127F72',
    //        'Fatigue cost': '#0E646C',
    //        'Logistics costs - rail': '#0A4057',

    //        'Fatigue hours': '#1DA55A',
    //        'Load hours': '#179369',
    //        'Unload hours': '#127F72',
    //        'Decoupling hours': '#0E646C',

    //        'Crew cost': '#1DA55A',
    //        'Operating cost': '#179369',
    //        'Logistics costs': '#127F72',
    //    }
    //    if (costItem in costitem_colors) {
    //        return costitem_colors[costItem]
    //    } else {
    //        return "#000000" //Black
    //    }
    //}

    //-----------------------------
    //-------    Bar Chart   ------
    //-----------------------------

    sortBarData(data: any, sortBy: any) {

        data.sort((a: any, b: any) => (a.sortBy > b.sortBy) ? 1 : ((b.sortBy > a.sortBy) ? -1 : 0))

        return data

    }

    iterateObjectKeys(missingData: any, data: any, chart_type: any) {
        if (chart_type === 'pathcost') {
            var keysToSkip = ['category', 'commod_l3', 'commod_l2', 'commodity']
        } else if (chart_type === 'trip_type') {
            var keysToSkip = ['trip_type', 'commod_l3', 'commod_l2', 'commodity']
        } else {
            var keysToSkip = ['commod_l3', 'commod_l2', 'commodity']
        }
        for (var i = 0; i < missingData.length; i++) {
            var d = missingData[i]
            for (let key in d) {
                if (d.hasOwnProperty(key)) {
                    if (!keysToSkip.includes(key)) {
                        d[key] = 0
                    }
                }
            }
            data.push(d)
        }

        var sortBy: any = this.commoditiesGroupBy
        data.sort((a: any, b: any) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0))

        return (data)
    }



    get commoditiesGroupBy() {
        if (this.sensitive_spatial_data_check) {
            return 'commod_l2'
        } else if (this.isRoot) {
            return 'commod_l3'
        } else { return 'commodity'}
    }

    //this function adds elements missing in one array based on another to make both arrays X axis identical but missing object key values are set to zero
    barChartTempDataArrayFunction(obj1: any, obj2: any, chart_type: any) {
        //var groupBy = this.isRoot ? 'commod_l3' : 'commodity'
        var groupBy = this.commoditiesGroupBy
        let missingInData1 = obj2.filter((obj: any) => obj1.every((s: any) => s[groupBy] !== obj[groupBy]));
        let missingInData2 = obj1.filter((obj: any) => obj2.every((s: any) => s[groupBy] !== obj[groupBy]));

        var data1 = [...obj1], data2 = [...obj2]

        var missingData1 = missingInData1.map((a: any) => { return { ...a } }), missingData2 = missingInData2.map((a: any) => { return { ...a } })

        if (missingData1.length > 0) {
            this.iterateObjectKeys(missingData1, data1, chart_type)
        }

        if (missingData2.length > 0) {
            this.iterateObjectKeys(missingData2, data2, chart_type)
        }

        return [data1, data2]
    }


    barDataFunction(data: any, chart_type: any) {
        //var index = this.isRoot ? 'commod_l3' : 'commodity'
        var index = this.commoditiesGroupBy
        if (chart_type === 'pathcost') {
            var columns = 'category', metric = this.tables.pathcost.metric_value
            var sortOrder: string[] = ['Distribution', 'Import/Export', 'Production', 'Processing', 'PrimaryDistribution', 'Input']
            data.sort(function (a: any, b: any) { return sortOrder.indexOf(a.category) - sortOrder.indexOf(b.category); });

        } else {
            var columns = 'trip_type', metric = this.tables.triptype.metric_value
            var sortOrder: string[] = ['Short', 'Medium', 'Long']
            data.sort(function (a: any, b: any) { return sortOrder.indexOf(a.trip_type) - sortOrder.indexOf(b.trip_type); });
            }

        //data
        let index_vals = [...new Set(data.map(function (val: any) { return val[index]; }))].sort();
        var column_names = ([...new Set(data.map(function (val: any) { return val[columns]; }))] as string[]);
        var data_array = column_names.map(function (column_name: any) { return new Array(index_vals.length); })

        for (var i = 0; i < data.length; i++) {
            var row = index_vals.indexOf(data[i][index])
            var col = column_names.indexOf(data[i][columns])
            data_array[col][row] = data[i][metric]
            //var color = this.sunburstColor(data[i]['sector'])
        }
        return [index_vals, column_names, data_array]
    }

    get barData() {
        let chart_type = 'pathcost'
        const [index_vals, column_names, data_array] = this.barDataFunction(this.tables.pathcost.data, chart_type);

        var ret: any = []
        for (var i = 0; i < column_names.length; i++) {

            let temp_column_names: any = column_names[i]

            var trace1 = {
                x: index_vals, // commodities
                y: data_array[i],
                name: column_names[i], //supply chain legs
                type: 'bar',
                opacity: 0.65,
                //legendgroup: 'group1',
                legendgrouptitle: {
                    text: 'First group',
                },
                marker: {
                    color: this.supplyLegColorsFunction(temp_column_names),
                    line: {
                        width: 1.0
                    }
                },
            };
            ret.push(trace1)
        }

        ////international comparison
        //if (this.internationalTripsDefined) {
        //    const [index_vals_int, column_names_int, data_array_int] = this.barDataFunction(this.tables.pathcostindonesia.data, chart_type);
        //    for (var i = 0; i < column_names_int.length; i++) {

        //        let temp_column_names: any = column_names_int[i]

        //        var trace2 = {
        //            x: index_vals_int, // commodities
        //            y: data_array_int[i],
        //            //q1: 1,
        //            //median: 100,
        //            //q2: 1,
        //            name: column_names_int[i], //supply chain legs
        //            type: 'box',
        //            boxpoints: 'all',
        //            jitter: 0,
        //            pointpos: 0,
        //            opacity: 1,
        //            showlegend: false,
        //            //legendgroup: 'group2',
        //            text: 'Indonesia',
        //            //legendgrouptitle: {
        //            //    text: 'First group',
        //            //},
        //            //fillcolor: this.supplyLegColorsFunction(temp_column_names),
        //            marker: {
        //                size: 15,
        //                color: this.supplyLegColorsFunction(temp_column_names),
        //                line: {
        //                    color: 'black',
        //                    width: 1.5,
        //                },
        //                symbol: 'circle'

        //            },
        //            line: {
        //                width: 0,
        //                height: 0
        //            }

        //        };
        //        ret.push(trace2)
        //    }


        //}

        return ret
    }

    //get barDataTemporal() {
    //    let chart_type = 'pathcost'

    //    const temporal_baseline = this.baselineFilter(this.tables.pathcosttemporal.data, this.selectedBaselineValue);
    //    const [barData1, barData2] = this.barChartTempDataArrayFunction(this.tables.pathcost.data, temporal_baseline, chart_type);

    //    const [index_vals, column_names, data_array] = this.barDataFunction(barData1, chart_type);
    //    const [index_vals_temp, column_names_temp, data_array_temp] = this.barDataFunction(barData2, chart_type);

    //    var ret: any = []
    //    for (var i = 0; i < column_names.length; i++) {

    //        let temp_column_names: any = column_names[i]

    //        var trace1 = {
    //            x: index_vals, // commodities
    //            y: data_array[i],
    //            name: column_names[i], //supply chain legs
    //            type: 'bar',
    //            opacity: 0.65,
    //            showlegend: true,
    //            //legendgroup: 'a',
    //            marker: {
    //                color: this.supplyLegColorsFunction(temp_column_names),
    //                line: {
    //                    width: 1.0
    //                }
    //            },
    //        };
    //        ret.push(trace1)
    //    }

    //    for (var i = 0; i < column_names_temp.length; i++) {

    //        let temp_column_names: any = column_names_temp[i]

    //        var trace2 = {
    //            x: index_vals_temp, // commodities
    //            y: data_array_temp[i],
    //            name: column_names_temp[i], //supply chain legs
    //            type: 'bar',
    //            opacity: 0.65,
    //            xaxis: 'x2',
    //            yaxis: 'y2',
    //            showlegend: false,
    //            //legendgroup: 'a',
    //            marker: {
    //                color: this.supplyLegColorsFunction(temp_column_names),
    //                //color: colors_temp[i],
    //                line: {
    //                    width: 1.0
    //                }
    //            },
    //        };
    //        ret.push(trace2)
    //    }

    //    return ret
    //}

    get barLayout() {
        var metric_settings = this.getMetricSettings(this.tables.pathcost.metric_value)
        return {
            barmode: 'group',
            boxmode: 'group',
            bargap: 0.2, //0.07
            boxgap: 0.2,
            bargroupgap: 0,
            boxgroupgap: 0,
            hovermode: 'closest',
            showlegend: true, // always show legend even if 1 item
            margin: { l: 100, r: 50, b: 100, t: 0 },
            font: {
                size: 14
                //size: 11.5
            },
            //boxmode: 'group',
            line: { color: '#ffffff' },
            height: 350, //350
            //width: 700,
            autosize: true,
            yaxis: {
                title: metric_settings.text + ' ' + metric_settings.units,
                tickprefix: metric_settings.tickprefixformat,
                //tickformat: metric_settings.valueformat,
                //tickformat: "$,f"
                //zeroline: true,
                //type: 'log'
            },
            legend: {
                orientation: 'h',
                //xanchor: 'left',
                x: 0,
                y: 1.2 //1.1
            },

        };

    }

    //get barLayoutTemporal() {

    //    var metric_settings = this.getMetricSettings(this.tables.pathcost.metric_value)
    //    return {
    //        barmode: 'bar',
    //        showlegend: true, // always show legend even if 1 item
    //        margin: { l: 100, r: 50, b: 170, t: 0 },
    //        grid: { rows: 1, columns: 2, pattern: 'independent' },
    //        font: {
    //            size: 14
    //            //size: 11.5
    //        },
    //        //boxmode: 'group',
    //        line: { color: '#ffffff' },
    //        height: 410, //350
    //        //width: 700,
    //        autosize: true,
    //        shared_yaxes: true,

    //        yaxis: {
    //            title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            //tickformat: "$,f"
    //            zeroline: true,

    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            matches: 'y',
    //        },
    //        xaxis: {
    //            visible: true,
    //            title: '<b>' + this.currentBaseline(true) + ' baseline</b>',
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: '<b>' + this.baseline_item_selected + ' baseline</b>',
    //        },
    //        legend: {
    //            orientation: 'h',
    //            //xanchor: 'left',
    //            x: 0,
    //            y: 1.2 //1.1
    //        },

    //    };

    //}




    ////--------------------------------------
    ////------- Power Figures Subplots -------
    ////--------------------------------------

    //baselineFilter(dataTable: any, baselineName: string) {
    //    const filteredData = dataTable.filter((x: any) => x.baseline === baselineName);
    //    return filteredData
    //}


    //// this will create 10 variables - this is needed to dinamicaly assign variables in chart functions
    //createVariables() {
    //    var variables: any = [];
    //    for (var i = 0; i <= 10; ++i) {
    //        variables[i] = 'y' + i;
    //    }
    //    return variables;
    //}


    //get subplotsDataTables() {
    //    var table_commod = this.filterGroup != 'All' ? this.tables.commodities.data : this.tables.commodities_average.data
    //    var subplot_tables: any = []
    //    var baseline_items_compare = this.baselineItemsToCompare

    //    for (var i = 0; i < baseline_items_compare.length; i++) {
    //        var temp = baseline_items_compare[i]
    //        temp = this.baselineFilter(this.tables.commoditiestemporal.data, baseline_items_compare[i])
    //        subplot_tables.push(temp)
    //    }
    //    subplot_tables.push(table_commod)

    //    return subplot_tables
    //}


    //tracesFunction(metric: any) {
    //    const simple_metrics: any = ['trailer_loads', 'tonnes', 'tonne_kms', 'trip_transport_costs', 'total_trip_distance', 'co2_tn', 'total_freight_value']
    //    var subplot_tables = this.subplotsDataTables
    //    var yaxis: any = this.createVariables()

    //    if (simple_metrics.includes(metric)) {
    //        var y: any = [];
    //        for (var i = 0; i < subplot_tables.length; i++) {
    //            yaxis[i + 1] = this.totals(subplot_tables[i], metric, 0);
    //            y.push(yaxis[i + 1])
    //        }
    //        return y

    //    } else if (metric === 'cost_per_tonne') {
    //        var y: any = []
    //        for (var i = 0; i < subplot_tables.length; i++) {
    //            yaxis[i + 1] = parseFloat(this.cost_per_tonneFunction(subplot_tables[i]))
    //            y.push(yaxis[i + 1])
    //        }
    //        return y

    //    } else if (metric === 'avg_trip_distance') {
    //        var y: any = []
    //        for (var i = 0; i < subplot_tables.length; i++) {
    //            yaxis[i + 1] = parseFloat(this.averages(subplot_tables[i], 'avg_trip_distance', 1))
    //            y.push(yaxis[i + 1])
    //        }
    //        return y
    //    }

    //    else if (metric === 'avg_trip_duration') {
    //        var y: any = []
    //        for (var i = 0; i < subplot_tables.length; i++) {
    //            yaxis[i + 1] = parseFloat(this.averages(subplot_tables[i], 'avg_trip_duration', 1))
    //            y.push(yaxis[i + 1])
    //        }
    //        return y
    //    }
    //}


    //get powerFiguresSubplotsData() {

    //    var trace_metric: any = ['trailer_loads', 'tonnes', 'cost_per_tonne', 'trip_transport_costs', 'total_freight_value', 'total_trip_distance', 'tonne_kms', 'avg_trip_distance', 'avg_trip_duration', 'co2_tn',]

    //    var color = ['orange',
    //        'blue',
    //        'green'
    //    ];

    //    var ret: any = [];
    //    var baselines = this.baseline_items_all.map(a => a.text);

    //    for (var i = 0; i < trace_metric.length; i++) {
    //        var trace = trace_metric[i]
    //        ret.push({
    //            x: baselines, // baseline
    //            y: this.tracesFunction(trace),
    //            xaxis: i === 0 ? 'x' : 'x' + (i + 1),
    //            yaxis: i === 0 ? 'y' : 'y' + (i + 1),
    //            name: '',
    //            type: 'scatter',
    //            opacity: 0.65,
    //            showlegend: false,
    //            //legendgroup: 'a',

    //            mode: 'lines + markers',
    //            marker: {
    //                color: color,
    //                size: 10,
    //                line: {
    //                    //width: 1.0,
    //                    //color: 'black',
    //                },
    //            },
    //            line: {
    //                color: 'grey',
    //                width: 1.0
    //            }
    //        })

    //    }

    //    for (var i = 0; i < baselines.length; i++) {
    //        //this is an empty trace to show and style legend
    //        var trace: any = this.createVariables()

    //        trace[i + 1] = {
    //            x: [null], // commodities
    //            y: [null],
    //            //name: column_names[i], //supply chain legs
    //            xaxis: 'x',
    //            yaxis: 'y',
    //            //type: 'scatter',
    //            mode: 'markers',
    //            opacity: 0.65,
    //            showlegend: true,
    //            name: baselines[i],
    //            //legendgroup: 'a',
    //            marker: {
    //                color: color[i],
    //                size: 10,
    //            },
    //        };
    //        ret.push(trace[i + 1])

    //    }

    //    return ret;
    //}


    //get powerFiguresSubplotsLayout () {

    //    //var metric_settings = this.getMetricSettings(this.tables.commodities.metric_value)

    //    return {
    //        //barmode: 'bar',
    //        //showlegend: true, // always show legend even if 1 item
    //        margin: { l: 100, r: 50, b: 80, t: 0, },
    //        grid: { rows: 2, columns: 5, pattern: 'independent' },
    //        font: {
    //            size: 12.5
    //            //size: 12
    //        },
    //        //boxmode: 'group',
    //        //line: { color: '#ffffff' },
    //        height: 450, //350
    //        //width: 1000,
    //        autosize: true,
    //        //shared_yaxes: true,

    //        yaxis: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            //tickformat: "$,f",
    //            zeroline: true,
    //            rangemode: 'tozero',

    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            //zeroline: true,
    //            //matches: 'y',
    //            //tickformat: "t",
    //            zeroline: true,
    //            rangemode: 'tozero',
    //        },
    //        yaxis3: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            tickformat: "$,.3s",
    //            //autorange: 'reversed',
    //            //matches: 'y',
    //        },
    //        yaxis4: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            tickformat: "$,.3s",
    //            //matches: 'y',
    //        },
    //        yaxis5: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            tickformat: "$,.3s",
    //            //matches: 'y',
    //        },
    //        yaxis6: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            //matches: 'y',
    //        },
    //        yaxis7: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            //matches: 'y',
    //        },
    //        yaxis8: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            //matches: 'y',
    //        },
    //        yaxis9: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            //matches: 'y',
    //        },
    //        yaxis10: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            rangemode: 'tozero',
    //            //matches: 'y',
    //        },


    //        xaxis: {
    //            visible: true,
    //            title: this.mainUnitType,
    //            //automargin: true,
    //            showticklabels: false
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: 'Annual Tonnes (t)',

    //            showticklabels: false
    //        },
    //        xaxis3: {
    //            visible: true,
    //            title: 'Cost per payload tonne ($)',

    //            showticklabels: false
    //        },
    //        xaxis4: {
    //            visible: true,
    //            title: 'Total Transport Costs ($)',

    //            showticklabels: false
    //        },
    //        xaxis5: {
    //            visible: true,
    //            title: 'Total Freight Value ($)',

    //            showticklabels: false
    //        },
    //        xaxis6: {
    //            visible: true,
    //            title: 'Total Travel Distance (km)',

    //            showticklabels: false
    //        },
    //        xaxis7: {
    //            visible: true,
    //            title: 'Annual Tonne-km',

    //            showticklabels: false
    //        },
    //        xaxis8: {
    //            visible: true,
    //            title: 'Avg Trip Distance (km)',

    //            showticklabels: false
    //        },
    //        xaxis9: {
    //            visible: true,
    //            title: 'Avg Trip Duration (hrs)',

    //            showticklabels: false
    //        },
    //        xaxis10: {
    //            visible: true,
    //            title: 'Total CO2 Emissions (t)',

    //            showticklabels: false
    //        },
    //        legend: {
    //            orientation: 'h',
    //            //xanchor: 'left',
    //            x: 0,
    //            y: 1.2, //1.1
    //            font: {
    //                size: 17,
    //            },
    //        },

    //    };

    //}


    //-----------------------------
    //-------Bar Chart-trip type ------
    //-----------------------------
    get tripTypeData() {
        let chart_type = 'trip_type'
        const [index_vals, column_names, data_array] = this.barDataFunction(this.tables.triptype.data, chart_type);
        //var data = this.tables.triptype.data
        //var sortOrder: string[] = ['Long', 'Medium', 'Short']
        //data.sort(function (a: any, b: any) { return sortOrder.indexOf(a.trip_type) - sortOrder.indexOf(b.trip_type); });
        //var index = this.isRoot ? 'commod_l3' : 'commodity'
        //var columns = 'trip_type'

        var ret: any = []
        for (var i = 0; i < column_names.length; i++) {
            let temp_column_names: any = column_names[i]
            var trace1 = {
                x: index_vals,
                y: data_array[i],
                name: column_names[i],
                type: 'bar',
                opacity: 0.65,
                marker: {
                    color: this.supplyLegColorsFunction(temp_column_names),
                    line: {
                        width: 1.0
                    }
                },

            };
            ret.push(trace1)
        }

    //    //international comparison
    //    if (this.internationalTripsDefined) {
    //        const [index_vals_int, column_names_int, data_array_int] = this.barDataFunction(this.tables.triptypeindonesia.data, chart_type);
    //        for (var i = 0; i < column_names_int.length; i++) {

    //            let temp_column_names: any = column_names_int[i]

    //            var trace2 = {
    //                x: index_vals_int, // commodities
    //                y: data_array_int[i],
    //                //q1: 1,
    //                //median: 100,
    //                //q2: 1,
    //                name: column_names_int[i], //supply chain legs
    //                type: 'box',
    //                boxpoints: 'all',
    //                jitter: 0,
    //                pointpos: 0,
    //                opacity: 1,
    //                showlegend: false,
    //                //legendgroup: 'group2',
    //                text: 'Indonesia',
    //                //legendgrouptitle: {
    //                //    text: 'First group',
    //                //},
    //                //fillcolor: this.supplyLegColorsFunction(temp_column_names),
    //                marker: {
    //                    size: 15,
    //                    color: this.supplyLegColorsFunction(temp_column_names),
    //                    line: {
    //                        color: 'black',
    //                        width: 1.5,
    //                    },
    //                    symbol: 'circle'

    //                },
    //                line: {
    //                    width: 0,
    //                    height: 0
    //                }
    //            };
    //            ret.push(trace2)
    //        }

    //    }

        return ret

    }

    //get tripTypeDataTemporal() {
    //    let chart_type = 'trip_type'

    //    const [barData1, barData2] = this.barChartTempDataArrayFunction(this.tables.triptype.data, this.tables.triptypetemporal.data, chart_type);
    //    const [index_vals, column_names, data_array] = this.barDataFunction(barData1, chart_type);
    //    const [index_vals_temp, column_names_temp, data_array_temp] = this.barDataFunction(barData2, chart_type);

    //    var ret: any = []
    //    for (var i = 0; i < column_names.length; i++) {
    //        let temp_column_names: any = column_names[i]
    //        var trace1 = {
    //            x: index_vals,
    //            y: data_array[i],
    //            name: column_names[i],
    //            type: 'bar',
    //            opacity: 0.65,
    //            showlegend: true,
    //            marker: {
    //                color: this.supplyLegColorsFunction(temp_column_names),
    //                line: {
    //                    width: 1.0
    //                }
    //            },
    //        };
    //        ret.push(trace1)
    //    }

    //    for (var i = 0; i < column_names_temp.length; i++) {
    //        let temp_column_names: any = column_names_temp[i]
    //        var trace2 = {
    //            x: index_vals_temp,
    //            y: data_array_temp[i],
    //            xaxis: 'x2',
    //            yaxis: 'y2',
    //            name: column_names_temp[i],
    //            type: 'bar',
    //            opacity: 0.65,
    //            showlegend: false,
    //            marker: {
    //                color: this.supplyLegColorsFunction(temp_column_names),
    //                line: {
    //                    width: 1.0
    //                }
    //            },

    //        };
    //        ret.push(trace2)
    //    }
    //    return ret
    //}

    get tripTypeLayout() {
        var metric_settings = this.getMetricSettings(this.tables.triptype.metric_value)
        return {
            barmode: 'group',
            boxmode: 'group',
            bargap: 0.2, //0.07
            boxgap: 0.2,
            bargroupgap: 0,
            boxgroupgap: 0,
            hovermode: 'closest',
            //barmode: 'bar',
            showlegend: true, // always show legend even if 1 item
            margin: { l: 100, r: 50, b: 100, t: 0 },
            colorway: ['#1f77b4', '#ff7f0e', '#2ca02c'],
            //width: 700,
            //height: 700,
            font: {
                size: 14
            },
            //boxmode: 'group',
            //margin: { l: 80, r: 50, b: 80, t: 0 },
            height: 350,
            autosize: true,
            yaxis: {
                title: metric_settings.text + ' ' + metric_settings.units,
                tickprefix: metric_settings.tickprefixformat,
                //tickformat: metric_settings.valueformat
                //tickformat: "$,f"
                //zeroline: false
            },
            xaxis: {
                visible: true
            },
            legend: {
                orientation: 'h',
                //xanchor: 'left',
                x: 0,
                y: 1.2
            },
            //boxmode: 'group'
        };
    }

    //get tripTypeLayoutTemporal() {
    //    var metric_settings = this.getMetricSettings(this.tables.triptype.metric_value)
    //    return {
    //        barmode: 'bar',
    //        showlegend: true, // always show legend even if 1 item
    //        margin: { l: 100, r: 50, b: 170, t: 0 },
    //        grid: { rows: 1, columns: 2, pattern: 'independent' },
    //        colorway: ['#1f77b4', '#ff7f0e', '#2ca02c'],
    //        //width: 700,
    //        //height: 700,
    //        font: {
    //            size: 14
    //        },
    //        height: 410,
    //        autosize: true,
    //        shared_yaxes: true,

    //        yaxis: {
    //            title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            //tickformat: "$,f"
    //            zeroline: true,
    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            tickprefix: metric_settings.tickprefixformat,
    //            zeroline: true,
    //            matches: 'y',
    //        },
    //        xaxis: {
    //            visible: true,
    //            title: '<b>' + this.currentBaseline(true) + ' baseline</b>',
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: '<b>' + this.baseline_item_selected + ' baseline</b>',
    //        },
    //        legend: {
    //            orientation: 'h',
    //            //xanchor: 'left',
    //            x: 0,
    //            y: 1.2
    //        },
    //    };
    //}

    //-----------------------------
    //----Transport Logistics------
    //-----------------------------

    get logisticsChart() {
        if (this.temporalTripsDefined)
            return false
        else if (this.internationalTripsDefined) {
            return false
        }
        else {
            return true
        }
    }

    transportLogisticsDataFunction(data: any) {
        var index = this.commoditiesGroupBy
        var metric_settings = this.getMetricSettingsLogisticsChart(this.tables.transportlogistics.metric_value)
        var metric = metric_settings.metric

        var column_names_chart: string[];
        var data_chart: any[];

        let index_vals = [...new Set(data.map(function (val: any) { return val[index]; }))];

        var capital_cost: any = [], driver_cost: any = [], fuel_cost: any = [], fixed_cost: any = [], maintenance_cost: any = [], operating_cost: any = [], load_c: any = [],
            unload_c: any = [], decoup_c: any = [], fat_c: any = [], logistic_cost: any = [], fat_h: any = [], load_h: any = [], unload_h: any = [], decoup_h: any = [];

        // extract data to individual arrays
        for (var i = 0; i < data.length; i++) {
            capital_cost.push(data[i]['capital_cost']);
            driver_cost.push(data[i]['driver_cost']);
            fuel_cost.push(data[i]['fuel_cost']);
            fixed_cost.push(data[i]['fixed_cost']);
            maintenance_cost.push(data[i]['maintenance_cost']);
            operating_cost.push(data[i]['operating_cost']);
            load_c.push(data[i]['load_c']);
            unload_c.push(data[i]['unload_c']);
            decoup_c.push(data[i]['decoup_c']);
            fat_c.push(data[i]['fat_c']);
            logistic_cost.push(data[i]['logistic_cost']);
            fat_h.push(data[i]['fat_h']);
            load_h.push(data[i]['load_h']);
            unload_h.push(data[i]['unload_h']);
            decoup_h.push(data[i]['decoup_h']);
        }

        //group individual arrays
        var transport_costs_both = [capital_cost, driver_cost, fuel_cost, fixed_cost, maintenance_cost, operating_cost];
        var logistics_costs_both = [load_c, unload_c, decoup_c, fat_c, logistic_cost];
        var logistics_times_both = [fat_h, load_h, unload_h, decoup_h];

        var transport_costs_road = [capital_cost, driver_cost, fuel_cost, fixed_cost, maintenance_cost];
        var logistics_costs_road = [load_c, unload_c, decoup_c, fat_c];
        var logistics_times_road = [fat_h, load_h, unload_h, decoup_h];

        var transport_costs_rail = [capital_cost, driver_cost, fuel_cost, maintenance_cost, operating_cost];
        var logistics_costs_rail = [logistic_cost];
        var logistics_times_rail = [fat_h, load_h, unload_h];

        //select legend names and y data array according to user selection
        if (metric === 'transport_costs' && this.mode == "") {
            var column_names_chart = ['Capital cost', 'Driver/crew cost', 'Fuel cost', 'Fixed costs', 'Maintenance cost', 'Operating cost - rail'];
            var data_chart = transport_costs_both.slice(0);
        } else if (metric === 'logistics_costs' && this.mode == "") {
            var column_names_chart = ['Load cost', 'Unload cost', 'Decouple cost', 'Fatigue cost', 'Logistics costs - rail'];
            var data_chart = logistics_costs_both.slice(0);
        } else if (metric === 'logistics_time' && this.mode == "") {
            var column_names_chart = ['Fatigue hours', 'Load hours', 'Unload hours', 'Decoupling hours'];
            var data_chart = logistics_times_both.slice(0);

        } else if (metric === 'transport_costs' && this.mode == 'road') {
            var column_names_chart = ['Capital cost', 'Driver cost', 'Fuel cost', 'Fixed costs', 'Maintenance cost'];
            var data_chart = transport_costs_road.slice(0);
        } else if (metric === 'logistics_costs' && this.mode == 'road') {
            var column_names_chart = ['Load cost', 'Unload cost', 'Decouple cost', 'Fatigue cost'];
            var data_chart = logistics_costs_road.slice(0);
        } else if (metric === 'logistics_time' && this.mode == 'road') {
            var column_names_chart = ['Fatigue hours', 'Load hours', 'Unload hours', 'Decoupling hours'];
            var data_chart = logistics_times_road.slice(0);

        } else if (metric === 'transport_costs' && this.mode == 'rail') {
            var column_names_chart = ['Capital cost', 'Crew cost', 'Fuel cost', 'Maintenance cost', 'Operating cost'];
            var data_chart = transport_costs_rail.slice(0);
        } else if (metric === 'logistics_costs' && this.mode == 'rail') {
            var column_names_chart = ['Logistics costs'];
            var data_chart = logistics_costs_rail.slice(0);
        } else {
            var column_names_chart = ['Fatigue hours', 'Load hours', 'Unload hours'];
            var data_chart = logistics_times_rail.slice(0);
        }

        return [index_vals, data_chart, column_names_chart]
    }

    get transportLogisticsData() {
        const [index_vals, data_chart, column_names_chart] = this.transportLogisticsDataFunction(this.tables.transportlogistics.data);

        var ret: any = []

        for (var i = 0; i < column_names_chart.length; i++) {
            let temp_column_names_chart: any = column_names_chart[i]

            var trace = {
                x: index_vals,//commodities
                y: data_chart[i],//data_array[i],
                name: column_names_chart[i],
                type: 'bar',
                //xaxis: 'x1',
                //width: 0.4,
                //offset: -0.4,
                //offsetgroup: "1",
                marker: {
                    color: this.transportLogisticsColorsFunction(temp_column_names_chart),
                    opacity: 0.75
                }
            };
            ret.push(trace)
        }

        ////international comparison
        //if (this.isAdmin && this.internationalTripsDefined) {
        //    //let chart_type = 'transport_logistics'

        //    const [index_vals_int, data_chart_int, column_names_chart_int] = this.transportLogisticsDataFunction(this.tables.transportlogisticsindonesia.data);

        //    for (var i = 0; i < column_names_chart_int.length; i++) {
        //        let temp_column_names_int: any = column_names_chart_int[i]

        //        var trace2 = {
        //            x: index_vals_int,//commodities
        //            y: data_chart_int[i],//data_array[i],
        //            name: column_names_chart_int[i],
        //            type: 'bar',
        //            xaxis: 'x2',
        //            //width: 0.4,
        //            //offset: -0.8,
        //            //offsetgroup: "2",
        //            marker: {
        //                color: 'blue',//this.transportLogisticsColorsFunction(temp_column_names_int),
        //                opacity: 0.75
        //            },
        //            line: {
        //                color: 'black',
        //                width: 1.5,
        //            },
        //            //fillcolor: colors_int[i]

        //        };
        //        ret.push(trace2)
        //    }

        //}

        return ret
    }


    //get transportLogisticsDataTemporal() {
    //    let chart_type = 'transport_logistics'

    //    const temporal_baseline = this.baselineFilter(this.tables.transportlogisticstemporal.data, this.selectedBaselineValue)
    //    const [barData1, barData2] = this.barChartTempDataArrayFunction(this.tables.transportlogistics.data, temporal_baseline, chart_type);
    //    const [index_vals, data_chart, column_names_chart] = this.transportLogisticsDataFunction(barData1);
    //    const [index_vals_temp, data_chart_temp, column_names_chart_temp] = this.transportLogisticsDataFunction(barData2);

    //    var ret: any = []
    //    for (var i = 0; i < column_names_chart.length; i++) {
    //        let temp_column_names_chart: any = column_names_chart[i]
    //        var trace1 = {
    //            x: index_vals,//commodities
    //            y: data_chart[i],//data_array[i],
    //            name: column_names_chart[i],
    //            type: 'bar',
    //            marker: {
    //                color: this.transportLogisticsColorsFunction(temp_column_names_chart),
    //                opacity: 0.75
    //            },
    //            showlegend: true
    //        };
    //        ret.push(trace1)
    //    }

    //    for (var i = 0; i < column_names_chart_temp.length; i++) {
    //        let temp_column_names_chart: any = column_names_chart_temp[i]
    //        var trace2 = {
    //            x: index_vals_temp,//commodities
    //            y: data_chart_temp[i],//data_array[i],
    //            name: column_names_chart_temp[i],
    //            type: 'bar',
    //            showlegend: false,
    //            xaxis: 'x2',
    //            yaxis: 'y2',
    //            marker: {
    //                color: this.transportLogisticsColorsFunction(temp_column_names_chart),
    //                opacity: 0.75
    //            },
    //        };
    //        ret.push(trace2)
    //    }
    //    return ret
    //}

    //get transportLogisticsDataInternational() {

    //    const [index_vals, data_chart, column_names_chart] = this.transportLogisticsDataFunction(this.tables.transportlogistics.data);
    //    const [index_vals_temp, data_chart_temp, column_names_chart_temp] = this.transportLogisticsDataFunction(this.tables.transportlogisticsindonesia.data);

    //    var ret: any = []
    //    for (var i = 0; i < column_names_chart.length; i++) {
    //        let temp_column_names_chart: any = column_names_chart[i]
    //        var trace1 = {
    //            x: index_vals,//commodities
    //            y: data_chart[i],//data_array[i],
    //            name: column_names_chart[i],
    //            type: 'bar',
    //            marker: {
    //                color: this.transportLogisticsColorsFunction(temp_column_names_chart),
    //                opacity: 0.75
    //            },
    //            showlegend: true
    //        };
    //        ret.push(trace1)
    //    }

    //    for (var i = 0; i < column_names_chart_temp.length; i++) {
    //        let temp_column_names_chart: any = column_names_chart_temp[i]
    //        var trace2 = {
    //            x: index_vals_temp,//commodities
    //            y: data_chart_temp[i],//data_array[i],
    //            name: column_names_chart_temp[i],
    //            type: 'bar',
    //            showlegend: false,
    //            xaxis: 'x2',
    //            yaxis: 'y2',
    //            marker: {
    //                color: this.transportLogisticsColorsFunction(temp_column_names_chart),
    //                opacity: 0.75
    //            },
    //        };
    //        ret.push(trace2)
    //    }
    //    return ret
    //}

    get transportLogisticsLayout() {
        var metric_settings = this.getMetricSettingsLogisticsChart(this.tables.transportlogistics.metric_value)
        return {
            barmode: 'stack',
            showlegend: true, // always show legend even if 1 item
            margin: { l: 100, r: 50, b: 100, t: 0 },
            //width: 700,
            //colorway: ['#f3cec9', '#e7a4b6', '#cd7eaf', '#a262a9', '#6f4d96', '#3d3b72', '#182844'],
            font: {
                size: 14
                //                     size: 11.5
            },
            //boxmode: 'group',
            //margin: { l: 80, r: 50, b: 80, t: 0 },
            height: 350,
            autosize: true,
            yaxis: {
                title: metric_settings.text + ' ' + metric_settings.units,
                //tickformat: metric_settings.valueformat,
                tickprefix: metric_settings.tickprefixformat,
                //type: 'log'
                //autorange: true
                //tickformat: "$,f"
                //zeroline: false
            },
            legend: {
                orientation: 'h',
                //xanchor: 'left',
                x: 0,
                y: 1.2
            },
            //boxmode: 'group'

        };
    }

    //get transportLogisticsLayoutTemporal() {
    //    var metric_settings = this.getMetricSettingsLogisticsChart(this.tables.transportlogistics.metric_value)
    //    return {
    //        barmode: 'stack',
    //        showlegend: true, // always show legend even if 1 item
    //        margin: { l: 100, r: 50, b: 170, t: 0 },
    //        grid: { rows: 1, columns: 2, pattern: 'independent' },
    //        //width: 700,
    //        //height: 700,
    //        //colorway: ['#f3cec9', '#e7a4b6', '#cd7eaf', '#a262a9', '#6f4d96', '#3d3b72', '#182844'],
    //        font: {
    //            size: 14
    //            //                     size: 11.5
    //        },
    //        //boxmode: 'group',
    //        //margin: { l: 80, r: 50, b: 80, t: 0 },
    //        height: 410,
    //        autosize: true,
    //        shared_yaxes: true,

    //        yaxis: {
    //            title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            //tickformat: "$,f"
    //            zeroline: true,

    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            tickprefix: metric_settings.tickprefixformat,
    //            zeroline: true,
    //            matches: 'y',
    //        },
    //        xaxis: {
    //            visible: true,
    //            title: '<b>' + this.currentBaseline(true) + ' baseline</b>',
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: '<b>' + this.baseline_item_selected + ' baseline</b>',
    //        },
    //        legend: {
    //            orientation: 'h',
    //            //xanchor: 'left',
    //            x: 0,
    //            y: 1.2
    //        },
    //        //boxmode: 'group'

    //    };
    //}

    //get transportLogisticsLayoutInternational() {
    //    var metric_settings = this.getMetricSettingsLogisticsChart(this.tables.transportlogistics.metric_value)
    //    return {
    //        barmode: 'stack',
    //        showlegend: true, // always show legend even if 1 item
    //        margin: { l: 100, r: 50, b: 150, t: 0 },
    //        grid: { rows: 1, columns: 2, pattern: 'independent' },
    //        //width: 700,
    //        //height: 700,
    //        //colorway: ['#f3cec9', '#e7a4b6', '#cd7eaf', '#a262a9', '#6f4d96', '#3d3b72', '#182844'],
    //        font: {
    //            size: 14
    //            //                     size: 11.5
    //        },
    //        //boxmode: 'group',
    //        //margin: { l: 80, r: 50, b: 80, t: 0 },
    //        height: 410,
    //        autosize: true,
    //        shared_yaxes: true,

    //        yaxis: {
    //            title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            //tickformat: "$,f"
    //            zeroline: true,

    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //            matches: 'y',
    //        },
    //        xaxis: {
    //            visible: true,
    //            title: '<b>Australia</b>',
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: '<b>Indonesia</b>',
    //        },
    //        legend: {
    //            orientation: 'h',
    //            //xanchor: 'left',
    //            x: 0,
    //            y: 1.2
    //        },
    //        //boxmode: 'group'

    //    };
    //}

    //-----------------------------
    //-------    Box Plot    ------
    //-----------------------------

    //this function adds elements missing in one array based on another to make both arrays X axis identical but missing object key values are set to zero
    boxPlotTempDataArrayFunction(obj1: any, obj2: any) {
        //var groupBy = this.isRoot ? 'sector' : 'commodity'
        var groupBy = this.commoditiesGroupBy

        var data1 = [...obj1], data2 = [...obj2]

        const items1 = data1.map((e: any) => e[groupBy]);
        const items2 = data2.map((e: any) => e[groupBy]);

        const removed = items1.filter((x: any) => !items2.includes(x));
        const added = items2.filter((x: any) => !items1.includes(x));

        if (added.length > 0) {
            for (var i = 0; i < added.length; i++) {
                var d = added[i]
                data1.push({ max: 0, meadian: 0, min: 0, q1: 0, q3: 0, [groupBy]: d })
            }
        }

        if (removed.length > 0) {
            for (var i = 0; i < removed.length; i++) {
                var d = removed[i]
                data2.push({ max: 0, meadian: 0, min: 0, q1: 0, q3: 0, [groupBy]: d })
            }
        }

        return [data1, data2]
    }

    boxPlotDataFunction(data: any) {
        //var groupBy = this.isRoot ? 'sector' : 'commodity'
        var groupBy = this.commoditiesGroupBy
        var colors = this.d3colorsFunction(data.length)

        // Build the node groups
        var nodeGroup: any = []
        for (var i = 0; i < data.length; i++) {

            var group = data[i][groupBy]
            if (!nodeGroup.includes(group)) {
                nodeGroup.push(group);
            }
        }
        nodeGroup = nodeGroup.sort();

        return [data, groupBy, nodeGroup, colors]

    }


    get boxPlotData() {

        const [data, groupBy, nodeGroup, colors] = this.boxPlotDataFunction(this.tables.tripdistribution.data);

        //var data = this.tables.tripdistribution.data

        //var data = this.tables.tripdistributiontemporal2021.data

        //var groupBy = this.isRoot ? 'sector' : 'commodity'

        //// Build the node groups
        //var nodeGroup: any = []
        //for (var i = 0; i < data.length; i++) {
        //    var group = data[i][groupBy]
        //    if (!nodeGroup.includes(group)) {
        //        nodeGroup.push(group);
        //    }
        //}
        //nodeGroup = nodeGroup.sort();

        var plotdata: any[] = []

        var data_loaded_trace1: boolean = false
        var data_loaded_trace2: boolean = false

        if (data.length > 0) {
            data_loaded_trace1 = true
        } else {
            data_loaded_trace1 = false
        }

        for (var i = 0; i < nodeGroup.length; i++) {
            var key = nodeGroup[i]
            var items = this.filter(data, groupBy, key)

            var trace1 = {
                type: 'box',
                name: key,
                x: [key],
                offsetgroup: "1",
                //lowerfence: items.map(a => a['min']),
                q1: items.map(a => a['q1']),
                median: items.map(a => a['median']),
                q3: items.map(a => a['q3']),
                //upperfence: items.map(a => a['max']),
                // ]
                marker: {
                    color: colors[i],

                },
            };
            plotdata.push(trace1)
        }

        // //international comparison
        //if (this.internationalTripsDefined) {
        //    const [boxData1, boxData2] = this.boxPlotTempDataArrayFunction(this.tables.tripdistribution.data, this.tables.tripdistributionindonesia.data);
        //    const [data_int, groupBy_int, nodeGroup_int, colors_int] = this.boxPlotDataFunction(boxData2);

        //    if (boxData2.length > 0) {
        //        data_loaded_trace2 = true
        //    } else {
        //        data_loaded_trace2 = false
        //    }

        //    for (var i = 0; i < nodeGroup_int.length; i++) {

        //        var key_int = nodeGroup_int[i]
        //        var items_temp = this.filter(data_int, groupBy_int, key_int)
        //        var median = items_temp.map(a => a['median'])// this removes all array elements that are zeros as we do not want them for international comparisoon (but they are needed for baseline comparison)

        //        if (median[0] > 0) {
        //            var trace2 = {
        //                type: 'box',
        //                name: key_int,
        //                x: [key_int],
        //                offsetgroup: "2",
        //                q1: items_temp.map(a => a['q1']),
        //                median: items_temp.map(a => a['median']),
        //                q3: items_temp.map(a => a['q3']),
        //                //xaxis: 'x2',
        //                //yaxis: 'y',
        //                showlegend: false,
        //                marker: {
        //                    //color: colors_int[i],
        //                    //opasity: 1,
        //                },
        //                line: {
        //                    color: colors_int[i], //'black',
        //                    width: 4, //1.5,
        //                },
        //                fillcolor: 'white',//colors_int[i]
        //            };
        //            plotdata.push(trace2)
        //        }

        //    }

        //}
        //// this is to load both traces at the same time to prevent chart flickering
        //if (!this.internationalTripsDefined) {
        //    return plotdata;
        //} else if (data_loaded_trace1 && data_loaded_trace2) {
        //    return plotdata;
        //}
    //}

    //get boxPlotDataTemporal() {

    //    const temporal_baseline = this.baselineFilter(this.tables.tripdistributiontemporal.data, this.selectedBaselineValue);
    //    const [boxData1, boxData2] = this.boxPlotTempDataArrayFunction(this.tables.tripdistribution.data, temporal_baseline);

    //    const [data, groupBy, nodeGroup, colors] = this.boxPlotDataFunction(boxData1);
    //    const [data_temp, groupBy_temp, nodeGroup_temp, colors_temp] = this.boxPlotDataFunction(boxData2);

    //    var plotdata: any[] = []
    //    for (var i = 0; i < nodeGroup.length; i++) {
    //        var key = nodeGroup[i]
    //        var items = this.filter(data, groupBy, key)

    //        var trace1 = {
    //            type: 'box',
    //            name: key,
    //            x: [key],
    //            offsetgroup: "1",
    //            q1: items.map(a => a['q1']),
    //            median: items.map(a => a['median']),
    //            q3: items.map(a => a['q3']),
    //            showlegend: true,
    //            marker: {
    //                color: colors[i],
    //            },

    //        };

    //        plotdata.push(trace1)
    //    }

    //    for (var i = 0; i < nodeGroup_temp.length; i++) {

    //        var key_temp = nodeGroup_temp[i]
    //        var items_temp = this.filter(data_temp, groupBy_temp, key_temp)

    //        var trace2 = {
    //            type: 'box',
    //            name: key_temp,
    //            x: [key_temp],
    //            offsetgroup: "1",
    //            q1: items_temp.map(a => a['q1']),
    //            median: items_temp.map(a => a['median']),
    //            q3: items_temp.map(a => a['q3']),
    //            xaxis: 'x2',
    //            yaxis: 'y2',
    //            showlegend: false,
    //            marker: {
    //                color: colors_temp[i],
    //            },

    //        };

    //        plotdata.push(trace2)
    //    }

        return plotdata;
    }

    get boxPlotLayout() {

        var metric_settings = this.getMetricSettingsBoxPlot(this.tables.tripdistribution.metric_value)
        //const [data, groupBy, nodeGroup, colors] = this.boxPlotDataFunction(this.tables.tripdistribution.data);

        return {
            boxmode: 'group',
            //hovermode: 'closest',
            showlegend: true, // always show legend even if 1 item
            //boxmode: 'overlay'
            margin: { l: 100, r: 50, b: 100, t: 0 },
            height: 350,
            autosize: true,
            yaxis: {
                title: metric_settings.text + ' ' + metric_settings.units,
                //tickformat: metric_settings.valueformat,
                //zeroline: true,
                tickprefix: metric_settings.tickprefixformat,
                rangemode: 'tozero'
            },
            font: {
                size: 14
            },
            legend: {
                orientation: 'h',
                x: 0,
                y: 1.5
            },
            //hoverlabel: { bgcolor: colors },

        }
    }

    //get boxPlotLayoutTemporal() {

    //    var metric_settings = this.getMetricSettingsBoxPlot(this.tables.tripdistributiontemporal.metric_value)

    //    return {
    //        boxmode: 'group',
    //        showlegend: true, // always show legend even if 1 item
    //        //boxmode: 'overlay'
    //        margin: { l: 100, r: 50, b: 170, t: 0 },
    //        grid: { rows: 1, columns: 2, pattern: 'independent' },
    //        height: 410,
    //        autosize: true,
    //        shared_yaxes: true,
    //        yaxis: {
    //            title: metric_settings.text + ' ' + metric_settings.units,
    //            tickprefix: metric_settings.tickprefixformat,
    //            //tickformat: metric_settings.valueformat,
    //            zeroline: true,
    //        },
    //        yaxis2: {
    //            //title: metric_settings.text + ' ' + metric_settings.units,
    //            //tickformat: metric_settings.valueformat,
    //            tickprefix: metric_settings.tickprefixformat,
    //            zeroline: true,
    //            matches: 'y',
    //        },
    //        xaxis: {
    //            visible: true,
    //            title: '<b>' + this.currentBaseline(true) + ' baseline</b>',
    //        },
    //        xaxis2: {
    //            visible: true,
    //            title: '<b>' + this.baseline_item_selected + ' baseline</b>',
    //        },
    //        font: {
    //            size: 14
    //        },
    //        legend: {
    //            orientation: 'h',
    //            x: 0,
    //            y: 1.5
    //        },
    //    }
    //}


    //-----------------------------
    //-------Backload Chart ------
    //-----------------------------
    get backloadData() {


        var data = this.tables.backload.data

        var groupBy = this.commoditiesGroupBy

        let index_vals = [...new Set(data.map(function (val: any) { return val[groupBy]; }))].sort();
        var data_array = new Array(index_vals.length);

        for (var i = 0; i < data.length; i++) {
            var row = index_vals.indexOf(data[i][groupBy])
            data_array[row] = data[i][this.tables.backload.metric_value]
        }

        var plotdata: any[] = []

        for (var i = 0; i < index_vals.length; i++) {
            var key = index_vals[i];
            var items = this.filter(data, groupBy, key);
            var size: any = items.map(a => a['backload_pct'])
            //var size_ = Math.round(size * 100)
            //var size_round = Math.round(size)

            plotdata.push({
                mode: 'markers',
                name: key,
                x: [key],
                y: [data_array[i]],
                marker: {
                    size: size,
                    sizeref: 0.009,
                    sizemode: 'area'

                },
                text: ['Backload trips:<br>' + size + '%'],
            })
        }

        return plotdata;

    }

    get backloadLayout() {

        var metric_settings = this.getMetricSettings(this.tables.backload.metric_value)

        return {
            showlegend: true,
            height: 350,
            autosize: true,
            margin: { l: 100, r: 50, b: 100, t: 0 },
            yaxis: {
                title: metric_settings.text + ' ' + metric_settings.units,
                tickprefix: metric_settings.tickprefixformat,
                //tickformat: metric_settings.valueformat,
                //tickformat: "$,f"
                //zeroline: true,
                rangemode: 'tozero',
            },
            xaxis: {
                visible: true,
            },
            legend: {
                itemsizing:'constant',
                orientation: 'h',
                x: 0,
                y: 1.2
            },
            font: {
                size: 14
            },
        }

    }

    //-----------------------------
    //-------  Density Map   ------
    //-----------------------------

    local_trips: boolean = true

    get map_direction_text() {
        return this.tables.densitymap.map_direction ? 'Inbound Trips' : 'Outbound Trips'
    }

    get regions() {
        return this.tables.densitymap.map_direction ? 'dest_' + this.spatial_type : 'orig_' + this.spatial_type
    }

    regions2(direction: string) {
        return direction + '_' + this.spatial_type
    }

    get mapData() {
        return this.mapDataFunction(this.tables.densitymap.data)
    }
    get mapData_inout() {
        return this.mapDataFunction(this.tables.densitymap_arrivals.data)
    }

    mapDataFunction(data: any) {

        var locations: string[] = []
        var values: number[] = []
        var names: number[] = []

        for (var i = 0; i < data.length; i++) {
            var regions_rev = this.regions.startsWith('orig') ? this.regions.replace('orig', 'dest') : this.regions.replace('dest', 'orig')
            if (data[i][this.regions] != undefined) {
                locations.push(data[i][this.regions])
            }
            else {
                locations.push(data[i][regions_rev])
            }

            values.push(data[i][this.tables.densitymap.metric_value])
            names.push(data[i]['name'])
        }

        var metric_settings = this.getMetricSettings(this.tables.densitymap.metric_value)

        //return datatest
        return [{
            type: "choroplethmapbox",
            name: (this.spatialTypeItemsRestrict as any)[this.spatial_type].text,
            geojson: (this.spatialTypeItemsRestrict as any)[this.spatial_type].geojson,
            locations: locations,
            meta: [metric_settings.valueformat.startsWith('$') ? '$' : '', metric_settings.valuesuffix],
            text: names,
            hovertemplate: "%{meta[0]}%{z}%{meta[1]}<extra>%{text}</extra>",
            //hoverinfo: "text+z+location",
            //textinfo: 'location+z+text',
            z: values,
            colorscale: [[0, 'white'], [0.4, 'rgb(63,81,181)'], [1, 'red']],


        }]

    }

    get spatialTypeItemsRestrict() {
        if (!this.isAllowedSensitiveInfo) {
            //return this.spatialTypeItemsState
            return this.spatialTypeItems
        }
        else if (this.isAllowedSensitiveInfo) {
            return this.spatialTypeItems
        }
    }

    get mapHeight() {
        var height = 600
        return this.commod_sensitive_outbound || this.commod_sensitive_inbound ? height - 50 : height
    }
    get mapLayout() {
        return {
            mapbox: {
                style: 'light',
                center: {
                    lon: 134,
                    lat: -28.3
                },
                zoom: 3.3
            },
            //width: 840,  // half size version
            //width: 1490,
            height: this.mapHeight,
            autosize: true,
            margin: { t: this.commod_sensitive_outbound ? 0 : 50, b: 5, l: 5, r: 5 }
        };
    }
    get mapLayout_half() {
        return {
            mapbox: {
                style: 'light',
                center: {
                    lon: 134,
                    lat: -28.3
                },
                zoom: 3.3
            },
            //width: 840,  // half size version
            //width: window.innerWidth > 745 ? 745: undefined,
            height: this.mapHeight,
            autosize: true,
            margin: { t: this.commod_sensitive_outbound || this.commod_sensitive_inbound ? 0 : 0, b: 5, l: 5, r: 5 }
        };
    }

    map_selected_orig: any = {};
    map_selected_dest: any = {};

    densityMapDeparturesOnClick(e: any) {
        if (this.commodityFilter && !this.map_arrivals && !this.map_departures) {
            this.resetNavBarFiltersMapOnClick()
        }
        this.map_departures = true;
        (Vue as any).gtm.trackEvent({
            event: 'gui',
            category: 'benchmark',
            action: 'Outbound trips for ' + e.points[0].data.name,
            label: e.points[0].location + ' - ' + e.points[0].text,
            //value: payload.value,
            noninteraction: true,
        });
        //console.log('Outbound trips for ' + e.points[0].data.name, e.points[0].location + ' - ' + e.points[0].text)
        this.map_selected_orig = { location: e.points[0].location, text: e.points[0].text }
        this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        if (this.map_selected_dest.location) {
            this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        }
        this.$emit('spatial_filter_from', this.map_selected_orig);
        this.spatialFilterUpdated();


    }
    densityMapArrivalsOnClick(e: any) {
        if (this.commodityFilter && !this.map_arrivals && !this.map_departures) {
            this.resetNavBarFiltersMapOnClick()
        }
        this.map_arrivals = true;
        (Vue as any).gtm.trackEvent({
            event: 'gui',
            category: 'benchmark',
            action: 'Inbound trips for ' + e.points[0].data.name,
            label: e.points[0].location + ' - ' + e.points[0].text,
            //value: payload.value,
            noninteraction: true,
        });
        //console.log('Inbound trips for ' + e.points[0].data.name, e.points[0].location + ' - ' + e.points[0].text)
        this.map_selected_dest = { location: e.points[0].location, text: e.points[0].text }
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        if (this.map_selected_orig.location) {
            this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        }
        this.$emit('spatial_filter_to', this.map_selected_dest);
        this.spatialFilterUpdated();


    }

    clearMapDeparturesOnClick() {
        this.map_departures = false;
        this.clearSupplyChainFilter();
        this.map_selected_orig = {}
        var reloadOtherMap = false
        if (this.filterGroup != 'All') {
            this.filterGroup = 'All'
            this.filterGroupBy = 'root'
            this.$emit('commodity_filter', this.commodityFilter);
            reloadOtherMap = true
        }
        if (reloadOtherMap || this.map_selected_dest.location) {
            this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        }
        this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        this.$emit('spatial_filter_from', this.map_selected_orig);
        this.spatialFilterUpdated();
    }

    clearMapArrivalsOnClick() {
        this.map_arrivals = false;
        this.clearSupplyChainFilter();
        this.map_selected_dest = {}
        var reloadOtherMap = false
        if (this.filterGroup != 'All') {
            this.filterGroup = 'All'
            this.filterGroupBy = 'root'
            this.$emit('commodity_filter', this.commodityFilter);
            reloadOtherMap = true
        }
        if (reloadOtherMap || this.map_selected_orig.location) {
            this.loadTable(this.tables.densitymap_arrivals, this.densityMapUrl, { 'regions': this.regions2('dest') });
        }
        this.loadTable(this.tables.densitymap, this.densityMapUrl, { 'regions': this.regions2('orig') });
        this.$emit('spatial_filter_to', this.map_selected_dest);
        this.spatialFilterUpdated();
    }

    //-----------------------------
    //------ Commodities Table ----
    //-----------------------------

    total_group(data: any[], groupBy: string, group: string, attribute: string, secondaryGroupBy?: string, secondaryGroup?: string) {
        let sum = 0;
        if (secondaryGroupBy) {
            return data.reduce((sum, item) => sum + (item[groupBy] == group && item[secondaryGroupBy] == secondaryGroup ? item[attribute] : 0), 0);
        } else {
            return data.reduce((sum, item) => sum + (item[groupBy] == group ? item[attribute] : 0), 0);
        }
    }

    total(data: any[], attribute: string) {
        let sum = 0;
        return data.reduce((sum, item) => sum + item[attribute], 0);
    }

    average(data: any[], attribute: string) {
        let sum = 0;
        return data.reduce((sum, item) => sum + item[attribute], 0) / data.length;
    }

    filter(data: any, filterKey: string, value: string): any[] {
        return data.filter(function (el: any) {
            return el[filterKey] == value;
        });
    }

    closeAll() {
        Object.keys(this.$refs).forEach(k => {
            if (this.$refs[k] && (this.$refs[k] as any).$attrs['data-open']) {
                (this.$refs[k] as any).$el.click()
            }
        })
    }
    openAll() {
        Object.keys(this.$refs).forEach(k => {
            if (this.$refs[k] && !(this.$refs[k] as any).$attrs['data-open']) {
                (this.$refs[k] as any).$el.click()
            }
        })
    }

    @Watch('tables.commodities.data', { deep: false })
    onValueChanged(val: any, oldVal: any) {
        this.closeAll();
    }



    //-----------------------------
    //-------      Data       -----
    //-----------------------------

    tables = {

        commodities_all: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'trip_transport_costs'
        },
        commodities_average: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'trip_transport_costs'
        },
        supplychain: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne'
        },
        transportlogistics: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'transport_costs'
        },
        //transportlogisticstemporal: {
        //    loading: false,
        //    init: false,
        //    data: [],
        //    metric_value: 'transport_costs'
        //},
        transportlogisticsindonesia: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'transport_costs'
        },
        pathcost: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne'
        },
        //pathcosttemporal: {
        //    loading: false,
        //    init: false,
        //    data: [],
        //    metric_value: 'cst_per_tonne'
        //},
        pathcostindonesia: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne'
        },
        triptype: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'total_trip_distance'
        },
        //triptypetemporal: {
        //    loading: false,
        //    init: false,
        //    data: [],
        //    metric_value: 'total_trip_distance'
        //},
        triptypeindonesia: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'total_trip_distance'
        },
        tripdistribution: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne'
        },
        //tripdistributiontemporal: {
        //    loading: false,
        //    init: false,
        //    data: [],
        //    metric_value: 'cst_per_tonne'
        //},
        tripdistributionindonesia: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne'
        },
        backload: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'tonnes'
        },
        densitymap: {
            loading: false,
            init: false,
            data: [],
            metric_value: 'cst_per_tonne',
            mapboxAccessToken: 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw',
            map_direction: false
        },
        densitymap_arrivals: {
            loading: false,
            init: false,
            data: [],
            //metric_value: 'cst_per_tonne',
            //mapboxAccessToken: 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw',
            //map_direction: false
        },
        commodities: {
            loading: false,
            init: false,
            data: [],
            currentPage: 1,
            pagination: {
                sortBy: 'trip_transport_costs',
                descending: true
            },
            search: '',
            headers: [
                {
                    text: 'Commodity',
                    align: 'left',
                    sortable: true,
                    value: 'commodity',
                    groupable: false,
                    description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"'
                },
                {
                    text: 'Industry',
                    align: 'left',
                    sortable: true,
                    value: 'industry',
                    description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.'
                },
                {
                    text: 'Sector',
                    align: 'left',
                    sortable: true,
                    value: 'sector',
                    description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commidity metadata" document under the TraNSIT Web menu.'
                },
                { text: 'Annual Trailers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total annual trailers which were used in transporting this commodity for the current selection.' },
                { text: 'Annual Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total annual tonnes which were transported for this commodity for the current selection.' },
                { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total annual tonnes per trailer which were transported for this commodity for the current selection.' },
                { text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
                { text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
                //{ text: 'Avg Trip Speed (km/hr)', align: 'right', sortable: 'true', value: 'avg_trip_speed' },
                //{ text: 'Commodity Transport Costs ($)', value: 'transport_costs', align: 'right' },
                { text: 'Cost per payload tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per payload tonne associated with transporting this commodity for the current selection.' },
                { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
                { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },
            ]
        },

    //    commoditiestemporal: {
    //        loading: false,
    //        init: false,
    //        data: [],
    //        currentPage: 1,
    //        pagination: {
    //            sortBy: 'trip_transport_costs',
    //            descending: true
    //        },
    //        search: '',
    //        headers: [
    //            {
    //                text: 'Commodity',
    //                align: 'left',
    //                sortable: true,
    //                value: 'commodity',
    //                groupable: false,
    //                description: 'The commodity being transported.\n\nNote: trips from distribution centers to supermarkets contain multiple commodities and are classified by TraNSIT as "Mixed"'
    //            },
    //            {
    //                text: 'Industry',
    //                align: 'left',
    //                sortable: true,
    //                value: 'industry',
    //                description: 'The level 2 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commodity metadata" document under the TraNSIT Web menu.'
    //            },
    //            {
    //                text: 'Sector',
    //                align: 'left',
    //                sortable: true,
    //                value: 'sector',
    //                description: 'The level 3 commodidity classification by TraNSIT.\n\nFurther information is provided in the "Commodity metadata" document under the TraNSIT Web menu.'
    //            },
    //            { text: 'Annual Trailers', value: 'trailer_loads', align: 'right', groupable: false, description: 'The total annual trailers which were used in transporting this commodity for the current selection.' },
    //            { text: 'Annual Tonnes', value: 'tonnes', align: 'right', groupable: false, description: 'The total annual tonnes which were transported for this commodity for the current selection.' },
    //            { text: 'Tonnes/Trailer', value: 'tonnes_per_trailer', align: 'right', groupable: false, description: 'The total annual tonnes per trailer which were transported for this commodity for the current selection.' },
    //            { text: 'Avg Trip Distance (km)', align: 'right', sortable: 'true', value: 'avg_trip_distance', groupable: false, description: 'The average distance of all trips which involve transporting this commodity for the current selection.' },
    //            { text: 'Avg Trip Duration (hrs)', align: 'right', sortable: 'true', value: 'avg_trip_duration', groupable: false, description: 'The average travel time of all trips which involve transporting this commodity for the current selection.' },
    //            //{ text: 'Avg Trip Speed (km/hr)', align: 'right', sortable: 'true', value: 'avg_trip_speed' },
    //            //{ text: 'Commodity Transport Costs ($)', value: 'transport_costs', align: 'right' },
    //            { text: 'Cost per payload tonne ($)', value: 'cost_per_tonne', align: 'right', groupable: false, description: 'The total cost per payload tonne associated with transporting this commodity for the current selection.' },
    //            { text: 'Cost per tonne km ($)', value: 'cost_per_tonne_km', align: 'right', groupable: false, description: 'The total cost per tonne km associated with transporting this commodity for the current selection.' },
    //            { text: 'Total Transport Costs ($)', value: 'trip_transport_costs', align: 'right', groupable: false, description: 'The total costs associated with transporting this commodity for the current selection.' },
    //        ]
    //    },

    }

    // Table related endpoints

    get commodityReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/commodityreportbm";
    }
    get pathcostReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/pathcostreportbm";
    }
    get triptypeReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/triptypereportbm";
    }
    get supplychainReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/supplychainreportbm";
    }
    get transportlogisticsReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/transportlogisticsreportbm";
    }
    get tripDistributionReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/tripdistributionreportbm";
    }
    get backloadReportUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/backloadreportbm";
    }
    get densityMapUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/densitymapbm";
    }

    ////temporal endpoints
    //get pathcosttemporalReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/pathcosttemporalreportbm";
    //}
    //get tripDistributiontemporalReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/tripdistributiontemporalreportbm";
    //}
    //get transportlogisticstemporalReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/transportlogisticstemporalreportbm";
    //}
    //get commoditytemporalReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/commoditytemporalreportbm";
    //}

    ////international endpoints
    //get pathcostindonesiaReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/pathcostindonesiareportbm";
    //}
    //get tripdistributionindonesiaReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/tripdistributionindonesiareportbm";
    //}
    //get transportlogisticsindonesiaReportUrl() {
    //    return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/transportlogisticsindonesiareportbm";
    //}

    reportError(errorThrown: any) {
        console.log(errorThrown);
        //if (errorThrown == "Gateway Time-out") {
        //    this.$data.errorMsh = "A timeout error has occurered while retrieving data. Please try zooming in closer or apply more selective filters.";
        //} else {
        //    //this.$data.errorMsg = "Errors have occurred retrieving data.";
        //    this.$data.errorMsg = errorThrown.response.data;
        //}
        //this.$data.errorSnackbar = true;
    }

    getQueryParams(ignoreFilters: boolean) {
        // Build the tile url based upon filter values
        var queryParams: any = {};

        if (this.filterGroupBy == 'sector') {
            queryParams = { 'commod_l3': this.filterGroup }
        }
        if (this.filterGroupBy == 'industry') {
            queryParams = { 'commod_l2': this.filterGroup }
        }
        if (this.filterGroupBy == 'commodity') {
            queryParams = { 'commodity': this.filterGroup }
        }


        queryParams['dataset'] = 'SIM-AU-BASELINE';
        queryParams['metric'] = this.getMetricSettings(this.tables.tripdistribution.metric_value).metric;
        queryParams['regions'] = this.regions;

        if (this.value_type) {
            queryParams['value_type'] = this.value_type;
        }

        queryParams['local_trips'] = this.local_trips;

        if (this.enterprise_type_operation == "Intersection" || this.enterprise_type_operation == "") {
            if (this.supplyChainFilter) {
                if (this.isRoot) {
                    queryParams['origenterprisecategory'] = this.orig_enterprise_type;
                    queryParams['destenterprisecategory'] = this.dest_enterprise_type;
                } else {
                    queryParams['origenterprise'] = this.orig_enterprise_type;
                    queryParams['destenterprise'] = this.dest_enterprise_type;
                }
                queryParams['enterprise_type_operation'] = this.enterprise_type_operation;
            }
        }

        if (this.enterprise_type_operation == "Union") {

            // TODO: Backend should work off this query parameter rather than the ones with 2 suffixes below
            queryParams['enterprise_type_operation'] = this.enterprise_type_operation;

            if (this.isRoot) {
                queryParams['destenterprisecategory2'] = this.dest_enterprise_type;
                queryParams['origenterprisecategory2'] = this.orig_enterprise_type;
            } else {
                queryParams['destenterprise2'] = this.dest_enterprise_type;
                queryParams['origenterprise2'] = this.orig_enterprise_type;
            }
        }


        if (this.mode) {
            queryParams['mode'] = this.mode;
        }
        if (this.map_selected_orig.location) {
            queryParams['orig_region'] = this.map_selected_orig.location
            queryParams['orig_region_category'] = this.regions2('orig')
        }
        if (this.map_selected_dest.location) {
            queryParams['dest_region'] = this.map_selected_dest.location
            queryParams['dest_region_category'] = this.regions2('dest')
        }

        return queryParams;
    }

    loadTable(tableConfig: any, url: string, params?: any) {
        //var vm = this;
        tableConfig.loading = true;

        tableConfig.data = [];  // Probably need something better than this

        var p = this.getQueryParams(false)
        if (params) {
            p = Object.assign({}, p, params);
        }

        var endpoint = buildUrl(url, {
            queryParams: p
        });

        this.startNumberAnimation()

        Vue.axios({
            url: endpoint
        }).then((data: any) => {
            tableConfig.data = data.data;
            tableConfig.loading = false;
            tableConfig.init = true;
            this.stopNumberAnimation()
            this.sensitive_commod_alert_check(this.tables.triptype.data, 'triptype')
            this.sensitive_commod_alert_check(this.tables.supplychain.data, 'supplychain')
            this.sensitive_commod_alert_check(this.tables.pathcost.data, 'pathcost')
            this.sensitive_commod_alert_check(this.tables.densitymap.data, 'dansitymap')
            this.sensitive_commod_alert_check(this.tables.densitymap_arrivals.data, 'dansitymaparrivals')

        }, (error: any) => {
            tableConfig.loading = false;
            this.reportError(error);
            this.stopNumberAnimation();
        });

    }

}


